import { post } from '@zeal/api/request'

import { notReachable } from '@zeal/toolkit'
import { ImperativeError } from '@zeal/toolkit/Error'
import { values } from '@zeal/toolkit/Object'
import { object } from '@zeal/toolkit/Result'

import { Address } from '@zeal/domains/Address'
import { CryptoCurrency } from '@zeal/domains/Currency'
import { parseKnownCurrencies } from '@zeal/domains/Currency/helpers/parse'
import { captureError } from '@zeal/domains/Error/helpers/captureError'
import { KeyStore } from '@zeal/domains/KeyStore'
import { PredefinedNetwork } from '@zeal/domains/Network'
import { filterNetworksByKeyStoreType } from '@zeal/domains/Network/helpers/filterNetworksByKeyStore'

import {
    BICONOMY_SUPPORTED_GAS_ABSTRACTION_CURRENCIES,
    FALLBACK_TOP_UP_CURRENCIES,
} from '../constants'

const getTopUpCurrenciesByKeystoreType = ({
    network,
    keyStoreType,
}: {
    network: PredefinedNetwork
    keyStoreType: KeyStore['type']
}): Address[] => {
    switch (keyStoreType) {
        case 'track_only':
        case 'private_key_store':
        case 'ledger':
        case 'secret_phrase_key':
        case 'trezor':
            return [network.gasTokenAddress]
        case 'safe_4337':
            const gasTokenAddresses =
                BICONOMY_SUPPORTED_GAS_ABSTRACTION_CURRENCIES[
                    network.hexChainId
                ] || null

            if (!gasTokenAddresses) {
                captureError(
                    new ImperativeError(
                        'Missing gas abstraction topup currencies in dApp for network',
                        { network: network }
                    )
                )
                return [network.gasTokenAddress]
            }
            return [network.gasTokenAddress, ...gasTokenAddresses]
        /* istanbul ignore next */
        default:
            return notReachable(keyStoreType)
    }
}

export const fetchSupportedTopUpCurrencies = async ({
    supportedNetworks,
    keyStoreType,
}: {
    supportedNetworks: PredefinedNetwork[]
    keyStoreType: KeyStore['type']
}): Promise<CryptoCurrency[]> => {
    const networks = filterNetworksByKeyStoreType({
        networks: supportedNetworks,
        keyStoreType,
    })

    try {
        const response = await post('/wallet/currencies', {
            body: networks.map((network) => ({
                network: network.name,
                addresses: getTopUpCurrenciesByKeystoreType({
                    network,
                    keyStoreType,
                }),
            })),
        })
        return object(response)
            .andThen((obj) => parseKnownCurrencies(obj.currencies))
            .map((knownCurrencies) =>
                values(knownCurrencies).filter(
                    (currency): currency is CryptoCurrency => {
                        switch (currency.type) {
                            case 'CryptoCurrency':
                                return true
                            case 'FiatCurrency':
                                return false
                            /* istanbul ignore next */
                            default:
                                return notReachable(currency)
                        }
                    }
                )
            )
            .getSuccessResultOrThrow(
                'Failed to parse supported crypto currencies'
            )
    } catch (e) {
        captureError(e)
        return FALLBACK_TOP_UP_CURRENCIES
    }
}
