import React from 'react'
import Svg, { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

type Props = {
    size: number
}

export const Optimism = ({ size }: Props) => {
    return (
        <Svg
            style={{ flexShrink: 0 }}
            viewBox="0 0 120 120"
            width={size}
            height={size}
            fill="none"
        >
            <G clipPath="url(#clip0_936_439)">
                <Path
                    d="M60 120C93.1371 120 120 93.1371 120 60C120 26.8629 93.1371 0 60 0C26.8629 0 0 26.8629 0 60C0 93.1371 26.8629 120 60 120Z"
                    fill="#FF0420"
                />
                <Path
                    d="M34.1297 82C29.0965 82 24.9728 80.8332 21.7583 78.4999C18.5861 76.125 17 72.7501 17 68.375C17 67.4583 17.1058 66.3331 17.3172 64.9998C17.8673 62 18.6497 58.3956 19.6647 54.1874C22.5407 42.7291 29.9638 37 41.9335 37C45.1902 37 48.1085 37.5416 50.6887 38.6248C53.2689 39.6664 55.299 41.2499 56.7792 43.375C58.2595 45.4581 59 47.9583 59 50.8748C59 51.7498 58.8942 52.854 58.6825 54.1874C58.0482 57.8957 57.2868 61.5 56.3985 64.9998C54.9182 70.7083 52.3594 74.9791 48.7219 77.8124C45.0844 80.604 40.2206 82 34.1297 82ZM35.018 72.9999C37.3868 72.9999 39.3956 72.3124 41.0453 70.9374C42.7372 69.5625 43.9426 67.4583 44.6614 64.6251C45.6342 60.7083 46.3747 57.2914 46.8822 54.3749C47.0512 53.4999 47.1358 52.6042 47.1358 51.6872C47.1358 47.8957 45.1267 45.9997 41.1088 45.9997C38.74 45.9997 36.7099 46.6872 35.018 48.0622C33.3686 49.4375 32.1842 51.5417 31.4654 54.3749C30.7039 57.1665 29.9425 60.5834 29.1814 64.6251C29.012 65.4581 28.9274 66.3331 28.9274 67.2498C28.9274 71.0833 30.9578 72.9999 35.018 72.9999Z"
                    fill="white"
                />
                <Path
                    d="M62.2998 81C61.8365 81 61.4782 80.8533 61.2257 80.5598C61.0149 80.2245 60.9519 79.8476 61.0361 79.4286L69.7552 38.5714C69.8394 38.1105 70.0711 37.7333 70.4502 37.4398C70.8294 37.1467 71.2294 37 71.6506 37H88.4572C93.1326 37 96.8813 37.9638 99.7034 39.8913C102.568 41.8192 104 44.6056 104 48.2513C104 49.2988 103.874 50.3886 103.621 51.5198C102.568 56.339 100.441 59.901 97.2396 62.2057C94.0805 64.5104 89.7418 65.6628 84.2239 65.6628H75.6943L72.7881 79.4286C72.7036 79.8895 72.4719 80.2664 72.0931 80.5598C71.7139 80.8533 71.3136 81 70.8927 81H62.2998ZM84.6663 56.9885C86.4352 56.9885 87.9726 56.5065 89.2785 55.5427C90.6264 54.579 91.5109 53.1961 91.9322 51.3942C92.0584 50.6817 92.1217 50.0532 92.1217 49.5085C92.1217 48.2932 91.7638 47.3713 91.0476 46.7429C90.3314 46.0722 89.1101 45.7372 87.383 45.7372H79.801L77.4002 56.9885H84.6663Z"
                    fill="white"
                />
            </G>
            <Defs>
                <ClipPath id="clip0_936_439">
                    <Rect width="120" height="120" fill="white" />
                </ClipPath>
            </Defs>
        </Svg>
    )
}
