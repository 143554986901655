import { FormatNumberOptions, useIntl } from 'react-intl'

import Big from 'big.js'

import { notReachable } from '@zeal/toolkit'

import { CryptoMoney } from '@zeal/domains/Money'
const NON_BREAKING_SPACE = '\u00A0'
type Props = {
    money: CryptoMoney
    sign?: '+' | '-'
}

export const FormattedCryptoMoney = ({ money, sign }: Props) => {
    const { formatNumber } = useIntl()
    const { currency } = money
    const power = BigInt(10) ** BigInt(currency.fraction)

    const signCorrection = sign
        ? (() => {
              switch (sign) {
                  case '+':
                      return 1
                  case '-':
                      return -1
                  default:
                      return notReachable(sign)
              }
          })()
        : 1

    const amount =
        new Big(money.amount.toString())
            .div(new Big(power.toString()))
            .toNumber() * signCorrection

    const absValue = Math.abs(amount)
    const options: FormatNumberOptions = {
        signDisplay: sign ? 'always' : 'auto',
    }
    if (money.amount === 0n) {
        options.minimumFractionDigits = 0
        options.maximumFractionDigits = 0
    } else if (absValue < 0.00000000001) options.notation = 'engineering'
    else if (absValue < 0.001) {
        options.maximumFractionDigits = 8
        options.maximumSignificantDigits = 1
    } else if (absValue < 1) {
        options.minimumSignificantDigits = 1
        options.maximumSignificantDigits = 2
    } else if (absValue < 10) {
        options.minimumFractionDigits = 2
        options.maximumFractionDigits = 2
    } else if (absValue < 100) {
        options.minimumFractionDigits = 1
        options.maximumFractionDigits = 1
    } else {
        options.minimumFractionDigits = 0
        options.maximumFractionDigits = 0
    }

    return `${formatNumber(amount, options)}${NON_BREAKING_SPACE}${
        currency.symbol
    }`
}
