import React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

type Props = {
    size: number
}

export const CustomWalletConnect = ({ size }: Props) => (
    <SvgIcon viewBox="0 0 28 28" width={size} height={size}>
        <G clipPath="url(#clip0_1042_28372)">
            <Rect width="28" height="28" fill="#3B99FC" />
            <Path
                d="M19.5595 10.6343C16.5536 7.65574 11.6795 7.65574 8.67365 10.6343L8.27639 11.0275C8.24071 11.0625 8.21234 11.1044 8.19297 11.1507C8.1736 11.197 8.16361 11.2468 8.16361 11.2971C8.16361 11.3474 8.1736 11.3972 8.19297 11.4435C8.21234 11.4898 8.24071 11.5317 8.27639 11.5667L9.51397 12.793C9.55047 12.8288 9.5993 12.8488 9.65012 12.8488C9.70095 12.8488 9.74978 12.8288 9.78628 12.793L10.3194 12.2648C12.4168 10.1873 15.8169 10.1873 17.9137 12.2648L18.4115 12.7582C18.448 12.794 18.4968 12.814 18.5477 12.814C18.5985 12.814 18.6473 12.794 18.6838 12.7582L19.9214 11.532C19.9571 11.497 19.9855 11.455 20.0048 11.4087C20.0242 11.3624 20.0342 11.3126 20.0342 11.2623C20.0342 11.212 20.0242 11.1622 20.0048 11.1159C19.9855 11.0696 19.9571 11.0277 19.9214 10.9927L19.5595 10.6343Z"
                fill="white"
            />
            <Path
                d="M23.2205 14.2616L22.1193 13.1699C22.0463 13.0982 21.9487 13.0581 21.847 13.0581C21.7453 13.0581 21.6476 13.0982 21.5747 13.1699L18.0501 16.6626C18.0319 16.6807 18.0074 16.6908 17.9819 16.6908C17.9564 16.6908 17.9319 16.6807 17.9137 16.6626L14.3886 13.1699C14.3157 13.0982 14.218 13.0581 14.1163 13.0581C14.0146 13.0581 13.9169 13.0982 13.844 13.1699L10.3194 16.6626C10.3012 16.6807 10.2767 16.6908 10.2512 16.6908C10.2258 16.6908 10.2013 16.6807 10.183 16.6626L6.65847 13.1699C6.58556 13.0984 6.48798 13.0583 6.38641 13.0583C6.28485 13.0583 6.18727 13.0984 6.11436 13.1699L5.01268 14.2616C4.977 14.2966 4.94863 14.3385 4.92926 14.3848C4.90989 14.4311 4.8999 14.4809 4.8999 14.5312C4.8999 14.5815 4.90989 14.6313 4.92926 14.6776C4.94863 14.724 4.977 14.7659 5.01268 14.8009L9.97943 19.7219C10.0524 19.7936 10.1501 19.8337 10.2517 19.8337C10.3534 19.8337 10.4511 19.7936 10.524 19.7219L14.0486 16.2292C14.0668 16.2111 14.0913 16.201 14.1168 16.201C14.1423 16.201 14.1668 16.2111 14.185 16.2292L17.7096 19.7219C17.7825 19.7936 17.8802 19.8337 17.9819 19.8337C18.0836 19.8337 18.1813 19.7936 18.2542 19.7219L23.2205 14.8009C23.2561 14.7659 23.2845 14.724 23.3039 14.6776C23.3233 14.6313 23.3332 14.5815 23.3332 14.5312C23.3332 14.4809 23.3233 14.4311 23.3039 14.3848C23.2845 14.3385 23.2561 14.2966 23.2205 14.2616Z"
                fill="white"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_1042_28372">
                <Rect width="28" height="28" rx="8" fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
