import React from 'react'
import { Path } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

import { Color, colors } from '../colors'

type Props = {
    color?: Color
    size: number
}

export const BoldDisconnected = ({ size, color }: Props) => {
    return (
        <SvgIcon
            viewBox="0 0 24 24"
            width={size}
            height={size}
            color={color && colors[color]}
            fill="none"
        >
            <Path
                d="M13.2385 12.0371L12.0464 13.2292L10.7707 11.9535L11.9628 10.7614C12.1843 10.5399 12.1843 10.1807 11.9628 9.95922C11.7413 9.73773 11.3822 9.7377 11.1606 9.95922L9.96849 11.1514L9.15153 10.3344C9.04515 10.228 8.90088 10.1682 8.75045 10.1682C8.59999 10.1682 8.45576 10.228 8.34938 10.3344L6.3399 12.3439C5.62987 13.0539 5.23886 13.9979 5.23886 15.002C5.23886 15.8144 5.49495 16.5875 5.96878 17.2291L3.16584 20.032C2.94431 20.2535 2.94431 20.6126 3.16584 20.8342C3.2766 20.9449 3.42177 21.0003 3.56695 21.0003C3.71212 21.0003 3.85729 20.9449 3.96802 20.8341L6.77085 18.0312C7.41246 18.505 8.18549 18.7611 8.99792 18.7611C10.002 18.7611 10.946 18.3701 11.656 17.66L13.6655 15.6506C13.7719 15.5442 13.8317 15.3999 13.8317 15.2495C13.8317 15.0991 13.7719 14.9548 13.6655 14.8484L12.8486 14.0315L14.0407 12.8393C14.2622 12.6178 14.2622 12.2587 14.0407 12.0371C13.8192 11.8155 13.46 11.8155 13.2385 12.0371Z"
                fill="currentColor"
            />
            <Path
                d="M20.8338 3.1659C20.6123 2.94438 20.2532 2.94438 20.0317 3.1659L18.135 5.06257C16.665 3.98045 14.5794 4.10406 13.2501 5.43343L11.2406 7.44295C11.0191 7.66447 11.0191 8.02361 11.2406 8.24513L15.7547 12.7592C15.8654 12.87 16.0106 12.9253 16.1557 12.9253C16.3009 12.9253 16.4461 12.8699 16.5568 12.7592L18.5663 10.7497C19.8957 9.42032 20.0193 7.33483 18.9372 5.86479L20.8339 3.96812C21.0554 3.74656 21.0554 3.38742 20.8338 3.1659Z"
                fill="currentColor"
            />
        </SvgIcon>
    )
}
