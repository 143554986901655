import { FormattedMessage } from 'react-intl'

import { ActionBar } from '@zeal/uikit/ActionBar'
import { Actions } from '@zeal/uikit/Actions'
import { Button } from '@zeal/uikit/Button'
import { Column } from '@zeal/uikit/Column'
import { Header } from '@zeal/uikit/Header'
import { BackIcon } from '@zeal/uikit/Icon/BackIcon'
import { BoldWallet } from '@zeal/uikit/Icon/BoldWallet'
import { IconButton } from '@zeal/uikit/IconButton'
import { Screen } from '@zeal/uikit/Screen'

import { notReachable } from '@zeal/toolkit'
import { ZealPlatform } from '@zeal/toolkit/OS/ZealPlatform'

import { ConnectionState } from '../../types'

type Props = {
    connectionState: Extract<
        ConnectionState,
        { type: 'reconnecting' | 'connecting' | 'disconnected' }
    >
    onMsg: (msg: Msg) => void
}

type Msg = { type: 'on_connect_wallet_clicked' } | { type: 'close' }

export const ConnectWallet = ({ connectionState, onMsg }: Props) => {
    return (
        <Screen
            padding="form"
            background="light"
            onNavigateBack={() => onMsg({ type: 'close' })}
        >
            <ActionBar
                left={(() => {
                    switch (ZealPlatform.OS) {
                        case 'ios':
                        case 'android':
                            return (
                                <IconButton
                                    variant="on_light"
                                    onClick={() => onMsg({ type: 'close' })}
                                >
                                    {({ color }) => (
                                        <BackIcon size={24} color={color} />
                                    )}
                                </IconButton>
                            )
                        case 'web':
                            return null
                        /* istanbul ignore next */
                        default:
                            return notReachable(ZealPlatform.OS)
                    }
                })()}
            />
            <Column spacing={0} fill alignY="stretch">
                <Column spacing={0} fill alignY="center">
                    <Header
                        title={
                            <FormattedMessage
                                id="zeal-dapp.connect-wallet.title"
                                defaultMessage="Connect wallet to continue"
                            />
                        }
                        icon={({ size, color }) => (
                            <BoldWallet size={size} color={color} />
                        )}
                    />
                </Column>
                <Actions>
                    {(() => {
                        switch (ZealPlatform.OS) {
                            case 'ios':
                            case 'android':
                                return (
                                    <Button
                                        variant="secondary"
                                        size="regular"
                                        onClick={() => onMsg({ type: 'close' })}
                                    >
                                        <FormattedMessage
                                            id="zeal-dapp.connect-wallet.cta.secondary"
                                            defaultMessage="Cancel"
                                        />
                                    </Button>
                                )
                            case 'web':
                                return null
                            /* istanbul ignore next */
                            default:
                                return notReachable(ZealPlatform.OS)
                        }
                    })()}
                    <MainCTA connectionState={connectionState} onMsg={onMsg} />
                </Actions>
            </Column>
        </Screen>
    )
}

const MainCTA = ({ connectionState, onMsg }: Props) => {
    switch (connectionState.type) {
        case 'disconnected':
            return (
                <Button
                    variant="primary"
                    size="regular"
                    onClick={() => onMsg({ type: 'on_connect_wallet_clicked' })}
                >
                    <FormattedMessage
                        id="zeal-dapp.connect-wallet.cta.primary.disconnected"
                        defaultMessage="Connect"
                    />
                </Button>
            )
        case 'connecting':
        case 'reconnecting':
            return (
                <Button disabled variant="primary" size="regular">
                    <FormattedMessage
                        id="zeal-dapp.connect-wallet.cta.primary.connecting"
                        defaultMessage="Connecting..."
                    />
                </Button>
            )
        /* istanbul ignore next */
        default:
            return notReachable(connectionState.type)
    }
}
