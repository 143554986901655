import { captureAppError } from './captureAppError'

import { parseAppError } from '../parsers/parseAppError'

type Params = {
    extra?: Record<string, unknown>
}

export const captureError = (error: unknown, params?: Params): void => {
    const parsed = parseAppError(error)

    switch (parsed.type) {
        case 'connectivity_error':
            // We don't want to capture connectivity errors
            return

        default:
            return captureAppError(parsed, {
                source: 'manually_captured',
                extra: params?.extra,
            })
    }
}
