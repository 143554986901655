import React from 'react'
import { Pressable, StyleSheet } from 'react-native'

import { ZealPlatform } from '@zeal/toolkit/OS/ZealPlatform'

type Props = {
    disabled?: true
    onClick: () => void
    children: React.ReactNode
}

const styles = StyleSheet.create({
    pressable: {
        justifyContent: 'center',
        ...(ZealPlatform.OS === 'web' ? { cursor: 'pointer' } : {}),
        zIndex: 1,
    },
    pressed: {
        opacity: 0.7,
        zIndex: 1,
    },
})

export const Clickable = ({ disabled, onClick, children }: Props) => {
    return (
        <Pressable
            disabled={disabled}
            onPress={onClick}
            style={({ pressed }) => [
                styles.pressable,
                pressed && styles.pressed,
            ]}
        >
            {children}
        </Pressable>
    )
}
