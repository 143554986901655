import { SwapQuoteRequest } from '@zeal/domains/Currency/domains/SwapQuote'
import { fetchSwapQuote } from '@zeal/domains/Currency/domains/SwapQuote/api/fetchSwapQuote'

import { fetchTakerUserCurrencyRate } from './fetchTakerUserCurrencyRate'

import { EarnDepositQuote, EarnDepositQuoteRequest } from '..'
import { DEFAULT_EARN_SLIPPAGE_PERCENT } from '../constants'

export const fetchEarnDepositQuote = async ({
    networkRPCMap,
    networkMap,
    amount,
    fromAccount,
    fromCurrency,
    taker,
    network,
    knownCurrencies,
}: EarnDepositQuoteRequest): Promise<EarnDepositQuote> => {
    const swapQuoteRequest: SwapQuoteRequest = {
        amount,
        fromAccount,
        fromCurrency,
        knownCurrencies,
        networkMap,
        toCurrency: taker.cryptoCurrency,
        recipient: taker.address,
        swapSlippagePercent: DEFAULT_EARN_SLIPPAGE_PERCENT,
        usedDexName: null,
    }

    const [takerUserCurrencyRate, swapQuote] = await Promise.all([
        fetchTakerUserCurrencyRate(taker, networkRPCMap),
        fetchSwapQuote(swapQuoteRequest),
    ])

    return {
        swapRoute: swapQuote.routes.length > 0 ? swapQuote.routes[0] : null,
        takerUserCurrencyRate,
        knownCurrencies: {
            ...knownCurrencies,
            ...swapQuote.knownCurrencies,
        },
        network,
    }
}
