import Web3 from 'web3'

import { notReachable } from '@zeal/toolkit'
import * as Hexadecimal from '@zeal/toolkit/Hexadecimal'
import { generateRandomNumber } from '@zeal/toolkit/Number'
import * as Web3Toolkit from '@zeal/toolkit/Web3'

import { Account } from '@zeal/domains/Account'
import { Address } from '@zeal/domains/Address'
import { KnownCurrencies } from '@zeal/domains/Currency'
import { CryptoMoney, Money } from '@zeal/domains/Money'
import { Network, NetworkRPCMap } from '@zeal/domains/Network'
import { getNativeTokenAddress } from '@zeal/domains/Network/helpers/getNativeTokenAddress'
import { EthSendTransaction } from '@zeal/domains/RPCRequest'
import { ZealWeb3RPCProvider } from '@zeal/domains/RPCRequest/helpers/ZealWeb3RPCProvider'

/**
 * @deprecated kill this and use ERC20_ABI from constants
 */
const ERC20_ABI = [
    {
        inputs: [
            { internalType: 'address', name: 'to', type: 'address' },
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
        ],
        name: 'transfer',
        outputs: [{ internalType: 'bool', name: 'ok', type: 'bool' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
] as const

type Params = {
    fromAccount: Account
    toAddress: Address
    network: Network
    networkRPCMap: NetworkRPCMap
    amount: Money
    knownCurrencies: KnownCurrencies
}

/**
 * @deprecated try to use createTransferEthSendTransaction if you can
 */
export const createERC20EthSendTransaction = ({
    fromAccount,
    knownCurrencies,
    toAddress,
    network,
    networkRPCMap,
    amount,
}: Params): EthSendTransaction => {
    const currency = knownCurrencies[amount.currencyId]
    if (!currency) {
        throw new Error(
            `Trying to create ERC20 trx, but fined to look up currency ${amount.currencyId}`
        )
    }
    switch (currency.type) {
        case 'FiatCurrency':
            throw new Error(
                `Trying to create ERC20 trx, but got FiatCurrency ${currency.id}`
            )
        case 'CryptoCurrency':
            const isTrxInNativeCurrency =
                currency.address === getNativeTokenAddress(network)
            if (isTrxInNativeCurrency) {
                return {
                    id: generateRandomNumber(),
                    jsonrpc: '2.0' as const,
                    method: 'eth_sendTransaction' as const,
                    params: [
                        {
                            from: fromAccount.address,
                            data: '',
                            to: toAddress,
                            value: '0x' + amount.amount.toString(16),
                        },
                    ],
                }
            } else {
                const web3 = new Web3(
                    new ZealWeb3RPCProvider({ network, networkRPCMap })
                )
                const contract = new web3.eth.Contract(
                    ERC20_ABI,
                    currency.address,
                    { from: fromAccount.address }
                )
                const hexAmount: string = '0x' + amount.amount.toString(16)
                const data: string = contract.methods
                    .transfer(toAddress, hexAmount)
                    .encodeABI()

                return {
                    id: generateRandomNumber(),
                    jsonrpc: '2.0' as const,
                    method: 'eth_sendTransaction' as const,
                    params: [
                        {
                            from: fromAccount.address,
                            data,
                            to: currency.address,
                        },
                    ],
                }
            }

        /* istanbul ignore next */
        default:
            return notReachable(currency)
    }
}

export const createTransferEthSendTransaction = ({
    amount,
    to,
    network,
    from,
}: {
    from: Address
    to: Address
    amount: CryptoMoney
    network: Network
}): EthSendTransaction =>
    amount.currency.address === getNativeTokenAddress(network)
        ? {
              id: generateRandomNumber(),
              jsonrpc: '2.0' as const,
              method: 'eth_sendTransaction' as const,
              params: [
                  {
                      from,
                      data: '',
                      to,
                      value: Hexadecimal.fromBigInt(amount.amount),
                  },
              ],
          }
        : {
              id: generateRandomNumber(),
              jsonrpc: '2.0' as const,
              method: 'eth_sendTransaction' as const,
              params: [
                  {
                      from: from,
                      to: amount.currency.address,
                      data: Web3Toolkit.abi.encodeFunctionData({
                          abi: ERC20_ABI,
                          functionName: 'transfer',
                          args: [to as `0x${string}`, amount.amount],
                      }),
                  },
              ],
          }
