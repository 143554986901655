import { failure, Result, success } from '@zeal/toolkit/Result'

import { ConnectivityError } from '@zeal/domains/Error'

export const parseConnectivityError = (
    input: unknown
): Result<unknown, ConnectivityError> =>
    input instanceof ConnectivityError && input.isConnectivityError
        ? success(input)
        : failure('not_correct_instance')
