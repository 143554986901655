import { nativeApplicationVersion } from 'expo-application'

import { notReachable } from '@zeal/toolkit'
import { ZealPlatform } from '@zeal/toolkit/OS/ZealPlatform'

export const getAppVersion = () => {
    switch (ZealPlatform.OS) {
        case 'ios':
        case 'android':
            return nativeApplicationVersion || ''
        case 'web':
            return process.env.ZEAL_APP_VERSION
        /* istanbul ignore next */
        default:
            return notReachable(ZealPlatform.OS)
    }
}
