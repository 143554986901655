import React from 'react'
import {
    GestureResponderEvent,
    Pressable,
    StyleSheet,
    View,
} from 'react-native'

import { Text } from '@zeal/uikit/Text'

import { notReachable } from '@zeal/toolkit'
import { ZealPlatform } from '@zeal/toolkit/OS/ZealPlatform'

import { colors } from '../colors'

const styles = StyleSheet.create({
    base: {
        flexGrow: 1,
        overflow: 'hidden',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        borderRadius: 8,
        backgroundColor: colors.actionPrimaryDefault,
        paddingVertical: 8,
        paddingHorizontal: 12,
        ...(() => {
            switch (ZealPlatform.OS) {
                case 'ios':
                case 'android':
                    return {}
                case 'web':
                    return { cursor: 'pointer' }
                /* istanbul ignore next */
                default:
                    return notReachable(ZealPlatform.OS)
            }
        })(),
    },

    hovered: {
        backgroundColor: colors.actionPrimaryHovered,
    },

    active: {
        backgroundColor: colors.actionPrimaryPressed,
    },
})

type Props = {
    onClick?: (e: GestureResponderEvent) => void
    children: React.ReactNode
}

export const ListItemSideButton = ({ onClick, children }: Props) => {
    return (
        <Pressable role="button" onPress={onClick}>
            {({ pressed, hovered }) => {
                return (
                    <View
                        style={[
                            styles.base,
                            hovered && styles.hovered,
                            pressed && styles.active,
                        ]}
                    >
                        <Text
                            ellipsis
                            color="textOnPrimary"
                            variant="caption1"
                            weight="medium"
                        >
                            {children}
                        </Text>
                    </View>
                )
            }}
        </Pressable>
    )
}
