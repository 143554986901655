import {
    failure,
    object,
    Result,
    shape,
    string,
    success,
} from '@zeal/toolkit/Result'

import { PasskeyAndroidUnableToGetSyncAccount } from '@zeal/domains/Error/domains/Passkey'

export const parsePasskeyAndroidUnableToGetSyncAccount = (
    input: unknown
): Result<unknown, PasskeyAndroidUnableToGetSyncAccount> =>
    object(input)
        .andThen((obj) =>
            shape({
                message: string(obj.message).andThen((msg) =>
                    msg.match('CreateCredentialCustomException') &&
                    msg.match('Unable to get sync account')
                        ? success(msg)
                        : failure({
                              type: 'message_does_not_match_regexp',
                              msg,
                          })
                ),
            })
        )
        .map(() => ({ type: 'passkey_android_unable_to_get_sync_account' }))
