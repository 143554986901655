import React from 'react'
import { StyleSheet, View } from 'react-native'

import { Avatar } from '../Avatar'
import { Clickable } from '../Clickable'
import { LightArrowDown3 } from '../Icon/LightArrowDown3'

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        height: 0,
        zIndex: 1,
    },
})

type Props = {
    onClick?: () => void
}

export const NextStepSeparator = ({ onClick }: Props) => {
    const nextStepIcon = (
        <Avatar
            backgroundColor="surfaceDefault"
            border="borderSecondary"
            size={40}
        >
            <LightArrowDown3 color="iconDefault" size={16} />
        </Avatar>
    )

    return (
        <View style={styles.container}>
            {onClick ? (
                <Clickable onClick={onClick}>{nextStepIcon}</Clickable>
            ) : (
                <View style={styles.container}>{nextStepIcon}</View>
            )}
        </View>
    )
}
