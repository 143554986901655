import { useEffect, useState } from 'react'

import { GetAccountReturnType } from '@wagmi/core'
import { useWalletInfo, useWeb3Modal } from '@web3modal/wagmi-react-native'
import { useAccount, useDisconnect, useSendTransaction } from 'wagmi'

import { notReachable } from '@zeal/toolkit'
import { MsgOf } from '@zeal/toolkit/MsgOf'
import { ZealPlatform } from '@zeal/toolkit/OS/ZealPlatform'

import { ZealDAppEntryPoint } from '@zeal/domains/Main'
import { NetworkRPCMap, PredefinedNetwork } from '@zeal/domains/Network'
import { parseNetworkHexId } from '@zeal/domains/Network/helpers/parse'
import { fetchCurrentNonce } from '@zeal/domains/RPCRequest/api/fetchCurrentNonce'

import { Layout } from './Layout'
import { Modal, State as ModalState } from './Modal'
import { parseConnectedAccount } from './parseConnectedAccount'

import {
    ConnectedNetwork,
    ConnectionState,
    SendTransactionToExternalWallet,
} from '../types'

type Props = {
    entryPoint: ZealDAppEntryPoint
    supportedNetworks: PredefinedNetwork[]
    networkRPCMap: NetworkRPCMap
    onMsg: (msg: Msg) => void
}

type Msg = Extract<
    MsgOf<typeof Layout>,
    {
        type:
            | 'on_top_up_transaction_complete_close'
            | 'on_external_earn_deposit_completed_close_click'
            | 'close'
    }
>

const calculateConnectedNetwork = ({
    supportedNetworks,
    externalAccount,
}: {
    externalAccount: Extract<GetAccountReturnType, { status: 'connected' }>
    supportedNetworks: PredefinedNetwork[]
}): ConnectedNetwork => {
    // externalAccount.chain is undefined if you switch to a network in wallet that is not defined in wagmi config
    if (!externalAccount.chain) {
        return { type: 'unsupported_network' }
    }

    const networkHexId = parseNetworkHexId(
        externalAccount.chainId.toString(10)
    ).getSuccessResultOrThrow('cannot parse wagmi chainId')

    const network = supportedNetworks.find(
        (network) => network.hexChainId === networkHexId
    )

    return network
        ? { type: 'supported_network', network }
        : { type: 'unsupported_network' }
}

const calculateConnectionState = ({
    externalAccount,
    supportedNetworks,
    walletInfo,
}: {
    externalAccount: GetAccountReturnType
    supportedNetworks: PredefinedNetwork[]
    walletInfo?: ReturnType<typeof useWalletInfo>['walletInfo'] // This is mocked on web so will return undefined
}): ConnectionState => {
    switch (externalAccount.status) {
        case 'connected':
            return {
                type: 'connected',
                account: parseConnectedAccount({
                    walletConnectWalletInfo: walletInfo,
                    wagmiExternalAccount: externalAccount,
                }).getSuccessResultOrThrow('cannot parse connected account'),
                network: calculateConnectedNetwork({
                    supportedNetworks,
                    externalAccount,
                }),
            }
        case 'reconnecting':
            return { type: 'reconnecting' }
        case 'connecting':
            return { type: 'connecting' }
        case 'disconnected':
            return { type: 'disconnected' }
        /* istanbul ignore next */
        default:
            return notReachable(externalAccount)
    }
}

export const EntryPoint = ({
    entryPoint,
    supportedNetworks,
    networkRPCMap,
    onMsg,
}: Props) => {
    const [modal, setModal] = useState<ModalState>({ type: 'closed' })

    const { open } = useWeb3Modal()
    const externalAccount = useAccount()
    const { walletInfo } = useWalletInfo()
    const { disconnect } = useDisconnect()

    const { sendTransactionAsync } = useSendTransaction()

    const sendTransaction: SendTransactionToExternalWallet = async ({
        request,
        network,
    }) => {
        const { from, to, data, value } = request.params[0]
        const nonce = await fetchCurrentNonce({
            address: from,
            network: network,
            networkRPCMap: {},
        })

        const transactionHash = await sendTransactionAsync({
            ...{
                to: to ? (to as `0x${string}`) : undefined,
                value: value ? BigInt(value) : undefined,
                data: data as `0x${string}`,
            },
            nonce,
        })

        return { transactionHash, submittedNonce: nonce }
    }

    const [connectionState, setConnectionState] = useState<ConnectionState>(
        calculateConnectionState({
            externalAccount,
            walletInfo,
            supportedNetworks,
        })
    )

    // Adding the account object as a dependency to the useEffect below causes an infinite re-render since useAccount returns an un-memoized object.
    useEffect(
        () => {
            setConnectionState(
                calculateConnectionState({
                    externalAccount,
                    walletInfo,
                    supportedNetworks,
                })
            )
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            externalAccount.status,
            externalAccount.address,
            externalAccount.chainId,
            walletInfo,
        ]
    )

    return (
        <>
            <Layout
                networkRPCMap={networkRPCMap}
                supportedNetworks={supportedNetworks}
                sendTransaction={sendTransaction}
                connectionState={connectionState}
                entryPoint={entryPoint}
                onMsg={(msg) => {
                    switch (msg.type) {
                        case 'on_connect_wallet_clicked':
                            switch (ZealPlatform.OS) {
                                case 'ios':
                                case 'android':
                                    open()
                                    break
                                case 'web':
                                    setModal({
                                        type: 'wallet_selector',
                                    })
                                    break
                                /* istanbul ignore next */
                                default:
                                    return notReachable(ZealPlatform.OS)
                            }
                            break

                        case 'on_disconnect_clicked':
                            disconnect()
                            break

                        case 'close':
                        case 'on_top_up_transaction_complete_close':
                        case 'on_external_earn_deposit_completed_close_click':
                            onMsg(msg)
                            break
                        /* istanbul ignore next */
                        default:
                            return notReachable(msg)
                    }
                }}
            />
            <Modal
                state={modal}
                onMsg={(msg) => {
                    switch (msg.type) {
                        case 'close':
                        case 'on_wallet_connected_successfully':
                            setModal({ type: 'closed' })
                            break
                        /* istanbul ignore next */
                        default:
                            return notReachable(msg)
                    }
                }}
            />
        </>
    )
}
