export type PasskeyError =
    | PasskeyOperationCancelled
    | PasskeySignerNotFoundError
    | PasskeyAppNotAssociatedWithDomain
    | PasskeyAndroidNoCredentialAvailable
    | PasskeyAndroidCannotValidateIncomingRequest
    | PasskeyAndroidNoCreateOptionsAvailable
    | PasskeyAndroidResidentKeyCreationNotSupported
    | PasskeyScreenLockMissing
    | PasskeyGoogleAccountMissing
    | PasskeyAndroidFailedToLaunchSelector
    | PasskeyAndroidProviderConfigurationError
    | PasskeyAndroidFidoApiNotAvailable
    | PasskeyAndroidUnableToGetSyncAccount
    | PasskeyAndroidUnknownError

export type PasskeyOperationCancelled = { type: 'passkey_operation_cancelled' }

export class PasskeySignerNotFoundError extends Error {
    isPasskeySignerNotFoundError = true
    type: 'passkey_signer_not_found_error'
    name = 'PasskeySignerNotFoundError'
    recoveryId: string

    constructor(recoveryId: string) {
        super()
        this.type = 'passkey_signer_not_found_error'
        this.recoveryId = recoveryId
    }
}

export type PasskeyAppNotAssociatedWithDomain = {
    type: 'passkey_app_not_associated_with_domain'
}

export type PasskeyAndroidNoCredentialAvailable = {
    type: 'passkey_android_no_credential_available'
}

export type PasskeyAndroidCannotValidateIncomingRequest = {
    type: 'passkey_android_cannot_validate_incoming_request'
}

export type PasskeyAndroidNoCreateOptionsAvailable = {
    type: 'passkey_android_no_create_options_available'
}

export type PasskeyAndroidResidentKeyCreationNotSupported = {
    type: 'passkey_android_resident_key_creation_not_supported'
}

export type PasskeyScreenLockMissing = {
    type: 'passkey_screen_lock_missing'
}

export type PasskeyGoogleAccountMissing = {
    type: 'passkey_google_account_missing'
}

export type PasskeyAndroidFailedToLaunchSelector = {
    type: 'passkey_android_failed_to_launch_selector'
}

export type PasskeyAndroidProviderConfigurationError = {
    type: 'passkey_android_provider_configuration_error'
}

export type PasskeyAndroidFidoApiNotAvailable = {
    type: 'passkey_android_fido_api_not_available'
}

export type PasskeyAndroidUnableToGetSyncAccount = {
    type: 'passkey_android_unable_to_get_sync_account'
}

export type PasskeyAndroidUnknownError = {
    type: 'passkey_android_unknown_error'
    originalError: unknown
}
