import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

import { notReachable } from '@zeal/toolkit'
import { useLoadableData } from '@zeal/toolkit/LoadableData/LoadableData'
import { useLiveRef } from '@zeal/toolkit/React'

import { EarnDepositRequest } from '@zeal/domains/Earn'
import { FormattedTokenBalanceWithSymbol2 } from '@zeal/domains/Money/components/FormattedTokenBalanceWithSymbol'
import { SubmitedTransaction } from '@zeal/domains/TransactionRequest/domains/SubmitedTransaction'

import { TransactionSentToWalletPopup } from '../../../../../components/TransactionSentToWalletPopup'
import { SendTransactionToExternalWallet } from '../../../../../types'

type Props = {
    earnDepositRequest: EarnDepositRequest

    sendTransaction: SendTransactionToExternalWallet
    onMsg: (msg: Msg) => void
}

type Msg =
    | { type: 'close' }
    | {
          type: 'on_earn_deposit_submitted'
          submitedDeposit: SubmitedTransaction
      }
    | { type: 'on_transaction_rejected' }

export const SubmitDepositTransaction = ({
    earnDepositRequest,
    sendTransaction,
    onMsg,
}: Props) => {
    const { swapTransaction } = earnDepositRequest.swapRoute
    const [loadable] = useLoadableData(sendTransaction, {
        type: 'loading',
        params: {
            network: earnDepositRequest.network,
            request: swapTransaction,
        },
    })

    const onMsgLive = useLiveRef(onMsg)

    useEffect(() => {
        switch (loadable.type) {
            case 'loading':
                break
            case 'loaded':
                onMsgLive.current({
                    type: 'on_earn_deposit_submitted',
                    submitedDeposit: {
                        state: 'queued',
                        queuedAt: Date.now(),
                        hash: loadable.data.transactionHash,
                        submittedNonce: loadable.data.submittedNonce,
                    },
                })
                break
            case 'error':
                // There is currently no clean way to distinguish between a transaction being rejected in the wallet vs some other error occurring since different wallets respond differently.
                onMsgLive.current({
                    type: 'on_transaction_rejected',
                })
                break
            /* istanbul ignore next */
            default:
                return notReachable(loadable)
        }
    }, [loadable, onMsgLive])

    return (
        <TransactionSentToWalletPopup
            fromAccount={earnDepositRequest.fromAccount}
            title={
                <FormattedMessage
                    id="earn-deposit.submit-deposit.sent-to-wallet"
                    defaultMessage="Deposit {amount}"
                    values={{
                        amount: (
                            <FormattedTokenBalanceWithSymbol2
                                money={earnDepositRequest.from}
                            />
                        ),
                    }}
                />
            }
            onMsg={onMsg}
        />
    )
}
