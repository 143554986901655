import { Modal as UIModal } from '@zeal/uikit/Modal'

import { notReachable } from '@zeal/toolkit'
import { MsgOf } from '@zeal/toolkit/MsgOf'

import { CryptoCurrency } from '@zeal/domains/Currency'
import { EarnDepositRequest } from '@zeal/domains/Earn'
import { NetworkRPCMap, PredefinedNetwork } from '@zeal/domains/Network'
import { Portfolio } from '@zeal/domains/Portfolio'

import { SelectFromToken } from './SelectFromToken'
import { SubmitDeposit } from './SubmitDeposit'

import { Connected, SendTransactionToExternalWallet } from '../../../../types'

type Props = {
    state: State
    fromCurrencies: CryptoCurrency[]
    selectedCurrency: CryptoCurrency | null
    portfolio: Portfolio

    supportedNetworks: PredefinedNetwork[]
    networkRPCMap: NetworkRPCMap
    installationId: string
    sendTransaction: SendTransactionToExternalWallet
    connectionState: Connected

    onMsg: (msg: Msg) => void
}

type Msg = MsgOf<typeof SelectFromToken> | MsgOf<typeof SubmitDeposit>

export type State =
    | { type: 'closed' }
    | { type: 'select_from_currency' }
    | {
          type: 'submit_deposit_from_external_wallet'
          earnDepositRequest: EarnDepositRequest
      }

export const Modal = ({
    state,
    fromCurrencies,
    portfolio,
    selectedCurrency,
    installationId,
    connectionState,
    supportedNetworks,
    networkRPCMap,
    sendTransaction,
    onMsg,
}: Props) => {
    switch (state.type) {
        case 'closed':
            return null

        case 'select_from_currency':
            return (
                <UIModal>
                    <SelectFromToken
                        connectionState={connectionState}
                        fromCurrencies={fromCurrencies}
                        portfolio={portfolio}
                        selectedCurrency={selectedCurrency}
                        supportedNetworks={supportedNetworks}
                        onMsg={onMsg}
                    />
                </UIModal>
            )

        case 'submit_deposit_from_external_wallet':
            return (
                <UIModal>
                    <SubmitDeposit
                        earnDepositRequest={state.earnDepositRequest}
                        networkRPCMap={networkRPCMap}
                        installationId={installationId}
                        sendTransaction={sendTransaction}
                        onMsg={onMsg}
                    />
                </UIModal>
            )

        default:
            return notReachable(state)
    }
}
