import { FormattedMessage } from 'react-intl'

import { notReachable } from '@zeal/toolkit'

import { TakerConfig } from '@zeal/domains/Earn'

type Props = {
    taker: TakerConfig
}

export const TakerTitle = ({ taker }: Props) => {
    switch (taker.type) {
        case 'usd':
            return (
                <FormattedMessage
                    id="earn.takerListItem.earnUSD.title"
                    defaultMessage="US Dollar"
                />
            )
        case 'eur':
            return (
                <FormattedMessage
                    id="earn.takerListItem.earnEURO.title"
                    defaultMessage="Euro"
                />
            )
        case 'eth':
            return (
                <FormattedMessage
                    id="earn.takerListItem.earnETH.title"
                    defaultMessage="Ethereum"
                />
            )

        /* istanbul ignore next */
        default:
            return notReachable(taker.type)
    }
}
