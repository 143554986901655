import { notReachable } from '@zeal/toolkit'
import {
    arrayOf,
    bigint,
    failure,
    match,
    nullableOf,
    number,
    object,
    oneOf,
    recordStrict,
    Result,
    shape,
    string,
    success,
    ValidObject,
} from '@zeal/toolkit/Result'

import { parse as parseAddress } from '@zeal/domains/Address/helpers/parse'
import { parseCryptoCurrency } from '@zeal/domains/Currency/helpers/parse'
import { Earn, EarnMap, Taker, TakerApyMap } from '@zeal/domains/Earn'
import { DEFAULT_TAKER_APY_MAP } from '@zeal/domains/Earn/constants'
import { parse as parsePortfolio } from '@zeal/domains/Portfolio/helpers/parse'

export const parseEarnMap = (input: unknown): Result<unknown, EarnMap> => {
    return oneOf(input, [
        object(input).andThen((obj) =>
            recordStrict(obj, {
                keyParser: parseAddress,
                valueParser: parseEarn,
            })
        ),
        success({}),
    ])
}

const TAKER_TYPE_MAP: Record<Taker['type'], true> = {
    usd: true,
    eur: true,
    eth: true,
}

const _dont_forget = (earn: Earn) => {
    switch (earn.type) {
        case 'not_configured':
        case 'configured':
            // !! don't forget to add EARN parser
            break
        /* istanbul ignore next */
        default:
            return notReachable(earn)
    }
}

const parseEarn = (input: unknown): Result<unknown, Earn> => {
    return object(input).andThen((obj) => {
        return oneOf(obj, [
            shape({
                type: match(obj.type, 'not_configured'),
                takerApyMap: parseTakerApyMap(obj),
            }),
            shape({
                type: match(obj.type, 'configured'),
                holder: parseAddress(obj.holder),
                takers: arrayOf(obj.takers, parseTaker),
                takerApyMap: parseTakerApyMap(obj),
                portfolio: oneOf(obj.type, [
                    object(obj.portfolio).andThen((portfolio) =>
                        recordStrict(portfolio, {
                            valueParser: parsePortfolio,
                            keyParser: parseAddress,
                        })
                    ),
                    success({}),
                ]),
                cardRecharge: oneOf(obj.cardRecharge, [
                    object(obj.cardRecharge).andThen((cardRecharge) =>
                        shape({
                            type: match(cardRecharge.type, 'recharge_enabled'),
                            threshold: bigint(cardRecharge.threshold),
                            rebalancers: arrayOf(
                                cardRecharge.rebalancers,
                                parseTaker
                            ),
                            recipient: parseAddress(cardRecharge.recipient),
                            recipientToken: object(
                                cardRecharge.recipientToken
                            ).andThen(parseCryptoCurrency),
                            lastRechargeTransactionHash: nullableOf(
                                cardRecharge.lastRechargeTransactionHash,
                                string
                            ),
                        })
                    ),
                    success({
                        type: 'recharge_disabled' as const,
                    }),
                ]),
            }),
        ])
    })
}

const parseTakerApyMap = (obj: ValidObject): Result<unknown, TakerApyMap> => {
    return oneOf(obj.takerApyMap, [
        object(obj.takerApyMap).andThen((takerApyMap) =>
            recordStrict(takerApyMap, {
                valueParser: number,
                keyParser: parseTakerType,
            })
        ),
        success(DEFAULT_TAKER_APY_MAP),
    ])
}

const parseTakerType = (input: unknown): Result<unknown, Taker['type']> => {
    return string(input).andThen((objType) =>
        TAKER_TYPE_MAP[objType as Taker['type']]
            ? success(objType as Taker['type'])
            : failure(`unknown taker ${objType}`)
    )
}

export const parseTaker = (input: unknown): Result<unknown, Taker> => {
    return object(input).andThen((obj) =>
        shape({
            type: parseTakerType(obj.type),
            address: parseAddress(obj.address),
            cryptoCurrency: object(obj.cryptoCurrency).andThen(
                parseCryptoCurrency
            ),
        })
    )
}
