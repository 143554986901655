import { GNOSIS_PAY_BASE_URL } from '@zeal/api/gnosisApi'

import {
    failure,
    match,
    object,
    Result,
    shape,
    success,
} from '@zeal/toolkit/Result'

import { parseHttpError } from './parseHttpError'

import {
    GnosisPayIsNotAvailableInThisCountry,
    GnosisPayNoActiveCardsFound,
} from '../AppError'

export const parseGnosisPayIsNotAvailableInThisCountry = (
    input: unknown
): Result<unknown, GnosisPayIsNotAvailableInThisCountry> => {
    return parseHttpError(input).andThen((httpError) =>
        shape({
            urlPrefix: httpError.url.startsWith(GNOSIS_PAY_BASE_URL)
                ? success(true)
                : failure({ type: 'not_gnosis_pay_base_url' }),
            status: match(httpError.status, 403),
            payload: object(httpError.data).andThen((data) =>
                shape({
                    code: match(data.code, 'WAFForbidden'),
                    message: match(data.message, 'forbidden'),
                })
            ),
        }).map(
            () =>
                ({
                    type: 'gnosis_pay_is_not_available_in_this_country',
                } as const)
        )
    )
}

export const parseGnosisPayNoActiveCardsFound = (
    input: unknown
): Result<unknown, GnosisPayNoActiveCardsFound> =>
    parseHttpError(input).andThen((httpError) =>
        shape({
            urlPrefix: httpError.url.startsWith(GNOSIS_PAY_BASE_URL)
                ? success(true)
                : failure({ type: 'not_gnosis_pay_base_url' }),
            status: match(httpError.status, 404),
            payload: object(httpError.data).andThen((data) =>
                shape({
                    error: match(data.error, 'No activated cards found'),
                })
            ),
        }).map(
            () =>
                ({
                    type: 'gnosis_pay_no_active_cards_found',
                } as const)
        )
    )
