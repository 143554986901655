import {
    failure,
    object,
    Result,
    shape,
    string,
    success,
} from '@zeal/toolkit/Result'

import { IosCouldNotCommunicateWithHelperApplication } from '@zeal/domains/Error'

export const parseIosUnableToCommunicateWithHelperApplication = (
    input: unknown
): Result<unknown, IosCouldNotCommunicateWithHelperApplication> =>
    object(input)
        .andThen((obj) =>
            shape({
                message: string(obj.message).andThen((msg) =>
                    msg.match('communicate with a helper application')
                        ? success(msg)
                        : failure({
                              type: 'message_does_not_match_regexp',
                              msg,
                          })
                ),
            })
        )
        .map(() => ({
            type: 'ios_could_not_communicate_with_helper_application',
        }))
