import React from 'react'
import { Path } from 'react-native-svg'

import { Color, colors } from '@zeal/uikit/colors'
import { SvgIcon } from '@zeal/uikit/SvgIcon'

type Props = {
    size: number
    color?: Color
}

export const Spam = ({ size, color }: Props) => {
    return (
        <SvgIcon
            fill="transparent"
            viewBox="0 0 20 20"
            width={size}
            height={size}
            color={color && colors[color]}
        >
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.23858 1.66666C6.79655 1.66666 6.37263 1.84225 6.06007 2.15481L2.15483 6.06006C1.84227 6.37262 1.66667 6.79654 1.66667 7.23857L1.66667 12.7614C1.66667 13.2034 1.84227 13.6274 2.15483 13.9399L6.06007 17.8452C6.37263 18.1577 6.79655 18.3333 7.23858 18.3333H12.7614C13.2035 18.3333 13.6274 18.1577 13.9399 17.8452L17.8452 13.9399C18.1577 13.6274 18.3333 13.2034 18.3333 12.7614V7.23857C18.3333 6.79654 18.1577 6.37262 17.8452 6.06006L13.9399 2.15481C13.6274 1.84225 13.2035 1.66666 12.7614 1.66666L7.23858 1.66666ZM9.90001 13.6263C9.49167 13.6263 9.16667 13.293 9.16667 12.893C9.16667 12.493 9.49167 12.168 9.89167 12.168C10.3 12.168 10.625 12.493 10.625 12.893C10.625 13.293 10.3 13.6263 9.90001 13.6263ZM9.90001 5.83332C9.50001 5.83332 9.16667 6.16749 9.16667 6.56666V10.25C9.16667 10.6508 9.50001 10.975 9.90001 10.975C10.3 10.975 10.625 10.6508 10.625 10.25V6.56666C10.625 6.16749 10.3 5.83332 9.90001 5.83332Z"
                fill="currentColor"
            />
        </SvgIcon>
    )
}
