// eslint-disable-next-line no-restricted-imports
import {
    decodeFunctionResult as viemDecodeFunctionResult,
    encodeAbiParameters as viemEncodeAbiParameters,
    encodeFunctionData as viemEncodeFunctionData,
    encodePacked as viemEncodePacked,
} from 'viem/utils'

export const encodeAbiParameters = viemEncodeAbiParameters
export const encodeFunctionData = viemEncodeFunctionData
export const decodeFunctionResult = viemDecodeFunctionResult
export const encodePacked = viemEncodePacked
