import { notReachable } from '@zeal/toolkit'
import {
    parseDecryptIncorrectPassword,
    parseEncryptedObjectInvalidFormat,
    parseInvalidEncryptedFileFormat,
} from '@zeal/toolkit/Crypto'
import { failure, oneOf, Result } from '@zeal/toolkit/Result'

import { AppError, GoogleApiError } from '@zeal/domains/Error'
import { parseBundlerResponseError } from '@zeal/domains/Error/domains/BundlerError/parsers/parseBundlerResponseError'
import { parseLedgerError } from '@zeal/domains/Error/domains/Ledger/parsers/parsers'
import { parsePasskeyError } from '@zeal/domains/Error/domains/Passkey/parsers/parsePasskeyError'
import { parseRPCError } from '@zeal/domains/Error/domains/RPCError/parsers/parseRPCError'
import { parseTrezorError } from '@zeal/domains/Error/domains/Trezor/parsers/parseTrezorError'
import { parseUnblockError } from '@zeal/domains/Error/domains/Unblock/parsers/parseUnblockError'
import { parseWagmiError } from '@zeal/domains/Error/domains/Wagmi/parsers/parseWagmiError'
import { parseWalletConnectError } from '@zeal/domains/Error/domains/WalletConnect/parsers/parseWalletConnectError'
import { parseHttpError } from '@zeal/domains/Error/parsers/parseHttpError'
import { parseIosUnableToCommunicateWithHelperApplication } from '@zeal/domains/Error/parsers/parseIosUnableToCommunicateWithHelperApplication'
import { parseRPCRequestParseError } from '@zeal/domains/Error/parsers/parseRPCRequestParseError'
import { parseFailedToFetchGoogleAuthToken } from '@zeal/domains/GoogleDriveFile'
import { parseBiometricPromptAuthFailedError } from '@zeal/domains/Password/parsers/parseBiometricPromptAuthFailedError'
import { parseBiometricPromptCancelledError } from '@zeal/domains/Password/parsers/parseBiometricPromptCancelledError'
import { parseSecureStoreKeychainDecryptionError } from '@zeal/domains/Password/parsers/parseSecureStoreKeychainDecryptionError'

import { parseConnectivityError } from './parseConnectivityError'
import {
    parseGnosisPayIsNotAvailableInThisCountry,
    parseGnosisPayNoActiveCardsFound,
} from './parseGnosisPayError'
import { parseImperativeError } from './parseImperativeError'
import { parseUnexpectedFailureError } from './parseUnexpectedFailureError'
import { parseUnknownMerchantCode } from './parseUnknownMerchantCode'

const parseGoogleAPIError = (
    error: unknown
): Result<unknown, GoogleApiError> => {
    return failure(error) // parser when needed
}

// eslint-disable-next-line no-unused-expressions
;(appError: AppError) => {
    switch (appError.type) {
        case 'biometric_prompt_auth_failed':
        case 'biometric_prompt_cancelled':
        case 'bundler_error_aa10_sender_already_constructed':
        case 'bundler_error_aa13_init_code_failed_or_out_of_gas':
        case 'bundler_error_aa21_didnt_pay_prefund':
        case 'bundler_error_aa24_signature_error':
        case 'bundler_error_aa25_invalid_account_nonce':
        case 'bundler_error_aa31_paymaster_deposit_too_low':
        case 'bundler_error_aa33_reverted_or_out_of_gas':
        case 'bundler_error_aa34_signature_error':
        case 'bundler_error_aa40_over_verification_gas_limit':
        case 'bundler_error_aa41_too_little_verification_gas':
        case 'bundler_error_aa51_prefund_below_gas_cost':
        case 'bundler_error_aa93_invalid_paymaster_and_data':
        case 'bundler_error_aa95_out_of_gas':
        case 'bundler_error_unknown':
        case 'connectivity_error':
        case 'decrypt_incorrect_password':
        case 'encrypted_object_invalid_format':
        case 'failed_to_fetch_google_auth_token':
        case 'gnosis_pay_is_not_available_in_this_country':
        case 'gnosis_pay_no_active_cards_found':
        case 'google_api_error':
        case 'hardware_wallet_failed_to_open_device':
        case 'http_error':
        case 'imperative_error':
        case 'invalid_encrypted_file_format':
        case 'ios_could_not_communicate_with_helper_application':
        case 'ledger_blind_sign_not_enabled_or_running_non_eth_app':
        case 'ledger_is_locked':
        case 'ledger_not_running_any_app':
        case 'ledger_running_non_eth_app':
        case 'passkey_android_cannot_validate_incoming_request':
        case 'passkey_android_failed_to_launch_selector':
        case 'passkey_android_fido_api_not_available':
        case 'passkey_android_no_create_options_available':
        case 'passkey_android_no_credential_available':
        case 'passkey_android_provider_configuration_error':
        case 'passkey_android_resident_key_creation_not_supported':
        case 'passkey_android_unable_to_get_sync_account':
        case 'passkey_android_unknown_error':
        case 'passkey_app_not_associated_with_domain':
        case 'passkey_google_account_missing':
        case 'passkey_operation_cancelled':
        case 'passkey_screen_lock_missing':
        case 'passkey_signer_not_found_error':
        case 'rpc_error_cannot_execute_request':
        case 'rpc_error_cannot_query_unfinalized_data':
        case 'rpc_error_execution_reverted':
        case 'rpc_error_execution_timeout':
        case 'rpc_error_gas_price_is_less_than_minimum':
        case 'rpc_error_gas_required_exceeds_allowance':
        case 'rpc_error_insufficient_balance_for_transfer':
        case 'rpc_error_insufficient_funds_for_gas_and_value':
        case 'rpc_error_invalid_argument':
        case 'rpc_error_invalid_sender':
        case 'rpc_error_max_fee_per_gas_less_than_block_base_fee':
        case 'rpc_error_nounce_is_too_low':
        case 'rpc_error_replacement_transaction_underpriced':
        case 'rpc_error_transaction_underpriced':
        case 'rpc_error_tx_pool_disabled':
        case 'rpc_error_unknown':
        case 'rpc_request_parse_error':
        case 'secure_store_keychain_decryption_error':
        case 'trezor_action_cancelled':
        case 'trezor_connection_already_initialized':
        case 'trezor_device_used_elsewhere':
        case 'trezor_method_cancelled':
        case 'trezor_permissions_not_granted':
        case 'trezor_pin_cancelled':
        case 'trezor_popup_closed':
        case 'unblock_account_number_and_sort_code_mismatch':
        case 'unblock_hard_kyc_failure':
        case 'unblock_invalid_faster_payment_configuration':
        case 'unblock_invalid_iban':
        case 'unblock_invalid_otp':
        case 'unblock_login_user_did_not_exists':
        case 'unblock_maximum_otp_attempts_exceeded':
        case 'unblock_nonce_already_in_use':
        case 'unblock_otp_expired':
        case 'unblock_session_expired':
        case 'unblock_unsupported_country':
        case 'unblock_user_associated_with_other_merchant':
        case 'unblock_user_with_address_already_exists':
        case 'unblock_user_with_such_email_already_exists':
        case 'unexpected_failure':
        case 'unknown_error':
        case 'unknown_merchant_code':
        case 'unknown_unblock_error':
        case 'user_trx_denied_by_user':
        case 'wallet_connect_proposal_expired':
        case 'wallet_connect_proposal_no_more_available':
        case 'wallet_connect_add_ethereum_chain_missing_or_invalid':
        case 'wagmi_add_ethereum_chain_not_supported':
        case 'wagmi_switch_chain_chain_id_not_supported':
            // !!! when adding new error dont forget to add parser to app error parser below
            break
        /* istanbul ignore next */
        default:
            return notReachable(appError)
    }
}

export const parseAppError = (error: unknown): AppError => {
    const parseResult = oneOf(error, [
        parseUnblockError(error),
        oneOf(error, [
            parseGoogleAPIError(error),
            parseFailedToFetchGoogleAuthToken(error),
            parseUnknownMerchantCode(error),
            parseUnblockError(error),
        ]),
        oneOf(error, [
            parseRPCError(error),
            parseBundlerResponseError(error),
            parseBiometricPromptAuthFailedError(error),
            parseSecureStoreKeychainDecryptionError(error),
            parseIosUnableToCommunicateWithHelperApplication(error),
        ]),
        oneOf(error, [
            parseUnexpectedFailureError(error),
            parseImperativeError(error),
            parseRPCRequestParseError(error),
            parseLedgerError(error),
            parseTrezorError(error),
            parseDecryptIncorrectPassword(error),
            parseEncryptedObjectInvalidFormat(error),
            parseInvalidEncryptedFileFormat(error),
            parseBiometricPromptCancelledError(error),
            parsePasskeyError(error),
        ]),
        oneOf(error, [
            parseGnosisPayNoActiveCardsFound(error),
            parseGnosisPayIsNotAvailableInThisCountry(error),
            parseWalletConnectError(error),
            parseWagmiError(error),
        ]),
        parseHttpError(error), // This is more generic error, we would like to keep it at the end
        parseConnectivityError(error),
    ])

    switch (parseResult.type) {
        case 'Failure':
            return { type: 'unknown_error', error }

        case 'Success':
            return parseResult.data

        /* istanbul ignore next */
        default:
            return notReachable(parseResult)
    }
}
