import { FormattedMessage } from 'react-intl'

import { Button } from '@zeal/uikit/Button'
import { Column } from '@zeal/uikit/Column'
import { Header } from '@zeal/uikit/Header'
import { Popup } from '@zeal/uikit/Popup'

import { Account } from '@zeal/domains/Account'
import { PredefinedNetwork } from '@zeal/domains/Network'

import { ExternalWalletAvatar } from '../../../components/ExternalWalletAvatar'

type Props = {
    connectedAccount: Account
    network: PredefinedNetwork
    onMsg: (msg: Msg) => void
}

type Msg = { type: 'close' }

export const WalletDoesNotSupportNetwork = ({
    connectedAccount,
    network,
    onMsg,
}: Props) => (
    <Popup.Layout onMsg={onMsg} background="surfaceDefault">
        <Column spacing={24}>
            <Header
                icon={({ size }) => (
                    <ExternalWalletAvatar
                        size={size}
                        fromAccount={connectedAccount}
                    />
                )}
                title={
                    <FormattedMessage
                        id="dApp.wallet-does-not-support-chain.title"
                        defaultMessage="Unsupported Network"
                    />
                }
                subtitle={
                    <FormattedMessage
                        id="dApp.wallet-does-not-support-chain.subtitle"
                        defaultMessage="Your wallet does not seem to support {network}. Try connecting with a different wallet, or use Zeal instead."
                        values={{ network: network.name }}
                    />
                }
            />
            <Popup.Actions>
                <Button
                    variant="primary"
                    onClick={() => onMsg({ type: 'close' })}
                    size="regular"
                >
                    <FormattedMessage
                        id="dApp.wallet-does-not-support-chain.button-text"
                        defaultMessage="I understand"
                    />
                </Button>
            </Popup.Actions>
        </Column>
    </Popup.Layout>
)
