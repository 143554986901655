import { useEffect } from 'react'

import { LoadingLayout } from '@zeal/uikit/LoadingLayout'

import { noop, notReachable } from '@zeal/toolkit'
import { ImperativeError } from '@zeal/toolkit/Error'
import { useLoadableData } from '@zeal/toolkit/LoadableData/LoadableData'

import { captureError } from '@zeal/domains/Error/helpers/captureError'
import { TopUpDappToExtension, ZealDAppEntryPoint } from '@zeal/domains/Main'
import { EXTENSION_KEY } from '@zeal/domains/Main/constants'
import { ZealDApp } from '@zeal/domains/Main/features/ZealDApp'
import { parseExtensionToTopUpDapp } from '@zeal/domains/Main/parsers/parseExtensionTopUpDappMsgs'

const sendMessageToExtension = (msg: TopUpDappToExtension) =>
    chrome.runtime.sendMessage(EXTENSION_KEY, msg)

const fetchEntryPoint = async (): Promise<ZealDAppEntryPoint> => {
    const response = await sendMessageToExtension({ type: 'ready' })
    return parseExtensionToTopUpDapp(response).getSuccessResultOrThrow(
        'Failed to parse extensionToTopUpDapp msg'
    ).zealDAppEntryPoint
}

export const EntryPoint = () => {
    const [loadable] = useLoadableData(fetchEntryPoint, {
        type: 'loading',
        params: undefined,
    })

    useEffect(() => {
        switch (loadable.type) {
            case 'loading':
            case 'loaded':
                break
            case 'error':
                captureError(loadable.error)
                break
            /* istanbul ignore next */
            default:
                return notReachable(loadable)
        }
    }, [loadable])

    switch (loadable.type) {
        case 'loading':
        case 'error':
            return <LoadingLayout actionBar={null} onClose={null} />
        case 'loaded':
            return (
                <ZealDApp
                    entryPoint={loadable.data}
                    onMsg={(msg) => {
                        switch (msg.type) {
                            case 'close':
                            case 'on_top_up_transaction_complete_close':
                                throw new ImperativeError(
                                    `Got ${msg.type} message from top up dapp`,
                                    { msg }
                                )
                            case 'on_external_earn_deposit_completed_close_click':
                                noop() // not relevant in dApp
                                break
                            /* istanbul ignore next */
                            default:
                                return notReachable(msg)
                        }
                    }}
                />
            )
        /* istanbul ignore next */
        default:
            return notReachable(loadable)
    }
}
