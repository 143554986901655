import React from 'react'
import { Path } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

import { Color, colors } from '../colors'

type Props = {
    size: number
    color?: Color
}

export const BoldCrossOctagon = ({ size, color }: Props) => (
    <SvgIcon
        viewBox="0 0 24 24"
        width={size}
        height={size}
        color={color && colors[color]}
    >
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.68629 2C8.15586 2 7.64715 2.21071 7.27208 2.58579L2.58579 7.27208C2.21071 7.64715 2 8.15586 2 8.68629V15.3137C2 15.8441 2.21071 16.3528 2.58579 16.7279L7.27208 21.4142C7.64715 21.7893 8.15586 22 8.68629 22H15.3137C15.8441 22 16.3528 21.7893 16.7279 21.4142L21.4142 16.7279C21.7893 16.3528 22 15.8441 22 15.3137V8.68629C22 8.15586 21.7893 7.64715 21.4142 7.27208L16.7279 2.58579C16.3528 2.21071 15.8441 2 15.3137 2H8.68629ZM8.29289 8.29289C8.68342 7.90237 9.31658 7.90237 9.70711 8.29289L12.0001 10.5858L14.293 8.29289C14.6835 7.90237 15.3167 7.90237 15.7072 8.29289C16.0978 8.68342 16.0978 9.31658 15.7072 9.70711L13.4143 12.0001L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L12.0001 13.4143L9.70723 15.7071C9.3167 16.0976 8.68354 16.0976 8.29302 15.7071C7.90249 15.3166 7.90249 14.6834 8.29302 14.2929L10.5858 12.0001L8.29289 9.70711C7.90237 9.31658 7.90237 8.68342 8.29289 8.29289Z"
            fill="currentColor"
        />
    </SvgIcon>
)
