import React from 'react'
import { Pressable, StyleProp, StyleSheet, View, ViewStyle } from 'react-native'

import { colors } from '@zeal/uikit/colors'

import { notReachable } from '@zeal/toolkit'
import { ZealPlatform } from '@zeal/toolkit/OS/ZealPlatform'

import { InternalItem, Props as InternalItemProps } from './InternalItem'

import { ImageBackground } from '../ImageBackground'

type Props = {
    background: BackgroundVariant
    onClick: () => void
    disabled: boolean
} & Omit<InternalItemProps, 'size' | 'background'>

const styles = StyleSheet.create({
    container: {
        backgroundColor: colors.surfaceDefault,
        borderRadius: 16,
        overflow: 'hidden',
        ...(() => {
            switch (ZealPlatform.OS) {
                case 'ios':
                case 'android':
                    return {}
                case 'web':
                    return { cursor: 'pointer' }
                /* istanbul ignore next */
                default:
                    return notReachable(ZealPlatform.OS)
            }
        })(),
    },
    list_item_container: {
        padding: 16,
        zIndex: 1,
    },
    hover: {
        opacity: 0.8,
    },
    pressed: {
        opacity: 0.5,
    },
    // @ts-expect-error
    disabled: {
        opacity: 0.3,
        ...(() => {
            switch (ZealPlatform.OS) {
                case 'ios':
                case 'android':
                    return {}
                case 'web':
                    return { cursor: 'not-allowed' }
                /* istanbul ignore next */
                default:
                    return notReachable(ZealPlatform.OS)
            }
        })(),
    },
})

export const ListItemButton = ({
    background,
    onClick,
    primaryTextIcon,
    shortText,
    primaryText,
    side,
    avatar,
    disabled,
}: Props) => {
    return (
        <Pressable onPress={onClick}>
            {({ pressed, hovered }) => (
                <Background
                    background={background}
                    style={[
                        styles.container,
                        disabled && styles.disabled,
                        hovered && styles.hover,
                        pressed && styles.pressed,
                    ]}
                >
                    <View style={[styles.list_item_container]}>
                        <InternalItem
                            size="large"
                            background={(() => {
                                switch (background) {
                                    case 'surface':
                                        return 'light'
                                    case 'placeholder':
                                    case 'earn':
                                    case 'card':
                                    case 'cashback':
                                        return 'dark'

                                    /* istanbul ignore next */
                                    default:
                                        return notReachable(background)
                                }
                            })()}
                            primaryText={primaryText}
                            primaryTextIcon={primaryTextIcon}
                            side={side}
                            avatar={avatar}
                            shortText={shortText}
                        />
                    </View>
                </Background>
            )}
        </Pressable>
    )
}

type BackgroundVariant =
    | 'surface'
    | 'placeholder'
    | 'earn'
    | 'card'
    | 'cashback'

type BackgroundProps = {
    style: StyleProp<ViewStyle>
    background: BackgroundVariant
    children: React.ReactNode
}

const Background = ({ style, background, children }: BackgroundProps) => {
    switch (background) {
        case 'surface':
            return <View style={style}>{children}</View>
        case 'placeholder':
            return (
                <ImageBackground
                    position="right"
                    style={style}
                    source={require('@zeal/assets/list-item-button-placeholder-bg.png')}
                >
                    {children}
                </ImageBackground>
            )
        case 'earn':
            return (
                <ImageBackground
                    position="right"
                    style={style}
                    source={require('@zeal/assets/list-item-button-earn-bg.png')}
                >
                    {children}
                </ImageBackground>
            )
        case 'card':
            return (
                <ImageBackground
                    position="right"
                    style={style}
                    source={require('@zeal/assets/list-item-button-card-bg.png')}
                >
                    {children}
                </ImageBackground>
            )
        case 'cashback':
            return (
                <ImageBackground
                    position="right"
                    style={style}
                    source={require('@zeal/assets/list-item-button-cashback-bg.png')}
                >
                    {children}
                </ImageBackground>
            )

        /* istanbul ignore next */
        default:
            return notReachable(background)
    }
}
