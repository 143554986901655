import {
    CryptoCurrency,
    CurrencyId,
    FiatCurrency,
    FiatCurrencyCode,
} from '@zeal/domains/Currency'
import { ETHEREUM, GNOSIS } from '@zeal/domains/Network/constants'

export const DEFAULT_CURRENCY_ID = 'USD' as CurrencyId

export const FIAT_CURRENCIES: Record<FiatCurrencyCode, FiatCurrency> = {
    GBP: {
        type: 'FiatCurrency',
        id: 'GBP',
        symbol: '£',
        code: 'GBP',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'British Pound',
    },
    EUR: {
        type: 'FiatCurrency',
        id: 'EUR',
        symbol: '€',
        code: 'EUR',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Euro',
    },
    NGN: {
        type: 'FiatCurrency',
        id: 'NGN',
        symbol: '₦',
        code: 'NGN',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Nigerian Naira',
    },
    PLN: {
        type: 'FiatCurrency',
        id: 'PLN',
        symbol: 'zł',
        code: 'PLN',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Polish Zloty',
    },
    USD: {
        type: 'FiatCurrency',
        id: 'USD',
        symbol: '$',
        code: 'USD',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'USD',
    },
}

export const GNOSIS_AAVE_EURE: CryptoCurrency = {
    type: 'CryptoCurrency',
    id: 'Gnosis|0xedbc7449a9b594ca4e053d9737ec5dc4cbccbfb2',
    symbol: 'aGnoEURe',
    code: 'aGnoEURe',
    fraction: 18,
    rateFraction: 18,
    icon: 'https://rdwdvjp8j5.execute-api.eu-west-1.amazonaws.com/wallet/image/currency/Gnosis|0xedbc7449a9b594ca4e053d9737ec5dc4cbccbfb2',
    name: 'Aave Gnosis EURe',
    address: '0xedbc7449a9b594ca4e053d9737ec5dc4cbccbfb2',
    marketCapRank: null,
    networkHexChainId: GNOSIS.hexChainId,
}

export const GNOSIS_EURE: CryptoCurrency = {
    type: 'CryptoCurrency',
    id: 'Gnosis|0xcb444e90d8198415266c6a2724b7900fb12fc56e',
    symbol: 'EURe',
    code: 'EURe',
    fraction: 18,
    rateFraction: 18,
    icon: 'https://rdwdvjp8j5.execute-api.eu-west-1.amazonaws.com/wallet/image/currency/Gnosis|0xcb444e90d8198415266c6a2724b7900fb12fc56e',
    name: 'Monerium EUR emoney',
    address: '0xcb444e90d8198415266c6a2724b7900fb12fc56e',
    marketCapRank: null,
    networkHexChainId: GNOSIS.hexChainId,
}

export const GNOSIS_SDAI: CryptoCurrency = {
    type: 'CryptoCurrency',
    id: 'Gnosis|0xaf204776c7245bf4147c2612bf6e5972ee483701',
    symbol: 'sDAI',
    code: 'sDAI',
    fraction: 18,
    rateFraction: 18,
    icon: 'https://rdwdvjp8j5.execute-api.eu-west-1.amazonaws.com/wallet/image/currency/Gnosis|0xaf204776c7245bf4147c2612bf6e5972ee483701',
    name: 'Savings xDAI',
    address: '0xaf204776c7245bf4147c2612bf6e5972ee483701',
    marketCapRank: null,
    networkHexChainId: GNOSIS.hexChainId,
}

export const GNOSIS_XDAI: CryptoCurrency = {
    type: 'CryptoCurrency',
    id: 'Gnosis|0x44fa8e6f47987339850636f88629646662444217',
    networkHexChainId: GNOSIS.hexChainId,
    address: '0x44fa8e6f47987339850636f88629646662444217',
    symbol: 'xDAI',
    code: 'XDAI',
    fraction: 18,
    rateFraction: 18,
    icon: 'https://rdwdvjp8j5.execute-api.eu-west-1.amazonaws.com/wallet/image/currency/Gnosis|0x44fa8e6f47987339850636f88629646662444217',
    name: 'XDAI',
    marketCapRank: null,
}
export const GNOSIS_WSTETH: CryptoCurrency = {
    type: 'CryptoCurrency',
    id: 'Gnosis|0x6c76971f98945ae98dd7d4dfca8711ebea946ea6',
    symbol: 'wstETH',
    code: 'wstETH',
    fraction: 18,
    rateFraction: 18,
    icon: 'https://rdwdvjp8j5.execute-api.eu-west-1.amazonaws.com/wallet/image/currency/Gnosis|0x6c76971f98945ae98dd7d4dfca8711ebea946ea6',
    name: 'Wrapped liquid staked Ether 2.0 from Mainnet',
    address: '0x6c76971f98945ae98dd7d4dfca8711ebea946ea6',
    marketCapRank: null,
    networkHexChainId: GNOSIS.hexChainId,
}

export const GNOSIS_USDC: CryptoCurrency = {
    type: 'CryptoCurrency',
    id: 'Gnosis|0xddafbb505ad214d7b80b1f830fccc89b60fb7a83',
    symbol: 'USDC.e',
    code: 'USDC.e',
    fraction: 6,
    rateFraction: 6,
    icon: 'https://rdwdvjp8j5.execute-api.eu-west-1.amazonaws.com/wallet/image/currency/Gnosis|0xddafbb505ad214d7b80b1f830fccc89b60fb7a83',
    name: 'USD//C on xDai',
    address: '0xddafbb505ad214d7b80b1f830fccc89b60fb7a83',
    marketCapRank: null,
    networkHexChainId: GNOSIS.hexChainId,
}

export const GNOSIS_GNO: CryptoCurrency = {
    type: 'CryptoCurrency',
    id: 'Gnosis|0x9c58bacc331c9aa871afd802db6379a98e80cedb',
    symbol: 'GNO',
    code: 'GNO',
    fraction: 18,
    rateFraction: 18,
    icon: 'https://rdwdvjp8j5.execute-api.eu-west-1.amazonaws.com/wallet/image/currency/Gnosis|0x9c58bacc331c9aa871afd802db6379a98e80cedb',
    name: 'Gnosis Token on xDai',
    address: '0x9c58bacc331c9aa871afd802db6379a98e80cedb',
    marketCapRank: 150,
    networkHexChainId: GNOSIS.hexChainId,
}

export const ETHEREUM_ETH: CryptoCurrency = {
    type: 'CryptoCurrency',
    id: 'Ethereum|0x0000000000000000000000000000000000000000',
    symbol: 'ETH',
    code: 'ETH',
    fraction: 18,
    rateFraction: 18,
    icon: 'https://rdwdvjp8j5.execute-api.eu-west-1.amazonaws.com/wallet/image/currency/Ethereum|0x0000000000000000000000000000000000000000',
    name: 'ETH',
    address: '0x0000000000000000000000000000000000000000',
    marketCapRank: 2,
    networkHexChainId: ETHEREUM.hexChainId,
}

export const ERC20_ABI = [
    {
        inputs: [
            { internalType: 'address', name: 'to', type: 'address' },
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
        ],
        name: 'transfer',
        outputs: [{ internalType: 'bool', name: 'ok', type: 'bool' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
        name: 'balanceOf',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
] as const
