import { notReachable } from '@zeal/toolkit'
import { Result } from '@zeal/toolkit/Result'

type Path = '@zeal/assets/data/currenciesMatrix.json'

const getData = async (path: Path): Promise<unknown> => {
    switch (path) {
        case '@zeal/assets/data/currenciesMatrix.json':
            return (
                await import('@zeal/assets/data/currenciesMatrix.json' as any)
            ).default

        default:
            return notReachable(path)
    }
}

export const fetchStaticData = async <T>(
    path: Path,
    parser: (input: unknown) => Result<unknown, T>
): Promise<T> => {
    const data = parser(await getData(path)).getSuccessResultOrThrow(
        `failed to parse static asset ${path}`
    )
    return data
}
