import { notReachable } from '@zeal/toolkit'
import {
    failure,
    match,
    object,
    oneOf,
    Result,
    shape,
    string,
    success,
} from '@zeal/toolkit/Result'

import {
    AA10SenderAlreadyConstructed,
    AA13InitCodeFailedOrOutOfGas,
    AA21DidntPayPrefund,
    AA24SignatureError,
    AA25InvalidAccountNonce,
    AA31PaymasterDepositTooLow,
    AA33RevertedOrOutOfGas,
    AA34SignatureError,
    AA40OverVerificationGasLimit,
    AA41TooLittleVerificationGas,
    AA51PrefundBelowGasCost,
    AA93InvalidPaymasterAndData,
    AA95OutOfGas,
    BundlerError,
    BundlerResponseError,
} from '@zeal/domains/Error/domains/BundlerError'
import { parseHttpError } from '@zeal/domains/Error/parsers/parseHttpError'
import { parse as parseNetworkHexId } from '@zeal/domains/Network/helpers/parse'

const _ = (error: BundlerError) => {
    switch (error.type) {
        case 'bundler_error_unknown':
        case 'bundler_error_aa21_didnt_pay_prefund':
        case 'bundler_error_aa33_reverted_or_out_of_gas':
        case 'bundler_error_aa24_signature_error':
        case 'bundler_error_aa10_sender_already_constructed':
        case 'bundler_error_aa13_init_code_failed_or_out_of_gas':
        case 'bundler_error_aa93_invalid_paymaster_and_data':
        case 'bundler_error_aa95_out_of_gas':
        case 'bundler_error_aa31_paymaster_deposit_too_low':
        case 'bundler_error_aa41_too_little_verification_gas':
        case 'bundler_error_aa40_over_verification_gas_limit':
        case 'bundler_error_aa51_prefund_below_gas_cost':
        case 'bundler_error_aa34_signature_error':
        case 'bundler_error_aa25_invalid_account_nonce':
            // Remember to add parser to parseBundlerResponseError
            return error
        /* istanbul ignore next */
        default:
            return notReachable(error)
    }
}

export const parseBundlerResponseError = (
    input: unknown
): Result<unknown, BundlerResponseError> =>
    parseHttpError(input).andThen((httpError) => {
        return shape({
            url: match(httpError.url, '/wallet/rpc/bundler/'),
            networkHexId: object(httpError.queryParams).andThen((params) =>
                string(params.network).andThen(parseNetworkHexId)
            ),
            error: oneOf(httpError.data, [
                parseAA21DidntPayPrefund(httpError.data),
                parseAA33RevertedOrOutOfGas(httpError.data),
                parseAA24SignatureError(httpError.data),
                parseAA10SenderAlreadyConstructed(httpError.data),
                parseAA13InitCodeFailedOrOutOfGas(httpError.data),
                parseAA93InvalidPaymasterAndData(httpError.data),
                parseAA95OutOfGas(httpError.data),
                parseAA31PaymasterDepositTooLow(httpError.data),
                parseAA41TooLittleVerificationGas(httpError.data),
                oneOf(httpError.data, [
                    parseAA40OverVerificationGasLimit(httpError.data),
                    parseAA51PrefundBelowGasCost(httpError.data),
                    parseAA34SignatureError(httpError.data),
                    parseAA25InvalidAccountNonce(httpError.data),
                    success({
                        type: 'bundler_error_unknown' as const,
                        payload: httpError.data,
                    }),
                ]),
            ]),
        }).map(
            ({ error, networkHexId }) =>
                new BundlerResponseError(error, networkHexId)
        )
    })

export const parseAA21DidntPayPrefund = (
    input: unknown
): Result<unknown, AA21DidntPayPrefund> =>
    object(input).andThen((obj) =>
        object(obj.error)
            .andThen((error) =>
                string(error.message).andThen((msg) =>
                    msg.match('AA21')
                        ? success(msg)
                        : failure({
                              type: 'message_does_not_match_regexp',
                              msg,
                          })
                )
            )
            .map(() => ({
                type: 'bundler_error_aa21_didnt_pay_prefund',
                payload: obj,
            }))
    )

export const parseAA33RevertedOrOutOfGas = (
    input: unknown
): Result<unknown, AA33RevertedOrOutOfGas> =>
    object(input).andThen((obj) =>
        object(obj.error)
            .andThen((error) =>
                string(error.message).andThen((msg) =>
                    msg.match('AA33')
                        ? success(msg)
                        : failure({
                              type: 'message_does_not_match_regexp',
                              msg,
                          })
                )
            )
            .map(() => ({
                type: 'bundler_error_aa33_reverted_or_out_of_gas',
                payload: obj,
            }))
    )

export const parseAA24SignatureError = (
    input: unknown
): Result<unknown, AA24SignatureError> =>
    object(input).andThen((obj) =>
        object(obj.error)
            .andThen((error) =>
                string(error.message).andThen((msg) =>
                    msg.match('AA24')
                        ? success(msg)
                        : failure({
                              type: 'message_does_not_match_regexp',
                              msg,
                          })
                )
            )
            .map(() => ({
                type: 'bundler_error_aa24_signature_error',
                payload: obj,
            }))
    )

export const parseAA10SenderAlreadyConstructed = (
    input: unknown
): Result<unknown, AA10SenderAlreadyConstructed> =>
    object(input).andThen((obj) =>
        object(obj.error)
            .andThen((error) =>
                string(error.message).andThen((msg) =>
                    msg.match('AA10')
                        ? success(msg)
                        : failure({
                              type: 'message_does_not_match_regexp',
                              msg,
                          })
                )
            )
            .map(() => ({
                type: 'bundler_error_aa10_sender_already_constructed',
                payload: obj,
            }))
    )

export const parseAA13InitCodeFailedOrOutOfGas = (
    input: unknown
): Result<unknown, AA13InitCodeFailedOrOutOfGas> =>
    object(input).andThen((obj) =>
        object(obj.error)
            .andThen((error) =>
                string(error.message).andThen((msg) =>
                    msg.match('AA13')
                        ? success(msg)
                        : failure({
                              type: 'message_does_not_match_regexp',
                              msg,
                          })
                )
            )
            .map(() => ({
                type: 'bundler_error_aa13_init_code_failed_or_out_of_gas',
                payload: obj,
            }))
    )

export const parseAA93InvalidPaymasterAndData = (
    input: unknown
): Result<unknown, AA93InvalidPaymasterAndData> =>
    object(input).andThen((obj) =>
        object(obj.error)
            .andThen((error) =>
                string(error.message).andThen((msg) =>
                    msg.match('AA93')
                        ? success(msg)
                        : failure({
                              type: 'message_does_not_match_regexp',
                              msg,
                          })
                )
            )
            .map(() => ({
                type: 'bundler_error_aa93_invalid_paymaster_and_data',
                payload: obj,
            }))
    )

export const parseAA95OutOfGas = (
    input: unknown
): Result<unknown, AA95OutOfGas> =>
    object(input).andThen((obj) =>
        object(obj.error)
            .andThen((error) =>
                string(error.message).andThen((msg) =>
                    msg.match('AA95')
                        ? success(msg)
                        : failure({
                              type: 'message_does_not_match_regexp',
                              msg,
                          })
                )
            )
            .map(() => ({
                type: 'bundler_error_aa95_out_of_gas',
                payload: obj,
            }))
    )

export const parseAA31PaymasterDepositTooLow = (
    input: unknown
): Result<unknown, AA31PaymasterDepositTooLow> =>
    object(input).andThen((obj) =>
        object(obj.error)
            .andThen((error) =>
                string(error.message).andThen((msg) =>
                    msg.match('AA31')
                        ? success(msg)
                        : failure({
                              type: 'message_does_not_match_regexp',
                              msg,
                          })
                )
            )
            .map(() => ({
                type: 'bundler_error_aa31_paymaster_deposit_too_low',
                payload: obj,
            }))
    )

export const parseAA41TooLittleVerificationGas = (
    input: unknown
): Result<unknown, AA41TooLittleVerificationGas> =>
    object(input).andThen((obj) =>
        object(obj.error)
            .andThen((error) =>
                string(error.message).andThen((msg) =>
                    msg.match('AA41')
                        ? success(msg)
                        : failure({
                              type: 'message_does_not_match_regexp',
                              msg,
                          })
                )
            )
            .map(() => ({
                type: 'bundler_error_aa41_too_little_verification_gas',
                payload: obj,
            }))
    )

export const parseAA40OverVerificationGasLimit = (
    input: unknown
): Result<unknown, AA40OverVerificationGasLimit> =>
    object(input).andThen((obj) =>
        object(obj.error)
            .andThen((error) =>
                string(error.message).andThen((msg) =>
                    msg.match('AA40')
                        ? success(msg)
                        : failure({
                              type: 'message_does_not_match_regexp',
                              msg,
                          })
                )
            )
            .map(() => ({
                type: 'bundler_error_aa40_over_verification_gas_limit',
                payload: obj,
            }))
    )

export const parseAA51PrefundBelowGasCost = (
    input: unknown
): Result<unknown, AA51PrefundBelowGasCost> =>
    object(input).andThen((obj) =>
        object(obj.error)
            .andThen((error) =>
                string(error.message).andThen((msg) =>
                    msg.match('AA51')
                        ? success(msg)
                        : failure({
                              type: 'message_does_not_match_regexp',
                              msg,
                          })
                )
            )
            .map(() => ({
                type: 'bundler_error_aa51_prefund_below_gas_cost',
                payload: obj,
            }))
    )

export const parseAA34SignatureError = (
    input: unknown
): Result<unknown, AA34SignatureError> =>
    object(input).andThen((obj) =>
        object(obj.error)
            .andThen((error) =>
                string(error.message).andThen((msg) =>
                    msg.match('AA34')
                        ? success(msg)
                        : failure({
                              type: 'message_does_not_match_regexp',
                              msg,
                          })
                )
            )
            .map(() => ({
                type: 'bundler_error_aa34_signature_error',
                payload: obj,
            }))
    )

export const parseAA25InvalidAccountNonce = (
    input: unknown
): Result<unknown, AA25InvalidAccountNonce> =>
    object(input).andThen((obj) =>
        object(obj.error)
            .andThen((error) =>
                string(error.message).andThen((msg) =>
                    msg.match('AA25')
                        ? success(msg)
                        : failure({
                              type: 'message_does_not_match_regexp',
                              msg,
                          })
                )
            )
            .map(() => ({
                type: 'bundler_error_aa25_invalid_account_nonce',
                payload: obj,
            }))
    )
