import { ExternalLink } from '@zeal/uikit/Icon/ExternalLink'
import { IconButton } from '@zeal/uikit/IconButton'
import { Row } from '@zeal/uikit/Row'
import { Text } from '@zeal/uikit/Text'

import { notReachable } from '@zeal/toolkit'
import { openExternalURL } from '@zeal/toolkit/Window'

import { Network } from '@zeal/domains/Network'

import { format } from '../helpers/format'
import { getExplorerLink } from '../helpers/getExplorerLink'
import { TransactionHash } from '../TransactionHash'

type Props = {
    transactionHash: TransactionHash
    network: Network
    variant: 'with_address' | 'no_address'
}

export const HashLink = ({ transactionHash, network, variant }: Props) => {
    const link = getExplorerLink(transactionHash, network)

    switch (variant) {
        case 'with_address':
            return (
                <Row spacing={4} alignY="center">
                    {!link ? (
                        <Text
                            variant="paragraph"
                            weight="regular"
                            color="textPrimary"
                        >
                            {format(transactionHash)}
                        </Text>
                    ) : (
                        <IconButton
                            size="small"
                            variant="on_light"
                            onClick={() => openExternalURL(link)}
                        >
                            {() => (
                                <Row spacing={4} alignY="center">
                                    <Text
                                        variant="paragraph"
                                        weight="regular"
                                        color="textPrimary"
                                    >
                                        {format(transactionHash)}
                                    </Text>

                                    <ExternalLink
                                        size={14}
                                        color="textPrimary"
                                    />
                                </Row>
                            )}
                        </IconButton>
                    )}
                </Row>
            )

        case 'no_address':
            return !link ? null : (
                <IconButton
                    size="small"
                    variant="on_light"
                    onClick={() => openExternalURL(link)}
                >
                    {() => <ExternalLink size={14} color="textPrimary" />}
                </IconButton>
            )

        default:
            return notReachable(variant)
    }
}
