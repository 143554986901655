import { notReachable } from '@zeal/toolkit'

import { Network } from '@zeal/domains/Network'
import { HashLink as TransactionHashLink } from '@zeal/domains/Transactions/domains/TransactionHash/components/HashLink'

import { SubmitedTransaction } from '../SubmitedTransaction'
import { SubmittedSafeTransaction } from '../SubmittedSafeTransaction'

type Props = {
    variant: 'with_address' | 'no_address'
    submitedTransaction: SubmitedTransaction | SubmittedSafeTransaction
    network: Network
}

export const HashLink = ({ submitedTransaction, network, variant }: Props) => {
    switch (submitedTransaction.state) {
        case 'queued':
        case 'included_in_block':
        case 'completed':
        case 'failed':
        case 'replaced':
            return (
                <TransactionHashLink
                    transactionHash={{
                        transactionHash: submitedTransaction.hash,
                    }}
                    network={network}
                    variant={variant}
                />
            )

        default:
            return notReachable(submitedTransaction)
    }
}
