import { useIntl } from 'react-intl'

import { Big } from 'big.js'

import { notReachable } from '@zeal/toolkit'

import {
    CryptoCurrency,
    FiatCurrency,
    KnownCurrencies,
} from '@zeal/domains/Currency'
import { useCurrenciesByRate } from '@zeal/domains/Currency/hooks/useCurrenciesByRate'
import { FXRate, FXRate2 } from '@zeal/domains/FXRate'

const MIN_AMOUNT = new Big('0.1')
const MIN_SIGNIFICANT_NUMBER = 2
const NON_BREAKING_SPACE = '\u00A0'

type Props = {
    rate: FXRate
    knownCurriencies: KnownCurrencies
}

/**
 * @deprecated use FormattedCryptoToFiatRate instead.
 */
export const FormattedRate = ({ knownCurriencies, rate }: Props) => {
    const { formatNumber } = useIntl()
    const { quote: currency } = useCurrenciesByRate(rate, knownCurriencies)

    const amount = new Big(rate.rate.toString()).div(
        Math.pow(10, currency.rateFraction)
    )

    const amountToFormat = amount.gte(MIN_AMOUNT)
        ? amount.toFixed(MIN_SIGNIFICANT_NUMBER, 0)
        : amount.toPrecision(MIN_SIGNIFICANT_NUMBER, 0)

    return (
        <>
            {formatNumber(parseFloat(amountToFormat), {
                style: 'currency',
                currency: currency.code,
                minimumFractionDigits: 0,
                minimumSignificantDigits: 1,
                maximumFractionDigits: currency.rateFraction,
            })}
        </>
    )
}

export const FormattedCryptoToFiatRate = <
    B extends CryptoCurrency,
    Q extends FiatCurrency
>({
    rate,
    variant,
}: {
    variant: 'just_quote' | 'base_and_quote'
    rate: FXRate2<B, Q>
}) => {
    const { formatNumber } = useIntl()
    const { quote, base } = rate

    const amount = new Big(rate.rate.toString()).div(
        Math.pow(10, quote.rateFraction)
    )

    const options: Parameters<typeof formatNumber>[1] = {
        style: 'currency',
        currency: quote.code,
    }

    if (amount.lt(0.001)) {
        options.maximumFractionDigits = 8
        options.maximumSignificantDigits = 1
    } else if (amount.lt(1)) {
        options.minimumSignificantDigits = 1
        options.maximumSignificantDigits = 2
    } else if (amount.lt(10)) {
        options.minimumFractionDigits = 2
        options.maximumFractionDigits = 2
    } else if (amount.lt(100)) {
        options.minimumFractionDigits = 1
        options.maximumFractionDigits = 1
    } else {
        options.minimumFractionDigits = 0
        options.maximumFractionDigits = 0
    }

    const formattedQuote = formatNumber(amount.toNumber(), options)

    switch (variant) {
        case 'just_quote':
            return <>{formattedQuote}</>
        case 'base_and_quote': {
            return (
                <>{`1${NON_BREAKING_SPACE}${base.symbol}${NON_BREAKING_SPACE}=${NON_BREAKING_SPACE}${formattedQuote}`}</>
            )
        }
        default:
            return notReachable(variant)
    }
}
