import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

import '@walletconnect/react-native-compat'

import { ActionBar as UIActionBar } from '@zeal/uikit/ActionBar'
import { Actions } from '@zeal/uikit/Actions'
import { Button } from '@zeal/uikit/Button'
import { Column } from '@zeal/uikit/Column'
import { Group } from '@zeal/uikit/Group'
import { OutlineWallet } from '@zeal/uikit/Icon/OutlineWallet'
import { AmountInput } from '@zeal/uikit/Input/AmountInput'
import { ListItem } from '@zeal/uikit/ListItem'
import { NextStepSeparator } from '@zeal/uikit/NextStepSeparator'
import { Row } from '@zeal/uikit/Row'
import { Screen } from '@zeal/uikit/Screen'
import { Skeleton as UISkeleton } from '@zeal/uikit/Skeleton'
import { Text } from '@zeal/uikit/Text'

import { notReachable } from '@zeal/toolkit'
import { useLoadableData } from '@zeal/toolkit/LoadableData/LoadableData'
import { MsgOf } from '@zeal/toolkit/MsgOf'

import { Account } from '@zeal/domains/Account'
import { AvatarWithoutBadge } from '@zeal/domains/Account/components/Avatar'
import { format as formatAddress } from '@zeal/domains/Address/helpers/format'
import { AppErrorPopup } from '@zeal/domains/Error/components/AppErrorPopup'
import { parseAppError } from '@zeal/domains/Error/parsers/parseAppError'
import { KeyStore } from '@zeal/domains/KeyStore'
import { NetworkRPCMap, PredefinedNetwork } from '@zeal/domains/Network'
import { Portfolio } from '@zeal/domains/Portfolio'
import { postUserEvent } from '@zeal/domains/UserEvents/api/postUserEvent'

import { fetchSupportedTopUpCurrencies } from './api/fetchSupportedTopUpCurrencies'
import { Header } from './components/Header'
import { Flow } from './Flow'

import { Connected, SendTransactionToExternalWallet } from '../types'

type Props = {
    sendTransaction: SendTransactionToExternalWallet
    account: Account
    connectionState: Connected
    supportedNetworks: PredefinedNetwork[]
    keyStoreType: KeyStore['type']
    networkRPCMap: NetworkRPCMap
    portfolio: Portfolio
    installationId: string
    onMsg: (msg: Msg) => void
}

type Msg = MsgOf<typeof Flow>

export const DataLoader = ({
    account,
    sendTransaction,
    installationId,
    supportedNetworks,
    onMsg,
    portfolio,
    networkRPCMap,
    keyStoreType,
    connectionState,
}: Props) => {
    const [loadable, setLoadable] = useLoadableData(
        fetchSupportedTopUpCurrencies,
        {
            type: 'loading',
            params: { supportedNetworks, keyStoreType },
        }
    )

    useEffect(() => {
        postUserEvent({
            type: 'TopUpDappOpenedEvent',
            installationId,
        })
    }, [installationId])

    switch (loadable.type) {
        case 'loading':
            return <Skeleton account={account} onMsg={onMsg} />

        case 'loaded':
            return (
                <Flow
                    supportedNetworks={supportedNetworks}
                    sendTransaction={sendTransaction}
                    connectionState={connectionState}
                    networkRPCMap={networkRPCMap}
                    topUpCurrencies={loadable.data}
                    account={account}
                    portfolio={portfolio}
                    installationId={installationId}
                    onMsg={onMsg}
                />
            )
        case 'error':
            return (
                <>
                    <Skeleton account={account} onMsg={onMsg} />
                    <AppErrorPopup
                        installationId={installationId}
                        error={parseAppError(loadable.error)}
                        onMsg={(msg) => {
                            switch (msg.type) {
                                case 'close':
                                case 'try_again_clicked':
                                    setLoadable({
                                        type: 'loading',
                                        params: loadable.params,
                                    })
                                    break

                                /* istanbul ignore next */
                                default:
                                    notReachable(msg)
                            }
                        }}
                    />
                </>
            )

        /* istanbul ignore next */
        default:
            return notReachable(loadable)
    }
}

const Skeleton = ({
    account,
    onMsg,
}: {
    account: Props['account']
    onMsg: Props['onMsg']
}) => (
    <Screen
        padding="form"
        background="light"
        onNavigateBack={() => onMsg({ type: 'close' })}
    >
        <UIActionBar
            top={
                <Row spacing={8}>
                    <OutlineWallet size={24} color="textSecondary" />
                    <Text
                        variant="paragraph"
                        weight="medium"
                        color="textSecondary"
                    >
                        <FormattedMessage
                            id="topUpDapp.connectWallet"
                            defaultMessage="Connect wallet"
                        />
                    </Text>
                </Row>
            }
            left={<Header onMsg={onMsg} />}
        />

        <Column spacing={16} shrink alignY="stretch">
            <Column spacing={4}>
                <AmountInput
                    state="normal"
                    content={{
                        topLeft: (
                            <Row spacing={8}>
                                <UISkeleton
                                    variant="default"
                                    width={32}
                                    height={32}
                                />
                                <UISkeleton
                                    variant="default"
                                    width={75}
                                    height={20}
                                />
                            </Row>
                        ),
                        topRight: () => (
                            <UISkeleton
                                variant="default"
                                width={55}
                                height={20}
                            />
                        ),
                        bottomRight: (
                            <UISkeleton
                                variant="default"
                                width={35}
                                height={10}
                            />
                        ),
                    }}
                />

                <NextStepSeparator />

                <Group variant="default">
                    <ListItem
                        size="large"
                        aria-current={false}
                        avatar={({ size }) => (
                            <AvatarWithoutBadge size={size} account={account} />
                        )}
                        primaryText={account.label}
                        shortText={formatAddress(account.address)}
                    />
                </Group>
            </Column>

            <Actions>
                <Button size="regular" variant="primary" disabled>
                    <FormattedMessage
                        id="topUpDapp.connectWallet"
                        defaultMessage="Connect wallet"
                    />
                </Button>
            </Actions>
        </Column>
    </Screen>
)
