import { failure, Result, success } from '@zeal/toolkit/Result'

import { UnknownMerchantCode } from '@zeal/domains/Error'

export const parseUnknownMerchantCode = (
    input: unknown
): Result<unknown, UnknownMerchantCode> =>
    input instanceof UnknownMerchantCode &&
    input.type === 'unknown_merchant_code'
        ? success(input)
        : failure('not_correct_instance')
