import Web3 from 'web3'

import { notReachable } from '@zeal/toolkit'
import * as Hexadecimal from '@zeal/toolkit/Hexadecimal'
import { generateRandomNumber } from '@zeal/toolkit/Number'
import { bigint, string } from '@zeal/toolkit/Result'
import * as Web3Toolkit from '@zeal/toolkit/Web3'

import { Address } from '@zeal/domains/Address'
import { CryptoCurrency } from '@zeal/domains/Currency'
import { ERC20_ABI } from '@zeal/domains/Currency/constants'
import { CryptoMoney } from '@zeal/domains/Money'
import { Network, NetworkMap, NetworkRPCMap } from '@zeal/domains/Network'
import { findNetworkByHexChainId } from '@zeal/domains/Network/constants'
import { fetchRPCResponse } from '@zeal/domains/RPCRequest/api/fetchRPCResponse'
import { ZealWeb3RPCProvider } from '@zeal/domains/RPCRequest/helpers/ZealWeb3RPCProvider'

type Params = {
    contract: Address
    account: Address
    network: Network
    networkRPCMap: NetworkRPCMap
    signal?: AbortSignal
}

/**
 * @deprecated kill this and use ERC20_ABI from constants
 */
const ABI = [
    {
        constant: true,
        inputs: [
            {
                name: '_owner' as const,
                type: 'address' as const,
            },
        ],
        name: 'balanceOf',
        outputs: [
            {
                name: 'balance' as const,
                type: 'uint256' as const,
            },
        ],
        payable: false,
        stateMutability: 'view' as const,
        type: 'function' as const,
    },
] as const

/**
 * @deprecated use fetchBalanceOf2 if you can
 */
export const fetchBalanceOf = async ({
    account,
    contract,
    network,
    networkRPCMap,
    signal,
}: Params): Promise<bigint> => {
    const web3 = new Web3(new ZealWeb3RPCProvider({ network, networkRPCMap }))
    const contractEncoded = new web3.eth.Contract(ABI, contract)
    const data: string = contractEncoded.methods.balanceOf(account).encodeABI()

    const balanceStr = await fetchRPCResponse({
        signal,
        network: network,
        networkRPCMap,
        request: {
            id: generateRandomNumber(),
            jsonrpc: '2.0' as const,
            method: 'eth_call' as const,
            params: [
                {
                    to: contract,
                    data,
                },
                'latest',
            ],
        },
    })

    const parsed = string(balanceStr)
        .map((str) => web3.eth.abi.decodeParameter('int256', str))
        .andThen(bigint)
        .getSuccessResultOrThrow('Failed to parse owner balance')

    return parsed
}

export const fetchBalanceOf2 = async ({
    currency,
    address,
    block = 'latest',
    networkRPCMap,
    networkMap,
    signal,
}: {
    currency: CryptoCurrency
    address: Address
    networkRPCMap: NetworkRPCMap
    networkMap: NetworkMap
    block?: bigint | 'latest'
    signal?: AbortSignal
}): Promise<CryptoMoney> => {
    const network = findNetworkByHexChainId(
        currency.networkHexChainId,
        networkMap
    )

    const balanceResponse = await fetchRPCResponse({
        signal,
        network: network,
        networkRPCMap,
        request: {
            id: generateRandomNumber(),
            jsonrpc: '2.0' as const,
            method: 'eth_call' as const,
            params: [
                {
                    to: currency.address,
                    data: Web3Toolkit.abi.encodeFunctionData({
                        abi: ERC20_ABI,
                        functionName: 'balanceOf',
                        args: [address as `0x${string}`],
                    }),
                },
                (() => {
                    switch (true) {
                        case block === 'latest':
                            return 'latest'

                        case typeof block === 'bigint':
                            return Hexadecimal.unpad(
                                Hexadecimal.fromBigInt(block)
                            )

                        default:
                            return notReachable(block)
                    }
                })(),
            ],
        },
    })

    const amount = Web3Toolkit.abi.decodeFunctionResult({
        abi: ERC20_ABI,
        data: balanceResponse as `0x${string}`,
        functionName: 'balanceOf',
    })

    return {
        amount,
        currency,
    }
}
