import { Avatar, AvatarSize } from '@zeal/uikit/Avatar'
import { EarnEth } from '@zeal/uikit/Icon/EarnEth'
import { EarnEURO } from '@zeal/uikit/Icon/EarnEURO'
import { EarnUSD } from '@zeal/uikit/Icon/EarnUSD'

import { notReachable } from '@zeal/toolkit'

import { TakerConfig } from '@zeal/domains/Earn'

type Props = {
    taker: TakerConfig
    size: AvatarSize
}

export const TakerAvatar = ({ taker, size }: Props) => {
    switch (taker.type) {
        case 'usd':
            return (
                <Avatar size={size}>
                    <EarnUSD size={size} />
                </Avatar>
            )
        case 'eur':
            return (
                <Avatar size={size}>
                    <EarnEURO size={size} />
                </Avatar>
            )
        case 'eth':
            return (
                <Avatar size={size}>
                    <EarnEth size={size} />
                </Avatar>
            )

        /* istanbul ignore next */
        default:
            return notReachable(taker.type)
    }
}
