import { NetworkHexId } from '@zeal/domains/Network'

export type BundlerError =
    | UnknownBundlerError
    | AA21DidntPayPrefund
    | AA33RevertedOrOutOfGas
    | AA24SignatureError
    | AA10SenderAlreadyConstructed
    | AA13InitCodeFailedOrOutOfGas
    | AA93InvalidPaymasterAndData
    | AA95OutOfGas
    | AA31PaymasterDepositTooLow
    | AA41TooLittleVerificationGas
    | AA40OverVerificationGasLimit
    | AA51PrefundBelowGasCost
    | AA34SignatureError
    | AA25InvalidAccountNonce

export type AA21DidntPayPrefund = {
    type: 'bundler_error_aa21_didnt_pay_prefund'
    payload: object
}

export type AA33RevertedOrOutOfGas = {
    type: 'bundler_error_aa33_reverted_or_out_of_gas'
    payload: object
}

export type AA24SignatureError = {
    type: 'bundler_error_aa24_signature_error'
    payload: object
}

export type AA10SenderAlreadyConstructed = {
    type: 'bundler_error_aa10_sender_already_constructed'
    payload: object
}

export type AA13InitCodeFailedOrOutOfGas = {
    type: 'bundler_error_aa13_init_code_failed_or_out_of_gas'
    payload: object
}

export type AA93InvalidPaymasterAndData = {
    type: 'bundler_error_aa93_invalid_paymaster_and_data'
    payload: object
}

export type AA95OutOfGas = {
    type: 'bundler_error_aa95_out_of_gas'
    payload: object
}

export type AA31PaymasterDepositTooLow = {
    type: 'bundler_error_aa31_paymaster_deposit_too_low'
    payload: object
}

export type AA41TooLittleVerificationGas = {
    type: 'bundler_error_aa41_too_little_verification_gas'
    payload: object
}

export type AA40OverVerificationGasLimit = {
    type: 'bundler_error_aa40_over_verification_gas_limit'
    payload: object
}

export type AA51PrefundBelowGasCost = {
    type: 'bundler_error_aa51_prefund_below_gas_cost'
    payload: object
}

export type AA34SignatureError = {
    type: 'bundler_error_aa34_signature_error'
    payload: object
}

export type AA25InvalidAccountNonce = {
    type: 'bundler_error_aa25_invalid_account_nonce'
    payload: object
}

export type UnknownBundlerError = {
    type: 'bundler_error_unknown'
    payload: unknown
}

export class BundlerResponseError<
    T extends BundlerError = BundlerError
> extends Error {
    name = 'BundlerResponseError' as const
    networkHexId: NetworkHexId
    type: T['type']
    payload: T['payload']

    constructor(error: T, networkHexId: NetworkHexId) {
        super()
        this.payload = error.payload
        this.type = error.type
        this.networkHexId = networkHexId
    }
}
