import { useState } from 'react'

import { notReachable } from '@zeal/toolkit'
import { MsgOf } from '@zeal/toolkit/MsgOf'

import { EarnDepositRequest } from '@zeal/domains/Earn'
import { NetworkRPCMap } from '@zeal/domains/Network'
import { EthSendTransaction } from '@zeal/domains/RPCRequest'
import { SubmitedTransaction } from '@zeal/domains/TransactionRequest/domains/SubmitedTransaction'

import { MonitorApproval } from './MonitorApproval'
import { MonitorDepositTransaction } from './MonitorDepositTransaction'
import { SubmitApproval } from './SubmitApproval'
import { SubmitDepositTransaction } from './SubmitDepositTransaction'

import { SendTransactionToExternalWallet } from '../../../../../types'

type Props = {
    earnDepositRequest: EarnDepositRequest
    networkRPCMap: NetworkRPCMap

    sendTransaction: SendTransactionToExternalWallet
    installationId: string

    onMsg: (msg: Msg) => void
}

type State =
    | {
          type: 'submit_approval'
          earnDepositRequest: EarnDepositRequest
          approvalRequest: EthSendTransaction
      }
    | { type: 'monitor_approval'; submitedApproval: SubmitedTransaction }
    | { type: 'submit_deposit' }
    | { type: 'monitor_deposit'; submittedDeposit: SubmitedTransaction }

type Msg =
    | Extract<
          MsgOf<typeof SubmitApproval>,
          { type: 'close' | 'on_transaction_rejected' }
      >
    | Extract<
          MsgOf<typeof MonitorApproval>,
          { type: 'close' | 'on_failed_transaction_close_click' }
      >
    | Extract<MsgOf<typeof SubmitDepositTransaction>, { type: 'close' }>
    | MsgOf<typeof MonitorDepositTransaction>

const calculateInitialState = ({
    earnDepositRequest,
}: {
    earnDepositRequest: EarnDepositRequest
}): State => {
    return earnDepositRequest.swapRoute.approvalTransaction
        ? {
              type: 'submit_approval',
              approvalRequest: earnDepositRequest.swapRoute.approvalTransaction,
              earnDepositRequest,
          }
        : { type: 'submit_deposit' }
}

export const SubmitDeposit = ({
    earnDepositRequest,
    networkRPCMap,
    sendTransaction,
    installationId,
    onMsg,
}: Props) => {
    const [state, setState] = useState<State>(
        calculateInitialState({ earnDepositRequest })
    )

    switch (state.type) {
        case 'submit_approval':
            return (
                <SubmitApproval
                    sendTransaction={sendTransaction}
                    earnDepositRequest={earnDepositRequest}
                    approvalRequest={state.approvalRequest}
                    onMsg={(msg) => {
                        switch (msg.type) {
                            case 'close':
                            case 'on_transaction_rejected':
                                onMsg(msg)
                                break
                            case 'on_approval_submitted':
                                setState({
                                    type: 'monitor_approval',
                                    submitedApproval: msg.submitedApproval,
                                })
                                break

                            /* istanbul ignore next */
                            default:
                                notReachable(msg)
                        }
                    }}
                />
            )

        case 'monitor_approval':
            return (
                <MonitorApproval
                    earnDepositRequest={earnDepositRequest}
                    networkRPCMap={networkRPCMap}
                    submitedApproval={state.submitedApproval}
                    onMsg={(msg) => {
                        switch (msg.type) {
                            case 'close':
                            case 'on_failed_transaction_close_click':
                                onMsg(msg)
                                break
                            case 'on_approval_completed':
                                setState({ type: 'submit_deposit' })
                                break

                            /* istanbul ignore next */
                            default:
                                notReachable(msg)
                        }
                    }}
                />
            )
        case 'submit_deposit':
            return (
                <SubmitDepositTransaction
                    earnDepositRequest={earnDepositRequest}
                    sendTransaction={sendTransaction}
                    onMsg={(msg) => {
                        switch (msg.type) {
                            case 'close':
                            case 'on_transaction_rejected':
                                onMsg(msg)
                                break
                            case 'on_earn_deposit_submitted':
                                setState({
                                    type: 'monitor_deposit',
                                    submittedDeposit: msg.submitedDeposit,
                                })
                                break

                            /* istanbul ignore next */
                            default:
                                notReachable(msg)
                        }
                    }}
                />
            )

        case 'monitor_deposit':
            return (
                <MonitorDepositTransaction
                    installationId={installationId}
                    earnDepositRequest={earnDepositRequest}
                    networkRPCMap={networkRPCMap}
                    submitedDeposit={state.submittedDeposit}
                    onMsg={onMsg}
                />
            )

        default:
            return notReachable(state)
    }
}
