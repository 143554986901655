import { notReachable } from '@zeal/toolkit'
import { generateRandomNumber } from '@zeal/toolkit/Number'
import * as Web3 from '@zeal/toolkit/Web3'

import { CryptoCurrency, Currency, FiatCurrency } from '@zeal/domains/Currency'
import {
    ETHEREUM_ETH,
    FIAT_CURRENCIES,
    GNOSIS_SDAI,
    GNOSIS_WSTETH,
} from '@zeal/domains/Currency/constants'
import { FXRate2 } from '@zeal/domains/FXRate'
import { NetworkRPCMap } from '@zeal/domains/Network'
import { ETHEREUM } from '@zeal/domains/Network/constants'
import { RPCRequest } from '@zeal/domains/RPCRequest'
import { fetchRPCResponse } from '@zeal/domains/RPCRequest/api/fetchRPCResponse'

import { TakerConfig } from '..'
import {
    EARN_NETWORK,
    ETHEREUM_WSTETH_ABI,
    ETHEREUM_WSTETH_ADDRESS,
    GNOSIS_SDAI_ABI,
} from '../constants'

export const fetchTakerUserCurrencyRate = async (
    taker: TakerConfig,
    networkRPCMap: NetworkRPCMap,
    signal?: AbortSignal
): Promise<FXRate2<CryptoCurrency, Currency>> => {
    switch (taker.type) {
        case 'usd':
            return fetchUsdTakerUserCurrencyRate(networkRPCMap, signal)

        case 'eur':
            return {
                base: taker.cryptoCurrency,
                quote: FIAT_CURRENCIES.EUR,
                rate: 10n ** BigInt(FIAT_CURRENCIES.EUR.rateFraction),
            }

        case 'eth':
            return fetchEthTakerUserCurrencyRate(networkRPCMap, signal)
        default:
            return notReachable(taker.type)
    }
}

const fetchUsdTakerUserCurrencyRate = async (
    networkRPCMap: NetworkRPCMap,
    signal?: AbortSignal
): Promise<FXRate2<CryptoCurrency, FiatCurrency>> => {
    const sDAIconvertToAssetsRequest: RPCRequest = {
        id: generateRandomNumber(),
        jsonrpc: '2.0',
        method: 'eth_call',
        params: [
            {
                data: Web3.abi.encodeFunctionData({
                    abi: GNOSIS_SDAI_ABI,
                    functionName: 'convertToAssets',
                    args: [10n ** BigInt(FIAT_CURRENCIES.USD.rateFraction)],
                }),
                to: GNOSIS_SDAI.address,
            },
        ],
    }

    const sDAIconvertToAssetsResponse = await fetchRPCResponse({
        request: sDAIconvertToAssetsRequest,
        signal,
        network: EARN_NETWORK,
        networkRPCMap,
    })

    const sDAIconvertToAssetRate = Web3.abi.decodeFunctionResult({
        abi: GNOSIS_SDAI_ABI,
        data: sDAIconvertToAssetsResponse as `0x${string}`,
        functionName: 'convertToAssets',
    })

    return {
        base: GNOSIS_SDAI,
        quote: FIAT_CURRENCIES.USD,
        rate: sDAIconvertToAssetRate,
    }
}

const fetchEthTakerUserCurrencyRate = async (
    networkRPCMap: NetworkRPCMap,
    signal?: AbortSignal
): Promise<FXRate2<CryptoCurrency, CryptoCurrency>> => {
    const wstEthStEthPerTokenRequest: RPCRequest = {
        id: generateRandomNumber(),
        jsonrpc: '2.0',
        method: 'eth_call',
        params: [
            {
                data: Web3.abi.encodeFunctionData({
                    abi: ETHEREUM_WSTETH_ABI,
                    functionName: 'stEthPerToken',
                }),
                to: ETHEREUM_WSTETH_ADDRESS,
            },
        ],
    }

    const wstEthStEthPerTokenResponse = await fetchRPCResponse({
        request: wstEthStEthPerTokenRequest,
        signal,
        network: ETHEREUM,
        networkRPCMap,
    })

    const wstEthStEthPerToken = Web3.abi.decodeFunctionResult({
        abi: ETHEREUM_WSTETH_ABI,
        data: wstEthStEthPerTokenResponse as `0x${string}`,
        functionName: 'stEthPerToken',
    })

    return {
        base: GNOSIS_WSTETH,
        quote: ETHEREUM_ETH,
        rate: wstEthStEthPerToken,
    }
}
