import { FormattedMessage } from 'react-intl'

import { ActionBar as UIActionBar } from '@zeal/uikit/ActionBar'
import { Clickable } from '@zeal/uikit/Clickable'
import { Column } from '@zeal/uikit/Column'
import { BackIcon } from '@zeal/uikit/Icon/BackIcon'
import { DisconnectWallet } from '@zeal/uikit/Icon/DisconnectWallet'
import { IconButton } from '@zeal/uikit/IconButton'
import { Row } from '@zeal/uikit/Row'
import { Screen } from '@zeal/uikit/Screen'
import { Text } from '@zeal/uikit/Text'

import { format } from '@zeal/domains/Address/helpers/format'
import { CryptoCurrency } from '@zeal/domains/Currency'
import { PredefinedNetwork } from '@zeal/domains/Network'
import { Portfolio } from '@zeal/domains/Portfolio'
import { SelectToken } from '@zeal/domains/Token/components/SelectToken'

import { ExternalWalletAvatar } from '../../../../components/ExternalWalletAvatar'
import { getNetworkMapFromSupportedNetworks } from '../../../../getNetworkMapFromSupportedNetworks'
import { Connected } from '../../../../types'

type Props = {
    fromCurrencies: CryptoCurrency[]
    selectedCurrency: CryptoCurrency | null
    portfolio: Portfolio
    supportedNetworks: PredefinedNetwork[]
    connectionState: Connected

    onMsg: (msg: Msg) => void
}

type Msg =
    | { type: 'close' }
    | { type: 'on_from_currency_selected'; fromCurrency: CryptoCurrency }
    | { type: 'on_disconnect_clicked' }

export const SelectFromToken = ({
    onMsg,
    portfolio,
    selectedCurrency,
    fromCurrencies,
    connectionState,
    supportedNetworks,
}: Props) => {
    return (
        <Screen
            background="light"
            padding="form"
            aria-labelledby="select-currency-label"
            onNavigateBack={() => onMsg({ type: 'close' })}
        >
            <UIActionBar
                top={
                    <Row spacing={8}>
                        <ExternalWalletAvatar
                            fromAccount={connectionState.account}
                            size={24}
                        />

                        <Text
                            variant="paragraph"
                            weight="medium"
                            color="textSecondary"
                        >
                            {format(connectionState.account.address)}
                        </Text>
                        <IconButton
                            variant="on_light"
                            onClick={() =>
                                onMsg({ type: 'on_disconnect_clicked' })
                            }
                        >
                            {({ color }) => (
                                <DisconnectWallet size={18} color={color} />
                            )}
                        </IconButton>
                    </Row>
                }
                left={
                    <Clickable onClick={() => onMsg({ type: 'close' })}>
                        <Row spacing={4}>
                            <BackIcon size={24} color="iconDefault" />
                            <Text
                                variant="title3"
                                weight="semi_bold"
                                color="textPrimary"
                            >
                                <FormattedMessage
                                    id="earn.deposit.select-currency.title"
                                    defaultMessage="Select token to deposit"
                                />
                            </Text>
                        </Row>
                    </Clickable>
                }
            />

            <Column fill shrink spacing={16}>
                <SelectToken
                    cryptoCurrencies={fromCurrencies}
                    currencyHiddenMap={{}}
                    currencyPinMap={{}}
                    networkMap={getNetworkMapFromSupportedNetworks(
                        supportedNetworks
                    )}
                    portfolio={portfolio}
                    selectedCurrency={selectedCurrency}
                    onCryptoCurrencySelected={(fromCurrency) =>
                        onMsg({
                            type: 'on_from_currency_selected',
                            fromCurrency,
                        })
                    }
                />
            </Column>
        </Screen>
    )
}
