import React from 'react'
import { FormattedMessage } from 'react-intl'

import { notReachable } from '@zeal/toolkit'

import { AppError } from '@zeal/domains/Error'

export const Title = ({ error }: { error: AppError }) => {
    switch (error.type) {
        case 'unblock_account_number_and_sort_code_mismatch':
            return (
                <FormattedMessage
                    id="error.unblock_account_number_and_sort_code_mismatch"
                    defaultMessage="Account number and sort code mismatch"
                />
            )

        case 'unblock_invalid_iban':
            return (
                <FormattedMessage
                    id="error.unblock_invalid_iban"
                    defaultMessage="Invalid IBAN"
                />
            )

        case 'unblock_hard_kyc_failure':
            return (
                <FormattedMessage
                    id="error.unblock_hard_kyc_failure"
                    defaultMessage="Unexpected KYC state"
                />
            )

        case 'unblock_session_expired':
            return (
                <FormattedMessage
                    id="error.unblock_session_expired.title"
                    defaultMessage="Unblock session expired"
                />
            )

        case 'unblock_user_with_such_email_already_exists':
            return (
                <FormattedMessage
                    id="error.unblock_user_with_such_email_already_exists.title"
                    defaultMessage="User with such email already exists"
                />
            )

        case 'unblock_user_with_address_already_exists':
            return (
                <FormattedMessage
                    id="error.unblock_user_with_address_already_exists.title"
                    defaultMessage="Account already setup for address"
                />
            )
        case 'decrypt_incorrect_password':
            return (
                <FormattedMessage
                    id="error.decrypt_incorrect_password.title"
                    defaultMessage="Incorrect password"
                />
            )
        case 'encrypted_object_invalid_format':
        case 'invalid_encrypted_file_format':
            return (
                <FormattedMessage
                    id="error.encrypted_object_invalid_format.title"
                    defaultMessage="Corrupted data"
                />
            )

        case 'bundler_error_aa10_sender_already_constructed':
        case 'bundler_error_aa13_init_code_failed_or_out_of_gas':
        case 'bundler_error_aa21_didnt_pay_prefund':
        case 'bundler_error_aa24_signature_error':
        case 'bundler_error_aa25_invalid_account_nonce':
        case 'bundler_error_aa31_paymaster_deposit_too_low':
        case 'bundler_error_aa33_reverted_or_out_of_gas':
        case 'bundler_error_aa34_signature_error':
        case 'bundler_error_aa40_over_verification_gas_limit':
        case 'bundler_error_aa41_too_little_verification_gas':
        case 'bundler_error_aa51_prefund_below_gas_cost':
        case 'bundler_error_aa93_invalid_paymaster_and_data':
        case 'bundler_error_aa95_out_of_gas':
        case 'bundler_error_unknown':
        case 'rpc_error_cannot_execute_request':
        case 'rpc_error_cannot_query_unfinalized_data':
        case 'rpc_error_execution_reverted':
        case 'rpc_error_execution_timeout':
        case 'rpc_error_gas_price_is_less_than_minimum':
        case 'rpc_error_gas_required_exceeds_allowance':
        case 'rpc_error_insufficient_balance_for_transfer':
        case 'rpc_error_insufficient_funds_for_gas_and_value':
        case 'rpc_error_invalid_argument':
        case 'rpc_error_invalid_sender':
        case 'rpc_error_max_fee_per_gas_less_than_block_base_fee':
        case 'rpc_error_nounce_is_too_low':
        case 'rpc_error_replacement_transaction_underpriced':
        case 'rpc_error_transaction_underpriced':
        case 'rpc_error_tx_pool_disabled':
        case 'rpc_error_unknown':
            return (
                <FormattedMessage
                    id="error.trezor_action_cancelled.title"
                    defaultMessage="Transaction rejected"
                />
            )

        case 'trezor_device_used_elsewhere':
            return (
                <FormattedMessage
                    id="error.trezor_device_used_elsewhere.title"
                    defaultMessage="Device is being used in another session"
                />
            )

        case 'trezor_method_cancelled':
            return (
                <FormattedMessage
                    id="error.trezor_method_cancelled.title"
                    defaultMessage="Couldn’t sync Trezor"
                />
            )

        case 'trezor_permissions_not_granted':
            return (
                <FormattedMessage
                    id="error.trezor_permissions_not_granted.title"
                    defaultMessage="Couldn’t sync Trezor"
                />
            )

        case 'trezor_pin_cancelled':
            return (
                <FormattedMessage
                    id="error.trezor_pin_cancelled.title"
                    defaultMessage="Couldn’t sync Trezor"
                />
            )

        case 'trezor_popup_closed':
            return (
                <FormattedMessage
                    id="error.trezor_popup_closed.title"
                    defaultMessage="Couldn’t sync Trezor"
                />
            )

        case 'failed_to_fetch_google_auth_token':
            return (
                <FormattedMessage
                    id="error.failed_to_fetch_google_auth_token.title"
                    defaultMessage="We couldn’t get access"
                />
            )
        case 'unblock_invalid_faster_payment_configuration':
            return (
                <FormattedMessage
                    id="error.unblock_invalid_faster_payment_configuration.title"
                    defaultMessage="" // FIXME :: @hannes
                />
            )

        case 'biometric_prompt_auth_failed':
        case 'biometric_prompt_cancelled':
        case 'gnosis_pay_is_not_available_in_this_country':
        case 'gnosis_pay_no_active_cards_found':
        case 'google_api_error':
        case 'hardware_wallet_failed_to_open_device':
        case 'http_error':
        case 'imperative_error':
        case 'ios_could_not_communicate_with_helper_application':
        case 'ledger_blind_sign_not_enabled_or_running_non_eth_app':
        case 'ledger_is_locked':
        case 'ledger_not_running_any_app':
        case 'ledger_running_non_eth_app':
        case 'passkey_android_cannot_validate_incoming_request':
        case 'passkey_android_failed_to_launch_selector':
        case 'passkey_android_fido_api_not_available':
        case 'passkey_android_no_create_options_available':
        case 'passkey_android_no_credential_available':
        case 'passkey_android_provider_configuration_error':
        case 'passkey_android_resident_key_creation_not_supported':
        case 'passkey_android_unable_to_get_sync_account':
        case 'passkey_android_unknown_error':
        case 'passkey_app_not_associated_with_domain':
        case 'passkey_google_account_missing':
        case 'passkey_operation_cancelled':
        case 'passkey_screen_lock_missing':
        case 'passkey_signer_not_found_error':
        case 'rpc_request_parse_error':
        case 'secure_store_keychain_decryption_error':
        case 'trezor_action_cancelled':
        case 'trezor_connection_already_initialized':
        case 'unblock_invalid_otp':
        case 'unblock_login_user_did_not_exists':
        case 'unblock_maximum_otp_attempts_exceeded':
        case 'unblock_nonce_already_in_use':
        case 'unblock_otp_expired':
        case 'unblock_unsupported_country':
        case 'unblock_user_associated_with_other_merchant':
        case 'unexpected_failure':
        case 'unknown_error':
        case 'unknown_merchant_code':
        case 'unknown_unblock_error':
        case 'user_trx_denied_by_user':
            return (
                <FormattedMessage
                    id="error.unknown_error.title"
                    defaultMessage="We’re having issues"
                />
            )

        case 'connectivity_error':
            return (
                <FormattedMessage
                    id="error.connectivity_error.title"
                    defaultMessage="No internet connection"
                />
            )

        case 'wallet_connect_proposal_no_more_available':
        case 'wallet_connect_proposal_expired':
            return (
                <FormattedMessage
                    id="wallet_connect_proposal_expired.title"
                    defaultMessage="Connection expired"
                />
            )
        case 'wallet_connect_add_ethereum_chain_missing_or_invalid':
        case 'wagmi_add_ethereum_chain_not_supported':
        case 'wagmi_switch_chain_chain_id_not_supported':
            return (
                <FormattedMessage
                    id="wallet_connect_add_chain_missing.title"
                    defaultMessage="Network not supported"
                />
            )

        /* istanbul ignore next */
        default:
            return notReachable(error)
    }
}
