import { failure, oneOf, Result, success } from '@zeal/toolkit/Result'

import {
    AddEthereumChainMissingOrInvalid,
    ProposalExpired,
    ProposalNoMoreAvailable,
    WalletConnectError,
} from '..'

const _dontForgetToAddParser: Record<WalletConnectError['type'], true> = {
    wallet_connect_proposal_expired: true,
    wallet_connect_proposal_no_more_available: true,
    wallet_connect_add_ethereum_chain_missing_or_invalid: true,
}

const parseError = (input: unknown): Result<unknown, Error> =>
    input instanceof Error
        ? success(input)
        : failure({ type: 'not_matching_instance' })

const parseProposalExpired = (
    input: unknown
): Result<unknown, ProposalExpired> =>
    parseError(input).andThen((error) =>
        error.message.match(/Record was recently deleted - proposal/gim)
            ? success({ type: 'wallet_connect_proposal_expired' })
            : failure({ type: 'not_matching_instance_or_message' })
    )

const parseProposalNoMoreAvailable = (
    input: unknown
): Result<unknown, ProposalNoMoreAvailable> =>
    parseError(input).andThen((error) =>
        error.message.match(
            /Missing or invalid. Record was recently deleted - proposal/gim
        )
            ? success({ type: 'wallet_connect_proposal_no_more_available' })
            : failure({ type: 'not_matching_instance_or_message' })
    )

const parseAddEthereumChainMissingOrInvalid = (
    input: unknown
): Result<unknown, AddEthereumChainMissingOrInvalid> =>
    parseError(input).andThen((error) =>
        error.message.match('Missing or invalid') &&
        error.message.match('wallet_addEthereumChain')
            ? success({
                  type: 'wallet_connect_add_ethereum_chain_missing_or_invalid',
              })
            : failure({ type: 'not_matching_instance_or_message' })
    )

export const parseWalletConnectError = (
    error: unknown
): Result<unknown, WalletConnectError> =>
    oneOf(error, [
        parseProposalExpired(error),
        parseProposalNoMoreAvailable(error),
        parseAddEthereumChainMissingOrInvalid(error),
    ])
