import { useState } from 'react'

import { notReachable } from '@zeal/toolkit'
import { MsgOf } from '@zeal/toolkit/MsgOf'

import { CryptoCurrency, Currency } from '@zeal/domains/Currency'
import { Taker } from '@zeal/domains/Earn'
import { FXRate2 } from '@zeal/domains/FXRate'
import { NetworkRPCMap, PredefinedNetwork } from '@zeal/domains/Network'
import { Portfolio } from '@zeal/domains/Portfolio'

import { Form } from './Form'
import { SelectFromToken } from './SelectFromToken'

import { Connected, SendTransactionToExternalWallet } from '../../types'

type Props = {
    fromCurrencies: CryptoCurrency[]
    portfolio: Portfolio
    installationId: string
    takerPortfolio: Portfolio
    connectionState: Connected
    taker: Taker
    takerUserCurrencyRate: FXRate2<CryptoCurrency, Currency>
    sendTransaction: SendTransactionToExternalWallet
    networkRPCMap: NetworkRPCMap
    supportedNetworks: PredefinedNetwork[]

    onMsg: (msg: Msg) => void
}

type Msg =
    | Extract<MsgOf<typeof SelectFromToken>, { type: 'close' }>
    | Extract<
          MsgOf<typeof Form>,
          {
              type:
                  | 'on_external_earn_deposit_completed_close_click'
                  | 'on_connect_to_correct_network_clicked'
                  | 'on_disconnect_clicked'
          }
      >

type State =
    | { type: 'select_from_currency' }
    | { type: 'form'; fromCurrency: CryptoCurrency }

export const Flow = ({
    portfolio,
    installationId,
    fromCurrencies,
    connectionState,
    takerPortfolio,
    takerUserCurrencyRate,
    taker,
    networkRPCMap,
    supportedNetworks,
    sendTransaction,
    onMsg,
}: Props) => {
    const [state, setState] = useState<State>({ type: 'select_from_currency' })

    switch (state.type) {
        case 'select_from_currency':
            return (
                <SelectFromToken
                    connectionState={connectionState}
                    portfolio={portfolio}
                    selectedCurrency={null}
                    supportedNetworks={supportedNetworks}
                    fromCurrencies={fromCurrencies}
                    onMsg={(msg) => {
                        switch (msg.type) {
                            case 'close':
                            case 'on_disconnect_clicked':
                                onMsg(msg)
                                break

                            case 'on_from_currency_selected':
                                setState({
                                    type: 'form',
                                    fromCurrency: msg.fromCurrency,
                                })
                                break

                            /* istanbul ignore next */
                            default:
                                notReachable(msg)
                        }
                    }}
                />
            )

        case 'form':
            return (
                <Form
                    takerPortfolio={takerPortfolio}
                    installationId={installationId}
                    taker={taker}
                    sendTransaction={sendTransaction}
                    supportedNetworks={supportedNetworks}
                    networkRPCMap={networkRPCMap}
                    takerUserCurrencyRate={takerUserCurrencyRate}
                    fromCurrencies={fromCurrencies}
                    connectionState={connectionState}
                    portfolio={portfolio}
                    fromCurrency={state.fromCurrency}
                    onMsg={(msg) => {
                        switch (msg.type) {
                            case 'close':
                                setState({ type: 'select_from_currency' })
                                break
                            case 'on_external_earn_deposit_completed_close_click':
                            case 'on_connect_to_correct_network_clicked':
                            case 'on_disconnect_clicked':
                                onMsg(msg)
                                break
                            /* istanbul ignore next */
                            default:
                                return notReachable(msg)
                        }
                    }}
                />
            )

        default:
            return notReachable(state)
    }
}
