import React from 'react'
import { useIntl } from 'react-intl'

import Big from 'big.js'

import { notReachable } from '@zeal/toolkit'

import { KnownCurrencies } from '@zeal/domains/Currency'
import { useCurrencyById } from '@zeal/domains/Currency/hooks/useCurrencyById'
import { FiatMoney, Money } from '@zeal/domains/Money'

type Props = {
    money: Money
    minimumFractionDigits: number
    knownCurrencies: KnownCurrencies
}

const MAX_FRACTION_DIGITS = 2

/**
 * @deprecated Use FormattedFiatCurrency2 instead
 */
export const FormattedFiatCurrency = ({
    knownCurrencies,
    money,
    minimumFractionDigits,
}: Props) => {
    const { formatNumber } = useIntl()
    const currency = useCurrencyById(money.currencyId, knownCurrencies)

    if (!currency) {
        return null
    }

    const power = BigInt(10) ** BigInt(currency.fraction)

    const amount = new Big(money.amount.toString())
        .div(new Big(power.toString()))
        .toNumber()

    return (
        <>
            {formatNumber(amount, {
                style: 'currency',
                currency: currency.code,
                maximumFractionDigits: MAX_FRACTION_DIGITS,
                minimumFractionDigits,
            })}
        </>
    )
}

export const FormattedFiatMoney = ({
    money,
    minimumFractionDigits,
    sign,
}: {
    money: FiatMoney
    sign?: '+' | '-'
    minimumFractionDigits: number
}) => {
    const { formatNumber } = useIntl()
    const currency = money.currency

    const power = BigInt(10) ** BigInt(currency.fraction)

    const signCorrection = sign
        ? (() => {
              switch (sign) {
                  case '+':
                      return 1
                  case '-':
                      return -1
                  default:
                      return notReachable(sign)
              }
          })()
        : 1

    const amount =
        new Big(money.amount.toString())
            .div(new Big(power.toString()))
            .toNumber() * signCorrection

    return (
        <>
            {formatNumber(amount, {
                style: 'currency',
                currency: currency.code,
                maximumFractionDigits: MAX_FRACTION_DIGITS,
                minimumFractionDigits,
                signDisplay: sign ? 'always' : 'never',
            })}
        </>
    )
}
