import { NetworkMap, PredefinedNetwork } from '@zeal/domains/Network'

export const getNetworkMapFromSupportedNetworks = (
    supportedNetworks: PredefinedNetwork[]
): NetworkMap =>
    supportedNetworks.reduce((result, item) => {
        return {
            ...result,
            [item.hexChainId]: item,
        }
    }, {} as NetworkMap)
