import { GetAccountReturnType } from '@wagmi/core'
import { useWalletInfo } from '@web3modal/wagmi-react-native'

import {
    failure,
    nullableOf,
    object,
    oneOf,
    Result,
    shape,
    string,
    success,
} from '@zeal/toolkit/Result'

import { Account } from '@zeal/domains/Account'
import { parse as parseAddress } from '@zeal/domains/Address/helpers/parse'

const parseWalletConnectAccount = (
    walletConnectWalletInfo: ReturnType<typeof useWalletInfo>['walletInfo'],
    wagmiExternalAccount: Extract<GetAccountReturnType, { status: 'connected' }>
): Result<unknown, Account> =>
    object(walletConnectWalletInfo).andThen((info) =>
        shape({
            address: parseAddress(wagmiExternalAccount.address),
            label: string(info.name),
            avatarSrc: nullableOf(info.icon, string),
        })
    )

const parseWagmiAccount = (
    wagmiExternalAccount: Extract<GetAccountReturnType, { status: 'connected' }>
): Result<unknown, Account> =>
    shape({
        address: parseAddress(wagmiExternalAccount.address),
        label: string(wagmiExternalAccount.connector.name).andThen((name) =>
            name === 'injected'
                ? failure({
                      type: 'injected_connector_does_not_have_name',
                  })
                : success(name)
        ),
        avatarSrc: nullableOf(wagmiExternalAccount.connector.icon, string),
    })

export const parseConnectedAccount = (input: {
    walletConnectWalletInfo?: ReturnType<typeof useWalletInfo>['walletInfo']
    wagmiExternalAccount: Extract<GetAccountReturnType, { status: 'connected' }>
}): Result<unknown, Account> =>
    oneOf(input, [
        parseWalletConnectAccount(
            input.walletConnectWalletInfo,
            input.wagmiExternalAccount
        ),
        parseWagmiAccount(input.wagmiExternalAccount),
        success({
            address: input.wagmiExternalAccount.address,
            label: 'wallet',
            avatarSrc: null,
        }),
    ])
