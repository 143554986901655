import React from 'react'
import { Circle, Path } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

import { Color, colors } from '../../colors'

type Props = {
    size: number
    color?: Color
}

export const Tokens = ({ size, color }: Props) => {
    return (
        <SvgIcon
            viewBox="0 0 24 24"
            width={size}
            height={size}
            color={color && colors[color]}
        >
            <Circle cx="12" cy="12" r="12" fill="#CBD2D9" />
            <Path
                d="M16.4392 13.74C16.2449 13.9282 16.1557 14.2005 16.1999 14.4675L16.8667 18.1575C16.9229 18.4702 16.7909 18.7868 16.5292 18.9675C16.2727 19.155 15.9314 19.1775 15.6517 19.0275L12.3299 17.295C12.2144 17.2335 12.0862 17.2005 11.9549 17.1968H11.7517C11.6812 17.2073 11.6122 17.2297 11.5492 17.2642L8.22666 19.005C8.06241 19.0875 7.87641 19.1168 7.69416 19.0875C7.25016 19.0035 6.95391 18.5805 7.02666 18.1343L7.69416 14.4443C7.73841 14.175 7.64916 13.9012 7.45491 13.71L4.74666 11.085C4.52016 10.8652 4.44141 10.5353 4.54491 10.2375C4.64541 9.9405 4.90191 9.72375 5.21166 9.675L8.93916 9.13425C9.22266 9.105 9.47166 8.9325 9.59916 8.6775L11.2417 5.31C11.2807 5.235 11.3309 5.166 11.3917 5.1075L11.4592 5.055C11.4944 5.016 11.5349 4.98375 11.5799 4.9575L11.6617 4.9275L11.7892 4.875H12.1049C12.3869 4.90425 12.6352 5.073 12.7649 5.325L14.4292 8.6775C14.5492 8.92275 14.7824 9.093 15.0517 9.13425L18.7792 9.675C19.0942 9.72 19.3574 9.9375 19.4617 10.2375C19.5599 10.5382 19.4752 10.8682 19.2442 11.085L16.4392 13.74Z"
                fill="currentColor"
            />
        </SvgIcon>
    )
}
