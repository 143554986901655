import React from 'react'
import { Path } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

type Props = {
    size: number
}

export const BoldStarFavourite = ({ size }: Props) => (
    <SvgIcon viewBox="0 0 16 16" width={size} height={size}>
        <Path
            d="M12.8179 13.3851L12.8179 13.3848L12.226 10.1093C12.2145 10.0347 12.2396 9.95953 12.2923 9.90754L14.7813 7.55161C14.7816 7.55129 14.782 7.55097 14.7823 7.55064C15.1281 7.22547 15.2554 6.73013 15.1078 6.27839L15.1079 6.27837L15.1048 6.26954C14.9509 5.8267 14.5625 5.50547 14.0971 5.43877C14.0969 5.43874 14.0967 5.43871 14.0965 5.43868L10.7868 4.95854C10.7088 4.94612 10.6422 4.89663 10.6083 4.82724L10.6083 4.82724L10.607 4.82467L9.12769 1.84467L9.12774 1.84464L9.12437 1.83811C8.93132 1.46316 8.56234 1.21297 8.14475 1.16966L8.11903 1.16699H8.09317H7.8125H7.71359L7.62213 1.20465L7.51778 1.24762L7.45425 1.27093L7.41275 1.28616L7.37457 1.30844C7.30691 1.3479 7.24269 1.39619 7.18416 1.45412L7.1522 1.47898L7.13136 1.49519L7.11235 1.5135C7.01623 1.60606 6.93989 1.7121 6.88223 1.82298L6.88208 1.82291L6.87644 1.83447L5.41781 4.825C5.38053 4.89818 5.30877 4.94728 5.22785 4.95563L5.21759 4.95669L5.20739 4.95817L1.89405 5.43884L1.89405 5.4388L1.8881 5.43974C1.42915 5.51197 1.04945 5.83277 0.90021 6.27145C0.745174 6.71989 0.864448 7.21609 1.20434 7.54585L1.20451 7.54602L3.61005 9.87761C3.66551 9.93285 3.69141 10.0113 3.67958 10.089L3.08715 13.364L3.08708 13.364L3.08568 13.3725C2.97741 14.0367 3.41824 14.6665 4.07956 14.7916L4.07952 14.7918L4.09327 14.794C4.35968 14.8368 4.63118 14.7939 4.87026 14.6738L4.87029 14.6739L4.87788 14.6699L7.83121 13.1226L7.83125 13.1226L7.83667 13.1197H7.95092C7.99098 13.1216 8.02724 13.1319 8.05817 13.1483L8.05816 13.1483L8.06195 13.1503L11.0124 14.6892C11.4267 14.9099 11.9325 14.878 12.3155 14.6012C12.7054 14.3288 12.9023 13.8544 12.8179 13.3851Z"
            fill="#F19D39"
            stroke="white"
        />
    </SvgIcon>
)
