import { secp256k1 } from '@noble/curves/secp256k1'
import memoize from 'lodash.memoize'
// eslint-disable-next-line no-restricted-imports
import {
    checksumAddress,
    getContractAddress as viewGetContractAddress,
} from 'viem'
import { HDKey } from 'viem/accounts' // eslint-disable-line no-restricted-imports
import { publicKeyToAddress } from 'viem/utils' // eslint-disable-line no-restricted-imports

import { ImperativeError } from '@zeal/toolkit/Error'
import * as Hexadecimal from '@zeal/toolkit/Hexadecimal'

import { failure, Result, success } from '../Result'

declare const AddressSymbol: unique symbol
declare const ChecksumAddressSymbol: unique symbol

export type Address = Hexadecimal.Hexadecimal & {
    _opaque: typeof AddressSymbol
}

export type ChecksumAddress = `0x${string}` & {
    _opaque: typeof ChecksumAddressSymbol
}

const REG_EXP = /^0x[a-fA-F0-9]{40}$/
export const ADDRESS_STRING_LEGTH_WITHOUT_PREFIX = 40

export type NotAValidAddress = { type: 'not_a_valid_address'; value: unknown }

export const fromString = memoize(
    (address: string): Result<NotAValidAddress, Address> => {
        if (address.match(REG_EXP)) {
            return success(address.toLowerCase() as Address) // we should not checksum address in parsers as it is expensive
        } else {
            return failure({ type: 'not_a_valid_address', value: address })
        }
    }
)

export const staticFromString = (address: string): Address =>
    fromString(address).getSuccessResultOrThrow(
        'Failed to parse static address'
    )

export const fromBigint = (
    bigint: bigint
): Result<NotAValidAddress, Address> => {
    const address = `0x${bigint
        .toString(16)
        .padStart(ADDRESS_STRING_LEGTH_WITHOUT_PREFIX, '0')}`

    return fromString(address)
}

export const fromExtendedPublicKey = (key: string, index: number): Address => {
    const publicKey = HDKey.fromExtendedKey(key).derive(`m/${index}`).publicKey

    if (!publicKey) {
        throw new ImperativeError(
            'Public key not found when deriving address from extended public key'
        )
    }

    const publicKeyHex = Hexadecimal.fromBuffer(publicKey)
    const point = secp256k1.ProjectivePoint.fromHex(
        Hexadecimal.remove0x(publicKeyHex)
    ) // May not be needed if https://github.com/wevm/viem/discussions/2044 is ever implemented
    const uncompressedPublicKeyHex = Hexadecimal.fromBuffer(
        point.toRawBytes(false) // false means we want the uncompressed public key
    )

    return publicKeyToAddress(uncompressedPublicKeyHex) as Address
}

export const toChecksumAddress = (
    address: Hexadecimal.Hexadecimal
): ChecksumAddress => checksumAddress(address) as ChecksumAddress

export const getPredictedSmartContractAddress = viewGetContractAddress
