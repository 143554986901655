import { ActionBar } from '@zeal/uikit/ActionBar'
import { BackIcon } from '@zeal/uikit/Icon/BackIcon'
import { IconButton } from '@zeal/uikit/IconButton'
import { LoadingLayout as UILoadingLayout } from '@zeal/uikit/LoadingLayout'

import { notReachable } from '@zeal/toolkit'
import { useLoadableData } from '@zeal/toolkit/LoadableData/LoadableData'
import { MsgOf } from '@zeal/toolkit/MsgOf'
import { ZealPlatform } from '@zeal/toolkit/OS/ZealPlatform'

import { CryptoCurrency, Currency } from '@zeal/domains/Currency'
import { fetchCurrenciesMatrix } from '@zeal/domains/Currency/api/fetchCurrenciesMatrix'
import { Taker } from '@zeal/domains/Earn'
import { fetchTakerUserCurrencyRate } from '@zeal/domains/Earn/api/fetchTakerUserCurrencyRate'
import { EARN_NETWORK } from '@zeal/domains/Earn/constants'
import { AppErrorPopup } from '@zeal/domains/Error/components/AppErrorPopup'
import { parseAppError } from '@zeal/domains/Error/parsers/parseAppError'
import { FXRate2 } from '@zeal/domains/FXRate'
import { NetworkRPCMap, PredefinedNetwork } from '@zeal/domains/Network'
import { Portfolio } from '@zeal/domains/Portfolio'
import { fetchServerPortfolio } from '@zeal/domains/Portfolio/api/fetchPortfolio'

import { Flow } from './Flow'

import { Connected, SendTransactionToExternalWallet } from '../types'

type Props = {
    taker: Taker
    portfolio: Portfolio
    connectionState: Connected
    sendTransaction: SendTransactionToExternalWallet
    networkRPCMap: NetworkRPCMap
    supportedNetworks: PredefinedNetwork[]
    installationId: string
    onMsg: (msg: Msg) => void
}

type Msg = MsgOf<typeof Flow>

type Data = {
    fromCurrencies: CryptoCurrency[]
    takerPortfolio: Portfolio
    takerUserCurrencyRate: FXRate2<CryptoCurrency, Currency>
}

const fetch = async ({
    taker,
}: {
    taker: Taker
    signal?: AbortSignal
}): Promise<Data> => {
    const [takerPortfolio, takerUserCurrencyRate, matrix] = await Promise.all([
        fetchServerPortfolio({
            address: taker.address,
            forceRefresh: false,
        }),
        fetchTakerUserCurrencyRate(taker, {}),
        fetchCurrenciesMatrix(),
    ])

    const currencyIds =
        matrix.currencies[EARN_NETWORK.hexChainId][EARN_NETWORK.hexChainId].from

    return {
        fromCurrencies: currencyIds
            .map((id): CryptoCurrency | null => {
                const currency = matrix.knownCurrencies[id] || null

                if (!currency) {
                    return null
                }

                switch (currency.type) {
                    case 'FiatCurrency':
                        return null
                    case 'CryptoCurrency':
                        return currency

                    default:
                        return notReachable(currency)
                }
            })
            .filter((currency) => currency !== null),
        takerPortfolio,
        takerUserCurrencyRate,
    }
}

export const EarnDeposit = ({
    taker,
    connectionState,
    onMsg,
    installationId,
    supportedNetworks,
    networkRPCMap,
    sendTransaction,
    portfolio,
}: Props) => {
    const [loadable, setLoadable] = useLoadableData(fetch, {
        type: 'loading',
        params: { taker },
    })

    switch (loadable.type) {
        case 'loading':
            return <LoadingLayout onMsg={onMsg} />
        case 'error':
            return (
                <>
                    <LoadingLayout onMsg={onMsg} />
                    <AppErrorPopup
                        error={parseAppError(loadable.error)}
                        installationId={installationId}
                        onMsg={(msg) => {
                            switch (msg.type) {
                                case 'close':
                                    onMsg(msg)
                                    break
                                case 'try_again_clicked':
                                    setLoadable({
                                        type: 'loading',
                                        params: loadable.params,
                                    })
                                    break
                                /* istanbul ignore next */
                                default:
                                    return notReachable(msg)
                            }
                        }}
                    />
                </>
            )
        case 'loaded':
            return (
                <Flow
                    fromCurrencies={loadable.data.fromCurrencies}
                    takerUserCurrencyRate={loadable.data.takerUserCurrencyRate}
                    takerPortfolio={loadable.data.takerPortfolio}
                    portfolio={portfolio}
                    installationId={installationId}
                    connectionState={connectionState}
                    taker={taker}
                    networkRPCMap={networkRPCMap}
                    sendTransaction={sendTransaction}
                    supportedNetworks={supportedNetworks}
                    onMsg={onMsg}
                />
            )
        /* istanbul ignore next */
        default:
            return notReachable(loadable)
    }
}

const LoadingLayout = ({ onMsg }: { onMsg: Props['onMsg'] }) => (
    <UILoadingLayout
        actionBar={
            <ActionBar
                left={(() => {
                    switch (ZealPlatform.OS) {
                        case 'ios':
                        case 'android':
                            return (
                                <IconButton
                                    variant="on_light"
                                    onClick={() => onMsg({ type: 'close' })}
                                >
                                    {({ color }) => (
                                        <BackIcon size={24} color={color} />
                                    )}
                                </IconButton>
                            )
                        case 'web':
                            return null
                        /* istanbul ignore next */
                        default:
                            return notReachable(ZealPlatform.OS)
                    }
                })()}
            />
        }
        onClose={() => onMsg({ type: 'close' })}
    />
)
