import React from 'react'
import { Circle, Defs, LinearGradient, Path, Stop } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

type Props = {
    size: number
}

export const EarnUSD = ({ size }: Props) => {
    return (
        <SvgIcon width={size} height={size} viewBox="0 0 120 120" fill="none">
            <Circle
                cx="60"
                cy="60"
                r="60"
                fill="url(#paint0_linear_1564_570)"
            />
            <Path
                d="M60.7558 91.7376C55.3348 91.7376 50.5764 90.7438 46.4806 88.7561C42.3847 86.7684 38.8912 83.8471 36 79.9922L42.9569 70.8669C46.0288 74.8422 49.0103 77.613 51.9015 79.179C54.7927 80.7451 58.0453 81.5281 61.6593 81.5281C63.7072 81.5281 65.5745 81.2269 67.261 80.6246C68.9475 79.9621 70.2726 79.0887 71.2364 78.0045C72.2001 76.8601 72.682 75.565 72.682 74.1194C72.682 73.0955 72.4711 72.1619 72.0495 71.3186C71.6881 70.4151 71.1159 69.6321 70.3329 68.9695C69.5498 68.2467 68.5861 67.5841 67.4417 66.9818C66.2972 66.3795 65.0022 65.8675 63.5566 65.4459C62.111 65.0242 60.5149 64.6327 58.7681 64.2713C54.3109 63.3678 50.6065 62.0728 47.6551 60.3863C44.7639 58.6395 42.5955 56.4711 41.1499 53.8811C39.7043 51.2911 38.9815 48.2493 38.9815 44.7558C38.9815 42.1055 39.5538 39.6661 40.6982 37.4375C41.9028 35.2089 43.5291 33.2814 45.5771 31.6551C47.625 30.0288 50.0042 28.7639 52.7147 27.8604C55.4252 26.9569 58.3465 26.5052 61.4786 26.5052C66.4177 26.5052 70.7846 27.4087 74.5793 29.2157C78.374 30.9624 81.5061 33.5826 83.9757 37.0761L77.2898 45.2076C75.2419 42.4368 72.8627 40.3588 70.1522 38.9734C67.5019 37.5278 64.6107 36.805 61.4786 36.805C59.3102 36.805 57.4129 37.1062 55.7866 37.7085C54.2205 38.3109 52.9857 39.1842 52.0822 40.3287C51.1787 41.4129 50.727 42.738 50.727 44.3041C50.727 45.5087 50.998 46.5929 51.5401 47.5566C52.0822 48.4601 52.8352 49.2733 53.7989 49.9961C54.8228 50.7189 56.1179 51.3513 57.6839 51.8934C59.25 52.4355 61.0269 52.9174 63.0145 53.339C66.3274 54.0016 69.3089 54.9051 71.9592 56.0495C74.6094 57.1337 76.8682 58.4588 78.7354 60.0249C80.6026 61.5307 82.0181 63.2775 82.9818 65.2652C83.9455 67.1926 84.4274 69.361 84.4274 71.7704C84.4274 75.9264 83.4336 79.5103 81.4459 82.522C79.5184 85.4734 76.7778 87.7622 73.2241 89.3885C69.6703 90.9546 65.5142 91.7376 60.7558 91.7376ZM55.3348 99.0559L55.6962 57.6758L63.918 60.6573L64.2794 99.0559H55.3348ZM67.0803 60.6573L58.8585 57.6758L58.4971 20H67.4417L67.0803 60.6573Z"
                fill="white"
            />
            <Defs>
                <LinearGradient
                    id="paint0_linear_1564_570"
                    x1="29.9962"
                    y1="8.03122"
                    x2="90.0038"
                    y2="111.969"
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#F5C757" />
                    <Stop offset="1" stopColor="#F0AE0C" />
                </LinearGradient>
            </Defs>
        </SvgIcon>
    )
}
