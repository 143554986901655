import { FormattedMessage } from 'react-intl'

import { Button } from '@zeal/uikit/Button'
import { Header } from '@zeal/uikit/Header'
import { BoldDisconnected } from '@zeal/uikit/Icon/BoldDisconnected'
import { Popup } from '@zeal/uikit/Popup'

import { ConnectivityError } from '@zeal/domains/Error'

type Props = {
    error: ConnectivityError
    installationId: string
    onMsg: (msg: Msg) => void
}

export type Msg = { type: 'close' }

export const ConnectivityErrorPopup = ({ onMsg }: Props) => (
    <Popup.Layout onMsg={onMsg}>
        <Header
            icon={({ size, color }) => (
                <BoldDisconnected size={size} color={color} />
            )}
            title={
                <FormattedMessage
                    id="error.connectivity_error.title"
                    defaultMessage="No internet connection"
                />
            }
            subtitle={
                <FormattedMessage
                    id="error.connectivity_error.title"
                    defaultMessage="Please check your internet connection and try again."
                />
            }
        />

        <Popup.Actions>
            <Button
                variant="primary"
                size="regular"
                onClick={() => onMsg({ type: 'close' })}
            >
                <FormattedMessage id="action.close" defaultMessage="Close" />
            </Button>
        </Popup.Actions>
    </Popup.Layout>
)
