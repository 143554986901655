import { notReachable } from '@zeal/toolkit'

import { KeyStore } from '@zeal/domains/KeyStore'

import { CurrentNetwork, Network } from '..'

export const filterNetworksByKeyStoreType = <
    T extends Network | CurrentNetwork
>({
    keyStoreType,
    networks,
}: {
    keyStoreType: KeyStore['type']
    networks: T[]
}): T[] => {
    switch (keyStoreType) {
        case 'private_key_store':
        case 'ledger':
        case 'secret_phrase_key':
        case 'trezor':
        case 'track_only':
            return networks

        case 'safe_4337':
            return networks.filter((network) => {
                switch (network.type) {
                    case 'all_networks':
                        return true

                    case 'specific_network':
                        switch (network.network.smartWalletSupport.type) {
                            case 'supported':
                                return true
                            case 'not_supported':
                                return false
                            /* istanbul ignore next */
                            default:
                                return notReachable(
                                    network.network.smartWalletSupport
                                )
                        }

                    case 'predefined':
                    case 'custom':
                    case 'testnet':
                        switch (network.smartWalletSupport.type) {
                            case 'supported':
                                return true
                            case 'not_supported':
                                return false
                            /* istanbul ignore next */
                            default:
                                return notReachable(network.smartWalletSupport)
                        }

                    default:
                        return notReachable(network)
                }
            })

        default:
            return notReachable(keyStoreType)
    }
}
