import { notReachable } from '@zeal/toolkit'

import { Money2, MoneyByCurrency } from '@zeal/domains/Money'
import { FormattedCryptoMoney } from '@zeal/domains/Money/components/FormattedCryptoMoney'
import { FormattedFiatMoney } from '@zeal/domains/Money/components/FormattedFiatCurrency'

type Props = {
    amountInUserCurrency: Money2
}

export const FormattedBalanceInUserCurrency = ({
    amountInUserCurrency,
}: Props) => {
    switch (amountInUserCurrency.currency.type) {
        case 'FiatCurrency':
            return (
                <FormattedFiatMoney
                    minimumFractionDigits={0}
                    money={
                        amountInUserCurrency as MoneyByCurrency<
                            typeof amountInUserCurrency.currency
                        >
                    }
                />
            )

        case 'CryptoCurrency':
            return (
                <FormattedCryptoMoney
                    money={
                        amountInUserCurrency as MoneyByCurrency<
                            typeof amountInUserCurrency.currency
                        >
                    }
                />
            )

        default:
            return notReachable(amountInUserCurrency.currency)
    }
}
