import { useState } from 'react'

import { notReachable } from '@zeal/toolkit'
import { useLoadedPollableData } from '@zeal/toolkit/LoadableData/LoadedPollableData'
import { MsgOf } from '@zeal/toolkit/MsgOf'

import { Account } from '@zeal/domains/Account'
import {
    CryptoCurrency,
    Currency,
    KnownCurrencies,
} from '@zeal/domains/Currency'
import { FIAT_CURRENCIES } from '@zeal/domains/Currency/constants'
import { Taker } from '@zeal/domains/Earn'
import { fetchEarnDepositQuote } from '@zeal/domains/Earn/api/fetchEarnDepositQuote'
import { EARN_NETWORK } from '@zeal/domains/Earn/constants'
import { FXRate2 } from '@zeal/domains/FXRate'
import {
    NetworkMap,
    NetworkRPCMap,
    PredefinedNetwork,
} from '@zeal/domains/Network'
import { Portfolio } from '@zeal/domains/Portfolio'
import { postUserEvent } from '@zeal/domains/UserEvents/api/postUserEvent'

import { Layout } from './Layout'
import { Modal, State as ModalState } from './Modal'
import { Pollable } from './validation'

import { getNetworkMapFromSupportedNetworks } from '../../../getNetworkMapFromSupportedNetworks'
import { Connected, SendTransactionToExternalWallet } from '../../../types'

type Props = {
    fromCurrencies: CryptoCurrency[]
    fromCurrency: CryptoCurrency
    portfolio: Portfolio
    takerPortfolio: Portfolio
    connectionState: Connected
    taker: Taker
    takerUserCurrencyRate: FXRate2<CryptoCurrency, Currency>
    installationId: string
    sendTransaction: SendTransactionToExternalWallet
    networkRPCMap: NetworkRPCMap
    supportedNetworks: PredefinedNetwork[]
    onMsg: (msg: Msg) => void
}

type Msg =
    | Extract<
          MsgOf<typeof Modal>,
          { type: 'on_external_earn_deposit_completed_close_click' }
      >
    | Extract<
          MsgOf<typeof Layout>,
          {
              type:
                  | 'close'
                  | 'on_connect_to_correct_network_clicked'
                  | 'on_disconnect_clicked'
          }
      >

const initPollable = ({
    fromAccount,
    fromAccountPortfolio,
    networkMap,
    networkRPCMap,
    fromCurrency,
    taker,
    fromCurrencies,
    takerUserCurrencyRate,
    network,
}: {
    fromCurrencies: CryptoCurrency[]
    taker: Taker
    fromAccount: Account
    fromAccountPortfolio: Portfolio
    fromCurrency: CryptoCurrency
    networkMap: NetworkMap
    networkRPCMap: NetworkRPCMap
    takerUserCurrencyRate: FXRate2<CryptoCurrency, Currency>
    network: PredefinedNetwork
}): Pollable => {
    const knownCurrencies = {
        ...fromCurrencies.reduce<KnownCurrencies>((kc, item) => {
            kc[item.id] = item
            return kc
        }, {}),
        ...fromAccountPortfolio.currencies,
        [taker.cryptoCurrency.id]: taker.cryptoCurrency,
        ...FIAT_CURRENCIES,
    }

    return {
        type: 'reloading',
        params: {
            network,
            amount: null,
            fromAccount,
            fromCurrency,
            networkMap,

            taker,
            networkRPCMap,
            knownCurrencies,
        },
        data: {
            knownCurrencies,
            network,
            swapRoute: null,
            takerUserCurrencyRate,
        },
    }
}

const POLL_INTERVAL_MS = 60_000

export const Form = ({
    portfolio,
    fromCurrency,
    fromCurrencies,
    connectionState,
    networkRPCMap,
    takerPortfolio,
    supportedNetworks,
    taker,
    sendTransaction,
    takerUserCurrencyRate,
    installationId,
    onMsg,
}: Props) => {
    const [modal, setModal] = useState<ModalState>({ type: 'closed' })
    const [pollable, setPollable] = useLoadedPollableData(
        fetchEarnDepositQuote,
        () =>
            initPollable({
                fromAccountPortfolio: portfolio,
                fromAccount: connectionState.account,
                fromCurrencies,
                fromCurrency,
                networkMap:
                    getNetworkMapFromSupportedNetworks(supportedNetworks),
                taker,
                takerUserCurrencyRate,
                networkRPCMap,
                network: EARN_NETWORK,
            }),
        { pollIntervalMilliseconds: POLL_INTERVAL_MS }
    )

    return (
        <>
            <Layout
                fromAccountPortfolio={portfolio}
                connectionState={connectionState}
                taker={taker}
                takerPortfolio={takerPortfolio}
                pollable={pollable}
                onMsg={(msg) => {
                    switch (msg.type) {
                        case 'close':
                        case 'on_connect_to_correct_network_clicked':
                        case 'on_disconnect_clicked':
                            onMsg(msg)
                            break
                        case 'on_select_from_currency_click':
                            setModal({ type: 'select_from_currency' })
                            break
                        case 'on_amount_change':
                            setPollable({
                                type: 'reloading',
                                data: pollable.data,
                                params: {
                                    ...pollable.params,
                                    amount: msg.amount,
                                },
                            })
                            break
                        case 'on_submit_deposit_click':
                            postUserEvent({
                                type: 'EarnDepositInitiatedEvent',
                                source: 'external',
                                asset: taker.type,
                                installationId,
                            })
                            setModal({
                                type: 'submit_deposit_from_external_wallet',
                                earnDepositRequest: msg.earnDepositRequest,
                            })
                            break
                        /* istanbul ignore next */
                        default:
                            return notReachable(msg)
                    }
                }}
            />
            <Modal
                connectionState={connectionState}
                networkRPCMap={networkRPCMap}
                installationId={installationId}
                sendTransaction={sendTransaction}
                fromCurrencies={fromCurrencies}
                portfolio={portfolio}
                selectedCurrency={pollable.params.fromCurrency}
                state={modal}
                supportedNetworks={supportedNetworks}
                onMsg={(msg) => {
                    switch (msg.type) {
                        case 'close':
                        case 'on_failed_transaction_close_click':
                        case 'on_transaction_rejected':
                            setModal({ type: 'closed' })
                            break

                        case 'on_from_currency_selected':
                            setModal({ type: 'closed' })
                            setPollable({
                                type: 'reloading',
                                data: pollable.data,
                                params: {
                                    ...pollable.params,
                                    fromCurrency: msg.fromCurrency,
                                },
                            })
                            break

                        case 'on_external_earn_deposit_completed_close_click':
                            setModal({ type: 'closed' })
                            onMsg(msg)
                            break

                        case 'on_disconnect_clicked':
                            onMsg(msg)
                            break

                        /* istanbul ignore next */
                        default:
                            notReachable(msg)
                    }
                }}
            />
        </>
    )
}
