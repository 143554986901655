import { useState } from 'react'

import { notReachable } from '@zeal/toolkit'
import { MsgOf } from '@zeal/toolkit/MsgOf'
import { ZealPlatform } from '@zeal/toolkit/OS/ZealPlatform'

import { Account } from '@zeal/domains/Account'
import { CryptoCurrency } from '@zeal/domains/Currency'
import { NetworkRPCMap, PredefinedNetwork } from '@zeal/domains/Network'
import { Portfolio } from '@zeal/domains/Portfolio'
import { SubmitedTransactionQueued } from '@zeal/domains/TransactionRequest/domains/SubmitedTransaction'

import { Form } from './Form'
import { MonitorTransaction } from './MonitorTransaction'
import { SubmitTransaction } from './SubmitTransaction'
import { TopUpRequest } from './TopUpRequest'

import { Connected, SendTransactionToExternalWallet } from '../../types'

type Props = {
    sendTransaction: SendTransactionToExternalWallet
    topUpCurrencies: CryptoCurrency[]
    connectionState: Connected
    installationId: string
    account: Account
    portfolio: Portfolio
    supportedNetworks: PredefinedNetwork[]
    networkRPCMap: NetworkRPCMap
    onMsg: (msg: Msg) => void
}

type Msg =
    | Extract<
          MsgOf<typeof Form>,
          {
              type:
                  | 'close'
                  | 'on_connect_to_correct_network_clicked'
                  | 'on_connect_wallet_clicked'
                  | 'on_crypto_currency_selected'
                  | 'on_disconnect_clicked'
          }
      >
    | Extract<
          MsgOf<typeof MonitorTransaction>,
          { type: 'on_top_up_transaction_complete_close' }
      >

type State =
    | { type: 'form' }
    | {
          type: 'submit_transaction'
          topUpRequest: TopUpRequest
      }
    | {
          type: 'monitor_transaction'
          topUpRequest: TopUpRequest
          submittedTransaction: SubmitedTransactionQueued
      }

export const Flow = ({
    topUpCurrencies,
    sendTransaction,
    supportedNetworks,
    account,
    connectionState,
    portfolio,
    networkRPCMap,
    installationId,
    onMsg,
}: Props) => {
    const [state, setState] = useState<State>({ type: 'form' })

    switch (state.type) {
        case 'form':
            return (
                <Form
                    portfolio={portfolio}
                    connectionState={connectionState}
                    supportedNetworks={supportedNetworks}
                    account={account}
                    topUpCurrencies={topUpCurrencies}
                    onMsg={(msg) => {
                        switch (msg.type) {
                            case 'on_form_submitted':
                                setState({
                                    type: 'submit_transaction',
                                    topUpRequest: msg.topUpRequest,
                                })
                                break
                            case 'close':
                            case 'on_connect_to_correct_network_clicked':
                            case 'on_crypto_currency_selected':
                            case 'on_disconnect_clicked':
                                onMsg(msg)
                                break
                            /* istanbul ignore next */
                            default:
                                return notReachable(msg)
                        }
                    }}
                />
            )
        case 'submit_transaction':
            return (
                <SubmitTransaction
                    sendTransaction={sendTransaction}
                    installationId={installationId}
                    networkRPCMap={networkRPCMap}
                    topUpRequest={state.topUpRequest}
                    onMsg={(msg) => {
                        switch (msg.type) {
                            case 'close':
                            case 'on_transaction_rejected':
                                setState({ type: 'form' })
                                break
                            case 'on_transaction_submitted':
                                setState({
                                    type: 'monitor_transaction',
                                    submittedTransaction:
                                        msg.submittedTransaction,
                                    topUpRequest: state.topUpRequest,
                                })
                                break
                            /* istanbul ignore next */
                            default:
                                return notReachable(msg)
                        }
                    }}
                />
            )
        case 'monitor_transaction':
            return (
                <MonitorTransaction
                    topUpRequest={state.topUpRequest}
                    submittedTransaction={state.submittedTransaction}
                    networkRPCMap={networkRPCMap}
                    onMsg={(msg) => {
                        switch (msg.type) {
                            case 'on_transaction_failed_try_again_clicked':
                            case 'close':
                                setState({ type: 'form' })
                                break
                            case 'on_top_up_transaction_complete_close':
                                switch (ZealPlatform.OS) {
                                    case 'ios':
                                    case 'android':
                                        onMsg(msg)
                                        break
                                    case 'web':
                                        setState({ type: 'form' })
                                        break
                                    /* istanbul ignore next */
                                    default:
                                        return notReachable(ZealPlatform.OS)
                                }
                                break
                            /* istanbul ignore next */
                            default:
                                return notReachable(msg)
                        }
                    }}
                />
            )
        /* istanbul ignore next */
        default:
            return notReachable(state)
    }
}
