import { notReachable } from '@zeal/toolkit'
import { getEnvironment } from '@zeal/toolkit/Environment'

export const ZEAL_WEBSITE = 'https://www.zeal.app/'

export const DISCORD_URL = 'https://discord.zeal.app/'
export const TWITTER_URL = 'https://x.com/zealwallet/'

export const ZEAL_TERMS_OF_USE_URL = 'https://zeal.app/terms-and-conditions'

export const ZEAL_PRIVACY_POLICY_URL = 'https://www.zeal.app/privacy-policy'

export const EXTENSION_KEY = 'heamnjbnflcikcggoiplibfommfbkjpj'

export const EXTENSION_URL = `chrome-extension://${EXTENSION_KEY}`

export const EXTENSION_STORE_URL = `https://chromewebstore.google.com/detail/${EXTENSION_KEY}`

export const TOP_UP_DAPP_URL = (() => {
    const env = getEnvironment()

    switch (env) {
        case 'local':
            return 'http://localhost:3001'
        case 'development':
            return 'https://d1px4ezi2zradc.cloudfront.net'
        case 'production':
            return 'https://topup.zeal.app'
        /* istanbul ignore next */
        default:
            return notReachable(env)
    }
})()

export const APPLE_APP_SITE_ASSOCIATION_FILE_URL =
    'https://passkey.zealwallet.com/.well-known/apple-app-site-association'

export const ANDROID_ASSET_LINKS_FILE_URL =
    'https://passkey.zealwallet.com/.well-known/assetlinks.json'
