import { mnemonicToSeed } from '@scure/bip39'
import memoize from 'lodash.memoize'
import { HDKey, hdKeyToAccount, privateKeyToAccount } from 'viem/accounts' // eslint-disable-line no-restricted-imports
import { toHex } from 'viem/utils' // eslint-disable-line no-restricted-imports

import { ImperativeError } from '@zeal/toolkit/Error'
import * as Hexadecimal from '@zeal/toolkit/Hexadecimal'

import { Address } from './address'

import { failure, Result, success } from '../Result'

export type PrivateKey = {
    privateKey: `0x${string}`
    address: Address
}

export type NotValidPrivateKey = { type: 'not_valid_private_key' }

// on mobile it is super slow, and it is deterministic so we can memo it
const mnemonicToSeedSyncMemo = memoize(mnemonicToSeed)

export const fromString = (
    input: string
): Result<
    NotValidPrivateKey | Hexadecimal.StringValueNotHexadecimal,
    PrivateKey
> => Hexadecimal.parseFromString(input).andThen(fromHexadecimal)

export const fromHexadecimal = (
    input: Hexadecimal.Hexadecimal
): Result<NotValidPrivateKey, PrivateKey> => {
    try {
        const { address } = privateKeyToAccount(input)
        return success({
            address: address as Address,
            privateKey: input,
        })
    } catch {
        return failure({ type: 'not_valid_private_key' })
    }
}

export const fromMnemonic = async (
    mnemonic: string,
    path: `m/44'/60'/0'/0/${string}`
): Promise<PrivateKey> => {
    const seed = await mnemonicToSeedSyncMemo(mnemonic)
    const account = hdKeyToAccount(HDKey.fromMasterSeed(seed), { path })
    const privateKey = account.getHdKey().privateKey
    if (!privateKey) {
        throw new ImperativeError('cannot extract private key from path')
    }
    return {
        privateKey: toHex(privateKey),
        address: account.address as Address,
    }
}
