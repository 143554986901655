import { notReachable } from '@zeal/toolkit'
import { MsgOf } from '@zeal/toolkit/MsgOf'

import { Account } from '@zeal/domains/Account'
import { PredefinedNetwork } from '@zeal/domains/Network'

import { WalletDoesNotSupportNetwork } from './WalletDoesNotSupportNetwork'

type Props = {
    state: State
    connectedAccount: Account
    onMsg: (msg: Msg) => void
}

type Msg = MsgOf<typeof WalletDoesNotSupportNetwork>

export type State =
    | { type: 'closed' }
    | {
          type: 'wallet_does_not_support_network'
          network: PredefinedNetwork
      }

export const Modal = ({ state, onMsg, connectedAccount }: Props) => {
    switch (state.type) {
        case 'closed':
            return null
        case 'wallet_does_not_support_network':
            return (
                <WalletDoesNotSupportNetwork
                    connectedAccount={connectedAccount}
                    network={state.network}
                    onMsg={onMsg}
                />
            )
        /* istanbul ignore next */
        default:
            return notReachable(state)
    }
}
