import { notReachable } from '@zeal/toolkit'
import { MsgOf } from '@zeal/toolkit/MsgOf'

import { WalletSelector } from './WalletSelector'

type Props = {
    state: State
    onMsg: (msg: Msg) => void
}

type Msg = MsgOf<typeof WalletSelector>

export type State = { type: 'closed' } | { type: 'wallet_selector' }

export const Modal = ({ state, onMsg }: Props) => {
    switch (state.type) {
        case 'closed':
            return null
        case 'wallet_selector':
            return <WalletSelector onMsg={onMsg} />
        /* istanbul ignore next */
        default:
            return notReachable(state)
    }
}
