import {
    failure,
    object,
    Result,
    shape,
    string,
    success,
} from '@zeal/toolkit/Result'

import { PasskeyScreenLockMissing } from '@zeal/domains/Error/domains/Passkey'

export const parsePasskeyScreenLockMissing = (
    input: unknown
): Result<unknown, PasskeyScreenLockMissing> =>
    object(input)
        .andThen((obj) =>
            shape({
                message: string(obj.message).andThen((msg) =>
                    msg.match('Screen lock is missing') ||
                    msg.match(
                        'Cannot perform passkey request because neither passcode nor biometrics are set up on device'
                    )
                        ? success(msg)
                        : failure({
                              type: 'message_does_not_match_regexp',
                              msg,
                          })
                ),
            })
        )
        .map(() => ({ type: 'passkey_screen_lock_missing' }))
