import { notReachable } from '@zeal/toolkit'
import { fromFixedWithFraction } from '@zeal/toolkit/BigInt'
import { LoadedPollableData } from '@zeal/toolkit/LoadableData/LoadedPollableData'
import { values } from '@zeal/toolkit/Object'
import { failure, Result, shape, success } from '@zeal/toolkit/Result'

import { SwapRoute } from '@zeal/domains/Currency/domains/SwapQuote'
import {
    EarnDepositQuote,
    EarnDepositQuoteRequest,
    EarnDepositRequest,
} from '@zeal/domains/Earn'
import { CryptoMoney } from '@zeal/domains/Money'
import { PredefinedNetwork } from '@zeal/domains/Network'
import { Portfolio } from '@zeal/domains/Portfolio'
import { getTokenByCryptoCurrency } from '@zeal/domains/Portfolio/helpers/getTokenByCryptoCurrency'

import { Connected } from '../../../types'

export type Pollable = LoadedPollableData<
    EarnDepositQuote,
    EarnDepositQuoteRequest
>

type NoRoutesFoundError = { type: 'no_routes_found' }
type NotEnoughBalanceError = { type: 'not_enough_balance' }
type PollableReloading = { type: 'pollable_reloading' }
type PollableSubsequentFailed = { type: 'pollable_subsequent_failed' }
type AmountRequired = { type: 'amount_required' }

type ConnectedToWrongNetwork = {
    type: 'connected_to_wrong_network'
    correctNetwork: PredefinedNetwork
}

type SubmitError =
    | PollableReloading
    | PollableSubsequentFailed
    | NoRoutesFoundError
    | NotEnoughBalanceError
    | AmountRequired
    | ConnectedToWrongNetwork

export type FormError = {
    from?: NotEnoughBalanceError
    submit?: SubmitError
}

const validateFromBalance = ({
    pollable,
    fromAccountPortfolio,
}: {
    pollable: Pollable
    fromAccountPortfolio: Portfolio
}): Result<NotEnoughBalanceError, CryptoMoney> => {
    const currency = pollable.params.fromCurrency
    const fromToken = getTokenByCryptoCurrency({
        currency,
        portfolio: fromAccountPortfolio,
    })

    const amount = fromFixedWithFraction(
        pollable.params.amount,
        currency.fraction
    )

    if (fromToken.balance.amount < amount) {
        return failure({ type: 'not_enough_balance' })
    }

    return success({ amount, currency })
}

const validateFromToken = ({
    pollable,
}: {
    pollable: Pollable
}): Result<AmountRequired, unknown> => {
    const currency = pollable.params.fromCurrency

    if (
        !pollable.params.amount ||
        fromFixedWithFraction(pollable.params.amount, currency.fraction) === 0n
    ) {
        return failure({ type: 'amount_required' })
    }

    return success(pollable.params.amount)
}

const validateRoute = ({
    pollable,
}: {
    pollable: Pollable
}): Result<
    NoRoutesFoundError | PollableReloading | PollableSubsequentFailed,
    SwapRoute
> => {
    switch (pollable.type) {
        case 'loaded': {
            return pollable.data.swapRoute
                ? success(pollable.data.swapRoute)
                : failure({ type: 'no_routes_found' })
        }

        case 'reloading':
            return failure({ type: 'pollable_reloading' })

        case 'subsequent_failed':
            return failure({ type: 'pollable_subsequent_failed' })

        /* istanbul ignore next */
        default:
            return notReachable(pollable)
    }
}

const validateConnectedNetwork = ({
    connectionState,
    pollable,
}: {
    connectionState: Connected
    pollable: Pollable
}): Result<ConnectedToWrongNetwork, PredefinedNetwork> => {
    const connectedNetwork = connectionState.network
    switch (connectedNetwork.type) {
        case 'unsupported_network':
            return failure({
                type: 'connected_to_wrong_network',
                correctNetwork: pollable.params.network,
            })
        case 'supported_network':
            return connectedNetwork.network.hexChainId ===
                pollable.params.network.hexChainId
                ? success(connectedNetwork.network)
                : failure({
                      type: 'connected_to_wrong_network',
                      correctNetwork: pollable.params.network,
                  })
        /* istanbul ignore next */
        default:
            return notReachable(connectedNetwork)
    }
}

export const validate = ({
    pollable,
    fromAccountPortfolio,
    connectionState,
}: {
    pollable: Pollable
    fromAccountPortfolio: Portfolio
    connectionState: Connected
}): Result<FormError, EarnDepositRequest> =>
    shape({
        from: validateFromBalance({ pollable, fromAccountPortfolio }),

        submit: shape({
            from: validateFromToken({ pollable }).andThen(() =>
                validateFromBalance({ pollable, fromAccountPortfolio })
            ),
            swapRoute: validateRoute({ pollable }),
            network: validateConnectedNetwork({
                pollable,
                connectionState,
            }),
        }).mapError((error) => values(error)[0]),
    }).map(({ submit }) => ({
        network: submit.network,
        swapRoute: submit.swapRoute,
        from: submit.from,
        taker: pollable.params.taker,
        fromAccount: pollable.params.fromAccount,
    }))
