import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

import { Button } from '@zeal/uikit/Button'
import { Column } from '@zeal/uikit/Column'
import { Header } from '@zeal/uikit/Header'
import { Popup } from '@zeal/uikit/Popup'

import { notReachable } from '@zeal/toolkit'
import { usePollableData } from '@zeal/toolkit/LoadableData/PollableData'
import { useLiveRef } from '@zeal/toolkit/React'

import { EarnDepositRequest } from '@zeal/domains/Earn'
import { useCaptureErrorOnce } from '@zeal/domains/Error/hooks/useCaptureErrorOnce'
import { FormattedTokenBalanceWithSymbol2 } from '@zeal/domains/Money/components/FormattedTokenBalanceWithSymbol'
import { NetworkRPCMap } from '@zeal/domains/Network'
import { SubmitedTransaction } from '@zeal/domains/TransactionRequest/domains/SubmitedTransaction'
import { fetchTransaction } from '@zeal/domains/TransactionRequest/domains/SubmitedTransaction/api/fetchTransaction'
import { ProgressStatusBar } from '@zeal/domains/TransactionRequest/domains/SubmitedTransaction/components/ProgressStatusBar'

import { ExternalWalletAvatar } from '../../../../../components/ExternalWalletAvatar'

type Props = {
    earnDepositRequest: EarnDepositRequest
    submitedApproval: SubmitedTransaction
    networkRPCMap: NetworkRPCMap
    onMsg: (msg: Msg) => void
}

type Msg =
    | { type: 'close' }
    | { type: 'on_approval_completed' }
    | { type: 'on_failed_transaction_close_click' }

const TRANSACTION_POLL_INTERVAL_MS = 1000

export const MonitorApproval = ({
    earnDepositRequest,
    networkRPCMap,
    submitedApproval,
    onMsg,
}: Props) => {
    const captureErrorOnce = useCaptureErrorOnce()
    const onMsgLive = useLiveRef(onMsg)

    const [pollable] = usePollableData(
        fetchTransaction,
        {
            type: 'loading',
            params: {
                transaction: submitedApproval,
                network: earnDepositRequest.network,
                account: earnDepositRequest.fromAccount,
                networkRPCMap,
            },
        },
        {
            pollIntervalMilliseconds: TRANSACTION_POLL_INTERVAL_MS,
            stopIf: (pollable) => {
                switch (pollable.type) {
                    case 'loading':
                    case 'error':
                        return false
                    case 'loaded':
                    case 'reloading':
                    case 'subsequent_failed':
                        switch (pollable.data.state) {
                            case 'queued':
                            case 'included_in_block':
                                return false
                            case 'completed':
                            case 'failed':
                            case 'replaced':
                                return true
                            /* istanbul ignore next */
                            default:
                                return notReachable(pollable.data)
                        }
                    /* istanbul ignore next */
                    default:
                        return notReachable(pollable)
                }
            },
        }
    )

    useEffect(() => {
        switch (pollable.type) {
            case 'loading':
            case 'reloading':
                break
            case 'loaded':
                switch (pollable.data.state) {
                    case 'queued':
                    case 'included_in_block':
                    case 'failed':
                    case 'replaced':
                        break
                    case 'completed':
                        onMsgLive.current({ type: 'on_approval_completed' })
                        break
                    /* istanbul ignore next */
                    default:
                        return notReachable(pollable.data)
                }
                break
            case 'error':
            case 'subsequent_failed':
                captureErrorOnce(pollable.error)
                break
            /* istanbul ignore next */
            default:
                return notReachable(pollable)
        }
    }, [captureErrorOnce, onMsgLive, pollable])

    return (
        <Layout
            earnDepositRequest={earnDepositRequest}
            networkRPCMap={networkRPCMap}
            onMsg={onMsg}
            submittedTransaction={(() => {
                switch (pollable.type) {
                    case 'loading':
                    case 'error':
                        return pollable.params.transaction
                    case 'loaded':
                    case 'reloading':
                    case 'subsequent_failed':
                        return pollable.data
                    /* istanbul ignore next */
                    default:
                        return notReachable(pollable)
                }
            })()}
        />
    )
}

const Layout = ({
    earnDepositRequest,
    networkRPCMap,
    submittedTransaction,
    onMsg,
}: {
    earnDepositRequest: EarnDepositRequest
    submittedTransaction: SubmitedTransaction
    networkRPCMap: NetworkRPCMap
    onMsg: (msg: Msg) => void
}) => (
    <Popup.Layout onMsg={onMsg} background="surfaceDefault">
        <Column spacing={24}>
            <Header
                icon={({ size }) => (
                    <ExternalWalletAvatar
                        size={size}
                        fromAccount={earnDepositRequest.fromAccount}
                    />
                )}
                title={
                    <FormattedMessage
                        id="earn-deposit.monitor-approval.title"
                        defaultMessage="Approve {amount}"
                        values={{
                            amount: (
                                <FormattedTokenBalanceWithSymbol2
                                    money={earnDepositRequest.from}
                                />
                            ),
                        }}
                    />
                }
            />
            {(() => {
                switch (submittedTransaction.state) {
                    case 'queued':
                    case 'included_in_block':
                    case 'replaced':
                    case 'completed':
                        return (
                            <Popup.Actions>
                                <Column spacing={0}>
                                    <ProgressStatusBar
                                        rounded
                                        submitedTransaction={
                                            submittedTransaction
                                        }
                                        network={earnDepositRequest.network}
                                        networkRPCMap={networkRPCMap}
                                        queuedInitialProgress={10}
                                    />
                                </Column>
                            </Popup.Actions>
                        )
                    case 'failed':
                        return (
                            <Popup.Actions variant="column">
                                <Column spacing={0}>
                                    <ProgressStatusBar
                                        rounded
                                        submitedTransaction={
                                            submittedTransaction
                                        }
                                        network={earnDepositRequest.network}
                                        networkRPCMap={networkRPCMap}
                                        queuedInitialProgress={null}
                                    />
                                </Column>
                                <Button
                                    variant="secondary"
                                    onClick={() =>
                                        onMsg({
                                            type: 'on_failed_transaction_close_click',
                                        })
                                    }
                                    size="regular"
                                >
                                    <FormattedMessage
                                        id="dapp.monitor-transaction-popup.close-btn"
                                        defaultMessage="Close"
                                    />
                                </Button>
                            </Popup.Actions>
                        )
                    /* istanbul ignore next */
                    default:
                        return notReachable(submittedTransaction)
                }
            })()}
        </Column>
    </Popup.Layout>
)
