import { notReachable } from '@zeal/toolkit'
import { MsgOf } from '@zeal/toolkit/MsgOf'

import { ZealDAppEntryPoint } from '@zeal/domains/Main'
import { NetworkRPCMap, PredefinedNetwork } from '@zeal/domains/Network'

import { ConnectWallet } from './ConnectWallet'
import { NetworkSwitcher } from './NetworkSwitcher'

import { ConnectionState, SendTransactionToExternalWallet } from '../../types'

type Props = {
    connectionState: ConnectionState
    entryPoint: ZealDAppEntryPoint
    supportedNetworks: PredefinedNetwork[]
    networkRPCMap: NetworkRPCMap
    sendTransaction: SendTransactionToExternalWallet
    onMsg: (msg: Msg) => void
}

type Msg = MsgOf<typeof ConnectWallet> | MsgOf<typeof NetworkSwitcher>

export const Layout = ({
    entryPoint,
    onMsg,
    connectionState,
    supportedNetworks,
    networkRPCMap,
    sendTransaction,
}: Props) => {
    switch (connectionState.type) {
        case 'disconnected':
        case 'connecting':
        case 'reconnecting':
            return (
                <ConnectWallet
                    connectionState={connectionState}
                    onMsg={onMsg}
                />
            )
        case 'connected':
            return (
                <NetworkSwitcher
                    connectionState={connectionState}
                    entryPoint={entryPoint}
                    supportedNetworks={supportedNetworks}
                    sendTransaction={sendTransaction}
                    networkRPCMap={networkRPCMap}
                    onMsg={onMsg}
                />
            )
        /* istanbul ignore next */
        default:
            return notReachable(connectionState)
    }
}
