import { generateRandomNumber } from '@zeal/toolkit/Number'
import { numberString } from '@zeal/toolkit/Result'

import { Address } from '@zeal/domains/Address'
import { Network, NetworkRPCMap } from '@zeal/domains/Network'
import { fetchRPCResponse } from '@zeal/domains/RPCRequest/api/fetchRPCResponse'

export const fetchCurrentNonce = async ({
    address,
    network,
    networkRPCMap,
}: {
    address: Address
    network: Network
    networkRPCMap: NetworkRPCMap
}): Promise<number> =>
    fetchRPCResponse({
        network,
        networkRPCMap,
        request: {
            id: generateRandomNumber(),
            jsonrpc: '2.0',
            method: 'eth_getTransactionCount',
            params: [address, 'latest'],
        },
    }).then((data) =>
        numberString(data).getSuccessResultOrThrow(
            'failed to parse latest nonce from eth_getTransactionCount'
        )
    )
