import { useEffect } from 'react'

import { ImperativeError } from '@zeal/toolkit/Error'

import { Currency, CurrencyId, KnownCurrencies } from '@zeal/domains/Currency'
import { captureError } from '@zeal/domains/Error/helpers/captureError'

export const useCurrencyById = (
    currencyId: CurrencyId,
    knownCurrencies: KnownCurrencies
): Currency | null => {
    const currency = knownCurrencies[currencyId] || null

    useEffect(() => {
        if (!currency) {
            captureError(
                new ImperativeError('Unknown currency picked from record', {
                    currency: currencyId,
                })
            )
        }
    }, [currency, currencyId])

    return currency
}
