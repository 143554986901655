export type UnblockLoginUserDidNotExists = {
    type: 'unblock_login_user_did_not_exists'
}

export type UnblockUserWithAddressAlreadyExists = {
    type: 'unblock_user_with_address_already_exists'
}

export type UnblockInvalidOtpError = { type: 'unblock_invalid_otp' }

export type UnblockMaximumOTPSubmitAttemptsError = {
    type: 'unblock_maximum_otp_attempts_exceeded'
}

export type UnblockOtpExpiredError = {
    type: 'unblock_otp_expired'
}

export type UnblockUserAssociatedWithOtherMerchant = {
    type: 'unblock_user_associated_with_other_merchant'
}
export type UnblockNonceAlreadyInUse = {
    type: 'unblock_nonce_already_in_use'
}

export class UnknownUnblockError extends Error {
    isUnknownUnblockError = true
    type: 'unknown_unblock_error' = 'unknown_unblock_error' as const
    name: string = 'UnknownUnblockError' as const

    url: string
    method: string
    data: unknown
    status: number | null
    trace: string | null
    errorId: string

    constructor(
        url: string,
        method: string,
        status: number | null,
        trace: string | null,
        errorId: string,
        data: unknown,
        message: string
    ) {
        super(message)
        this.trace = trace
        this.url = url
        this.method = method
        this.status = status
        this.errorId = errorId
        this.data = data
    }
}

export type UnblockUserWithSuchEmailAlreadyExists = {
    type: 'unblock_user_with_such_email_already_exists'
}

export type UnblockAccountNumberAndSortCodeMismatch = {
    type: 'unblock_account_number_and_sort_code_mismatch'
}

export type UnblockInvalidIBAN = {
    type: 'unblock_invalid_iban'
}

export type UnblockInvalidFasterPaymentConfiguration = {
    type: 'unblock_invalid_faster_payment_configuration'
}

export type UnblockHardKycFailure = {
    type: 'unblock_hard_kyc_failure'
}

export type UnblockSessionExpired = {
    type: 'unblock_session_expired'
}

export type UnblockUnsupportedCountry = {
    type: 'unblock_unsupported_country'
}

export type UnblockError =
    | UnblockAccountNumberAndSortCodeMismatch
    | UnblockHardKycFailure
    | UnblockInvalidFasterPaymentConfiguration
    | UnblockInvalidIBAN
    | UnblockInvalidOtpError
    | UnblockLoginUserDidNotExists
    | UnblockMaximumOTPSubmitAttemptsError
    | UnblockNonceAlreadyInUse
    | UnblockOtpExpiredError
    | UnblockSessionExpired
    | UnblockUserAssociatedWithOtherMerchant
    | UnblockUserWithAddressAlreadyExists
    | UnblockUserWithSuchEmailAlreadyExists
    | UnblockUnsupportedCountry
    | UnknownUnblockError
