export const ZEAL_WALLET_WC_METADATA = {
    name: 'Zeal',
    description:
        'The best web3 wallet for builders and on-chain earners on all EVM networks.',
    url: 'zeal.app',
    icons: [
        'https://imagedelivery.net/_aTEfDRm7z3tKgu9JhfeKA/5416fb0b-9aec-4ffe-b7cd-c04c79ea4300/lg',
    ],
}

export const ZEAL_DAPP_WC_METADATA = {
    name: 'Zeal',
    description:
        'The best web3 wallet for builders and on-chain earners on all EVM networks.',
    url: 'https://topup.zeal.app',
    icons: [
        'https://imagedelivery.net/_aTEfDRm7z3tKgu9JhfeKA/5416fb0b-9aec-4ffe-b7cd-c04c79ea4300/lg',
    ],
    redirect: {
        native: 'zeal://',
    },
}

export const ZEAL_WALLET_WC_STORAGE_PREFIX = 'zealwalletwc'
export const ZEAL_WALLET_WC_PROJECT_ID = '77e3b295469d80a9a369cc184afe0369'
export const ZEAL_DAPP_WC_PROJECT_ID = '815ae5b3d43221addf4f34613ef2bc2c'
