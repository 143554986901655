import React from 'react'
import { Circle, Path } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

type Props = {
    size: number
}

export const EarnEth = ({ size }: Props) => {
    return (
        <SvgIcon width={size} height={size} viewBox="0 0 80 80" fill="none">
            <Circle cx="40" cy="40" r="40" fill="#EDF0F4" />
            <Path
                d="M39.8885 16L39.5703 17.0809V48.442L39.8885 48.7594L54.4457 40.1546L39.8885 16Z"
                fill="#343434"
            />
            <Path
                d="M39.8896 16L25.332 40.1546L39.8896 48.7594V33.5376V16Z"
                fill="#8C8C8C"
            />
            <Path
                d="M39.8903 51.5155L39.7109 51.7342V62.9055L39.8903 63.429L54.4564 42.915L39.8903 51.5155Z"
                fill="#3C3C3B"
            />
            <Path
                d="M39.8896 63.429V51.5155L25.332 42.915L39.8896 63.429Z"
                fill="#8C8C8C"
            />
            <Path
                d="M39.8906 48.7594L54.4478 40.1545L39.8906 33.5376V48.7594Z"
                fill="#141414"
            />
            <Path
                d="M25.332 40.1545L39.8896 48.7594V33.5376L25.332 40.1545Z"
                fill="#393939"
            />
        </SvgIcon>
    )
}
