import { FormatNumberOptions, useIntl } from 'react-intl'

import Big from 'big.js'

import { notReachable } from '@zeal/toolkit'

import { Money2 } from '@zeal/domains/Money'

type Props = {
    amountInUserCurrency: Money2
}

const CRYPTO_LIMIT_FOR_FORMATTING = 1000
const CRYPTO_NUMBER_OF_SIGNIFICANT_DIGITS = 4
const FIAT_MAX_FRACTION_DIGITS = 2
const FIAT_MIN_FRACTION_DIGITS = 0

export const FormattedAmountInUserCurrency = ({
    amountInUserCurrency,
}: Props) => {
    const { formatNumber } = useIntl()
    switch (amountInUserCurrency.currency.type) {
        case 'FiatCurrency': {
            const { amount, currency } = amountInUserCurrency

            const power = BigInt(10) ** BigInt(currency.fraction)

            const amountToFormat = new Big(amount.toString())
                .div(new Big(power.toString()))
                .toNumber()

            return formatNumber(amountToFormat, {
                maximumFractionDigits: FIAT_MAX_FRACTION_DIGITS,
                minimumFractionDigits: FIAT_MIN_FRACTION_DIGITS,
                signDisplay: 'never',
            })
        }

        case 'CryptoCurrency': {
            const { currency, amount } = amountInUserCurrency

            const power = BigInt(10) ** BigInt(currency.fraction)

            const dollars = amount / power
            const amountToFormat = new Big(amount.toString())
                .div(new Big(power.toString()))
                .toNumber()

            const options: FormatNumberOptions =
                dollars >= CRYPTO_LIMIT_FOR_FORMATTING
                    ? {
                          maximumFractionDigits: 0,
                          signDisplay: 'never' as const,
                      }
                    : {
                          maximumSignificantDigits:
                              CRYPTO_NUMBER_OF_SIGNIFICANT_DIGITS,
                          signDisplay: 'never' as const,
                      }

            return formatNumber(amountToFormat, options)
        }

        default:
            return notReachable(amountInUserCurrency.currency)
    }
}
