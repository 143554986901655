export type KnownNetwork = {
    type: 'mainnet' | 'testnet'
    name: string
    chainName: string
    hexChainId: string
    networkId: number
    icon: string | null
}

export const KNOWN_NETWORKS: KnownNetwork[] = [
    {
        type: 'mainnet',
        name: 'Ethereum Mainnet',
        chainName: 'ETH',
        hexChainId: '0x1',
        networkId: 1,
        icon: 'ethereum',
    },
    {
        type: 'mainnet',
        name: 'Expanse Network',
        chainName: 'EXP',
        hexChainId: '0x2',
        networkId: 1,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Ropsten',
        chainName: 'ETH',
        hexChainId: '0x3',
        networkId: 3,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Rinkeby',
        chainName: 'ETH',
        hexChainId: '0x4',
        networkId: 4,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Goerli',
        chainName: 'ETH',
        hexChainId: '0x5',
        networkId: 5,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Ethereum Classic Testnet Kotti',
        chainName: 'ETC',
        hexChainId: '0x6',
        networkId: 6,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'ThaiChain',
        chainName: 'TCH',
        hexChainId: '0x7',
        networkId: 7,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Ubiq',
        chainName: 'UBQ',
        hexChainId: '0x8',
        networkId: 8,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Ubiq Network Testnet',
        chainName: 'UBQ',
        hexChainId: '0x9',
        networkId: 2,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'OP Mainnet',
        chainName: 'ETH',
        hexChainId: '0xa',
        networkId: 10,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Metadium Mainnet',
        chainName: 'META',
        hexChainId: '0xb',
        networkId: 11,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Metadium Testnet',
        chainName: 'META',
        hexChainId: '0xc',
        networkId: 12,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Diode Testnet Staging',
        chainName: 'DIODE',
        hexChainId: '0xd',
        networkId: 13,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Flare Mainnet',
        chainName: 'FLR',
        hexChainId: '0xe',
        networkId: 14,
        icon: 'flare',
    },
    {
        type: 'mainnet',
        name: 'Diode Prenet',
        chainName: 'DIODE',
        hexChainId: '0xf',
        networkId: 15,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Flare Testnet Coston',
        chainName: 'FLR',
        hexChainId: '0x10',
        networkId: 16,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'ThaiChain 2.0 ThaiFi',
        chainName: 'TCH',
        hexChainId: '0x11',
        networkId: 17,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'ThunderCore Testnet',
        chainName: 'TST',
        hexChainId: '0x12',
        networkId: 18,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Songbird Canary-Network',
        chainName: 'SGB',
        hexChainId: '0x13',
        networkId: 19,
        icon: 'songbird',
    },
    {
        type: 'mainnet',
        name: 'Elastos Smart Chain',
        chainName: 'ETH',
        hexChainId: '0x14',
        networkId: 20,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Elastos Smart Chain Testnet',
        chainName: 'ETH',
        hexChainId: '0x15',
        networkId: 21,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'ELA-DID-Sidechain Mainnet',
        chainName: 'ETH',
        hexChainId: '0x16',
        networkId: 22,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'ELA-DID-Sidechain Testnet',
        chainName: 'ETH',
        hexChainId: '0x17',
        networkId: 23,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'KardiaChain Mainnet',
        chainName: 'KAI',
        hexChainId: '0x18',
        networkId: 0,
        icon: 'kardiachain',
    },
    {
        type: 'mainnet',
        name: 'Cronos Mainnet',
        chainName: 'CRO',
        hexChainId: '0x19',
        networkId: 25,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Genesis L1 testnet',
        chainName: 'genesis',
        hexChainId: '0x1a',
        networkId: 26,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'ShibaChain',
        chainName: 'SHIB',
        hexChainId: '0x1b',
        networkId: 27,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Boba Network Rinkeby Testnet',
        chainName: 'ETH',
        hexChainId: '0x1c',
        networkId: 28,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Genesis L1',
        chainName: 'genesis',
        hexChainId: '0x1d',
        networkId: 29,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'RSK Mainnet',
        chainName: 'RSK',
        hexChainId: '0x1e',
        networkId: 30,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'RSK Testnet',
        chainName: 'RSK',
        hexChainId: '0x1f',
        networkId: 31,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'GoodData Testnet',
        chainName: 'GooD',
        hexChainId: '0x20',
        networkId: 32,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'GoodData Mainnet',
        chainName: 'GooD',
        hexChainId: '0x21',
        networkId: 33,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Dithereum Testnet',
        chainName: 'DTH',
        hexChainId: '0x22',
        networkId: 34,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'TBWG Chain',
        chainName: 'TBWG',
        hexChainId: '0x23',
        networkId: 35,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Dxchain Mainnet',
        chainName: 'Dxchain',
        hexChainId: '0x24',
        networkId: 36,
        icon: 'dx',
    },
    {
        type: 'mainnet',
        name: 'SeedCoin-Network',
        chainName: 'SeedCoin-Network',
        hexChainId: '0x25',
        networkId: 37,
        icon: 'seedcoin',
    },
    {
        type: 'mainnet',
        name: 'Valorbit',
        chainName: 'VAL',
        hexChainId: '0x26',
        networkId: 38,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Unicorn Ultra Testnet',
        chainName: 'u2u',
        hexChainId: '0x27',
        networkId: 39,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Telos EVM Mainnet',
        chainName: 'TLOS',
        hexChainId: '0x28',
        networkId: 40,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Telos EVM Testnet',
        chainName: 'TLOS',
        hexChainId: '0x29',
        networkId: 41,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'LUKSO Mainnet',
        chainName: 'LUKSO',
        hexChainId: '0x2a',
        networkId: 42,
        icon: 'lukso',
    },
    {
        type: 'testnet',
        name: 'Darwinia Pangolin Testnet',
        chainName: 'pangolin',
        hexChainId: '0x2b',
        networkId: 43,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Darwinia Crab Network',
        chainName: 'crab',
        hexChainId: '0x2c',
        networkId: 44,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Darwinia Pangoro Testnet',
        chainName: 'pangoro',
        hexChainId: '0x2d',
        networkId: 45,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Darwinia Network',
        chainName: 'darwinia',
        hexChainId: '0x2e',
        networkId: 46,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Acria IntelliChain',
        chainName: 'AIC',
        hexChainId: '0x2f',
        networkId: 47,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Ennothem Mainnet Proterozoic',
        chainName: 'ETMP',
        hexChainId: '0x30',
        networkId: 48,
        icon: 'etmp',
    },
    {
        type: 'testnet',
        name: 'Ennothem Testnet Pioneer',
        chainName: 'ETMP',
        hexChainId: '0x31',
        networkId: 49,
        icon: 'etmp',
    },
    {
        type: 'mainnet',
        name: 'XinFin XDC Network',
        chainName: 'XDC',
        hexChainId: '0x32',
        networkId: 50,
        icon: 'xdc',
    },
    {
        type: 'mainnet',
        name: 'XDC Apothem Network',
        chainName: 'XDC',
        hexChainId: '0x33',
        networkId: 51,
        icon: 'xdc',
    },
    {
        type: 'mainnet',
        name: 'CoinEx Smart Chain Mainnet',
        chainName: 'CSC',
        hexChainId: '0x34',
        networkId: 52,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'CoinEx Smart Chain Testnet',
        chainName: 'CSC',
        hexChainId: '0x35',
        networkId: 53,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Openpiece Mainnet',
        chainName: 'OPENPIECE',
        hexChainId: '0x36',
        networkId: 54,
        icon: 'openpiece',
    },
    {
        type: 'mainnet',
        name: 'Zyx Mainnet',
        chainName: 'ZYX',
        hexChainId: '0x37',
        networkId: 55,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'BNB Smart Chain Mainnet',
        chainName: 'BSC',
        hexChainId: '0x38',
        networkId: 56,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Syscoin Mainnet',
        chainName: 'SYS',
        hexChainId: '0x39',
        networkId: 57,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Ontology Mainnet',
        chainName: 'Ontology',
        hexChainId: '0x3a',
        networkId: 58,
        icon: 'ontology',
    },
    {
        type: 'mainnet',
        name: 'EOS EVM Legacy',
        chainName: 'EOS',
        hexChainId: '0x3b',
        networkId: 59,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'GoChain',
        chainName: 'GO',
        hexChainId: '0x3c',
        networkId: 60,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Ethereum Classic Mainnet',
        chainName: 'ETC',
        hexChainId: '0x3d',
        networkId: 1,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Ethereum Classic Testnet Morden',
        chainName: 'ETC',
        hexChainId: '0x3e',
        networkId: 2,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Ethereum Classic Testnet Mordor',
        chainName: 'ETC',
        hexChainId: '0x3f',
        networkId: 7,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Ellaism',
        chainName: 'ELLA',
        hexChainId: '0x40',
        networkId: 64,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'OKExChain Testnet',
        chainName: 'okexchain',
        hexChainId: '0x41',
        networkId: 65,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'OKXChain Mainnet',
        chainName: 'okxchain',
        hexChainId: '0x42',
        networkId: 66,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'DBChain Testnet',
        chainName: 'DBM',
        hexChainId: '0x43',
        networkId: 67,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'SoterOne Mainnet',
        chainName: 'SOTER',
        hexChainId: '0x44',
        networkId: 68,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Optimism Kovan',
        chainName: 'ETH',
        hexChainId: '0x45',
        networkId: 69,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Hoo Smart Chain',
        chainName: 'HSC',
        hexChainId: '0x46',
        networkId: 70,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Conflux eSpace (Testnet)',
        chainName: 'Conflux',
        hexChainId: '0x47',
        networkId: 71,
        icon: 'conflux',
    },
    {
        type: 'testnet',
        name: 'DxChain Testnet',
        chainName: 'DxChain',
        hexChainId: '0x48',
        networkId: 72,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'FNCY',
        chainName: 'FNCY',
        hexChainId: '0x49',
        networkId: 73,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'IDChain Mainnet',
        chainName: 'IDChain',
        hexChainId: '0x4a',
        networkId: 74,
        icon: 'idchain',
    },
    {
        type: 'mainnet',
        name: 'Decimal Smart Chain Mainnet',
        chainName: 'DSC',
        hexChainId: '0x4b',
        networkId: 75,
        icon: 'dsc',
    },
    {
        type: 'mainnet',
        name: 'Mix',
        chainName: 'MIX',
        hexChainId: '0x4c',
        networkId: 76,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'POA Network Sokol',
        chainName: 'POA',
        hexChainId: '0x4d',
        networkId: 77,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'PrimusChain mainnet',
        chainName: 'PC',
        hexChainId: '0x4e',
        networkId: 78,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Zenith Mainnet',
        chainName: 'Zenith',
        hexChainId: '0x4f',
        networkId: 79,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'GeneChain',
        chainName: 'GeneChain',
        hexChainId: '0x50',
        networkId: 80,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Japan Open Chain Mainnet',
        chainName: 'JOC',
        hexChainId: '0x51',
        networkId: 81,
        icon: 'joc',
    },
    {
        type: 'mainnet',
        name: 'Meter Mainnet',
        chainName: 'METER',
        hexChainId: '0x52',
        networkId: 82,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Meter Testnet',
        chainName: 'METER Testnet',
        hexChainId: '0x53',
        networkId: 83,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Linqto Devnet',
        chainName: 'LNQ',
        hexChainId: '0x54',
        networkId: 84,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'GateChain Testnet',
        chainName: 'GTTEST',
        hexChainId: '0x55',
        networkId: 85,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'GateChain Mainnet',
        chainName: 'GT',
        hexChainId: '0x56',
        networkId: 86,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Nova Network',
        chainName: 'NNW',
        hexChainId: '0x57',
        networkId: 87,
        icon: 'novanetwork',
    },
    {
        type: 'mainnet',
        name: 'TomoChain',
        chainName: 'TOMO',
        hexChainId: '0x58',
        networkId: 88,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'TomoChain Testnet',
        chainName: 'TOMO',
        hexChainId: '0x59',
        networkId: 89,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Garizon Stage0',
        chainName: 'GAR',
        hexChainId: '0x5a',
        networkId: 90,
        icon: 'garizon',
    },
    {
        type: 'mainnet',
        name: 'Garizon Stage1',
        chainName: 'GAR',
        hexChainId: '0x5b',
        networkId: 91,
        icon: 'garizon',
    },
    {
        type: 'mainnet',
        name: 'Garizon Stage2',
        chainName: 'GAR',
        hexChainId: '0x5c',
        networkId: 92,
        icon: 'garizon',
    },
    {
        type: 'mainnet',
        name: 'Garizon Stage3',
        chainName: 'GAR',
        hexChainId: '0x5d',
        networkId: 93,
        icon: 'garizon',
    },
    {
        type: 'mainnet',
        name: 'SwissDLT',
        chainName: 'SDLT',
        hexChainId: '0x5e',
        networkId: 94,
        icon: 'bcts',
    },
    {
        type: 'mainnet',
        name: 'CamDL Mainnet',
        chainName: 'CADL',
        hexChainId: '0x5f',
        networkId: 95,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Bitkub Chain',
        chainName: 'BKC',
        hexChainId: '0x60',
        networkId: 96,
        icon: 'bkc',
    },
    {
        type: 'testnet',
        name: 'BNB Smart Chain Testnet',
        chainName: 'BSC',
        hexChainId: '0x61',
        networkId: 97,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Six Protocol',
        chainName: 'SIXNET',
        hexChainId: '0x62',
        networkId: 98,
        icon: 'six',
    },
    {
        type: 'mainnet',
        name: 'POA Network Core',
        chainName: 'POA',
        hexChainId: '0x63',
        networkId: 99,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Gnosis',
        chainName: 'GNO',
        hexChainId: '0x64',
        networkId: 100,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'EtherInc',
        chainName: 'ETI',
        hexChainId: '0x65',
        networkId: 1,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Web3Games Testnet',
        chainName: 'Web3Games',
        hexChainId: '0x66',
        networkId: 102,
        icon: 'web3games',
    },
    {
        type: 'testnet',
        name: 'Kaiba Lightning Chain Testnet',
        chainName: 'tKLC',
        hexChainId: '0x68',
        networkId: 104,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Web3Games Devnet',
        chainName: 'Web3Games',
        hexChainId: '0x69',
        networkId: 105,
        icon: 'web3games',
    },
    {
        type: 'mainnet',
        name: 'Velas EVM Mainnet',
        chainName: 'Velas',
        hexChainId: '0x6a',
        networkId: 106,
        icon: 'velas',
    },
    {
        type: 'testnet',
        name: 'Nebula Testnet',
        chainName: 'NTN',
        hexChainId: '0x6b',
        networkId: 107,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'ThunderCore Mainnet',
        chainName: 'TT',
        hexChainId: '0x6c',
        networkId: 108,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Proton Testnet',
        chainName: 'XPR',
        hexChainId: '0x6e',
        networkId: 110,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'EtherLite Chain',
        chainName: 'ETL',
        hexChainId: '0x6f',
        networkId: 111,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Coinbit Mainnet',
        chainName: 'Coinbit',
        hexChainId: '0x70',
        networkId: 112,
        icon: 'coinbit',
    },
    {
        type: 'mainnet',
        name: 'Dehvo',
        chainName: 'Dehvo',
        hexChainId: '0x71',
        networkId: 113,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Flare Testnet Coston2',
        chainName: 'FLR',
        hexChainId: '0x72',
        networkId: 114,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'DeBank Testnet',
        chainName: 'DeBank',
        hexChainId: '0x73',
        networkId: 115,
        icon: 'debank',
    },
    {
        type: 'mainnet',
        name: 'DeBank Mainnet',
        chainName: 'DeBank',
        hexChainId: '0x74',
        networkId: 116,
        icon: 'debank',
    },
    {
        type: 'mainnet',
        name: 'Uptick Mainnet',
        chainName: 'Uptick',
        hexChainId: '0x75',
        networkId: 117,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Arcology Testnet',
        chainName: 'Arcology',
        hexChainId: '0x76',
        networkId: 118,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'ENULS Mainnet',
        chainName: 'ENULS',
        hexChainId: '0x77',
        networkId: 119,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'ENULS Testnet',
        chainName: 'ENULS',
        hexChainId: '0x78',
        networkId: 120,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Realchain Mainnet',
        chainName: 'REAL',
        hexChainId: '0x79',
        networkId: 121,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Fuse Mainnet',
        chainName: 'FUSE',
        hexChainId: '0x7a',
        networkId: 122,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Fuse Sparknet',
        chainName: 'fuse',
        hexChainId: '0x7b',
        networkId: 123,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Decentralized Web Mainnet',
        chainName: 'DWU',
        hexChainId: '0x7c',
        networkId: 124,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'OYchain Testnet',
        chainName: 'OYchain',
        hexChainId: '0x7d',
        networkId: 125,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'OYchain Mainnet',
        chainName: 'OYchain',
        hexChainId: '0x7e',
        networkId: 126,
        icon: 'oychain',
    },
    {
        type: 'mainnet',
        name: 'Factory 127 Mainnet',
        chainName: 'FETH',
        hexChainId: '0x7f',
        networkId: 127,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Huobi ECO Chain Mainnet',
        chainName: 'Heco',
        hexChainId: '0x80',
        networkId: 128,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'iExec Sidechain',
        chainName: 'Bellecour',
        hexChainId: '0x86',
        networkId: 134,
        icon: 'rlc',
    },
    {
        type: 'testnet',
        name: 'Alyx Chain Testnet',
        chainName: 'Alyx Chain Testnet',
        hexChainId: '0x87',
        networkId: 135,
        icon: 'alyx',
    },
    {
        type: 'mainnet',
        name: 'Deamchain Mainnet',
        chainName: 'Deamchain',
        hexChainId: '0x88',
        networkId: 136,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Polygon Mainnet',
        chainName: 'Polygon',
        hexChainId: '0x89',
        networkId: 137,
        icon: 'polygon',
    },
    {
        type: 'mainnet',
        name: 'Defi Oracle Meta Mainnet',
        chainName: 'dfiometa',
        hexChainId: '0x8a',
        networkId: 1,
        icon: 'defioraclemeta',
    },
    {
        type: 'mainnet',
        name: 'WoopChain Mainnet',
        chainName: 'WOOP',
        hexChainId: '0x8b',
        networkId: 139,
        icon: 'woopchain',
    },
    {
        type: 'testnet',
        name: 'Openpiece Testnet',
        chainName: 'OPENPIECE',
        hexChainId: '0x8d',
        networkId: 141,
        icon: 'openpiece',
    },
    {
        type: 'mainnet',
        name: 'DAX CHAIN',
        chainName: 'DAX',
        hexChainId: '0x8e',
        networkId: 142,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'PHI Network v2',
        chainName: 'PHI',
        hexChainId: '0x90',
        networkId: 144,
        icon: 'phi',
    },
    {
        type: 'testnet',
        name: 'Six Protocol Testnet',
        chainName: 'FIVENET',
        hexChainId: '0x96',
        networkId: 150,
        icon: 'six',
    },
    {
        type: 'testnet',
        name: 'Tenet Testnet',
        chainName: 'TENET',
        hexChainId: '0x9b',
        networkId: 155,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'OEBlock Testnet',
        chainName: 'OEBt',
        hexChainId: '0x9c',
        networkId: 156,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Armonia Eva Chain Mainnet',
        chainName: 'Eva',
        hexChainId: '0xa0',
        networkId: 160,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Armonia Eva Chain Testnet',
        chainName: 'Wall-e',
        hexChainId: '0xa1',
        networkId: 161,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Lightstreams Testnet',
        chainName: 'PHT',
        hexChainId: '0xa2',
        networkId: 162,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Lightstreams Mainnet',
        chainName: 'PHT',
        hexChainId: '0xa3',
        networkId: 163,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Omni Testnet 1',
        chainName: 'Omni',
        hexChainId: '0xa5',
        networkId: 165,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Atoshi Testnet',
        chainName: 'ATOSHI',
        hexChainId: '0xa7',
        networkId: 167,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'AIOZ Network',
        chainName: 'AIOZ',
        hexChainId: '0xa8',
        networkId: 168,
        icon: 'aioz',
    },
    {
        type: 'testnet',
        name: 'HOO Smart Chain Testnet',
        chainName: 'ETH',
        hexChainId: '0xaa',
        networkId: 170,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Latam-Blockchain Resil Testnet',
        chainName: 'Resil',
        hexChainId: '0xac',
        networkId: 172,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'AME Chain Mainnet',
        chainName: 'AME',
        hexChainId: '0xb4',
        networkId: 180,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Seele Mainnet',
        chainName: 'Seele',
        hexChainId: '0xba',
        networkId: 186,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'BMC Mainnet',
        chainName: 'BMC',
        hexChainId: '0xbc',
        networkId: 188,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'BMC Testnet',
        chainName: 'BMC',
        hexChainId: '0xbd',
        networkId: 189,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Crypto Emergency',
        chainName: 'CEM',
        hexChainId: '0xc1',
        networkId: 193,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'OKBChain Testnet',
        chainName: 'okbchain',
        hexChainId: '0xc3',
        networkId: 195,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'OKBChain Mainnet',
        chainName: 'okbchain',
        hexChainId: '0xc4',
        networkId: 196,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Neutrinos TestNet',
        chainName: 'NEUTR',
        hexChainId: '0xc5',
        networkId: 197,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'BitTorrent Chain Mainnet',
        chainName: 'BTTC',
        hexChainId: '0xc7',
        networkId: 199,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Arbitrum on xDai',
        chainName: 'AOX',
        hexChainId: '0xc8',
        networkId: 200,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'MOAC testnet',
        chainName: 'MOAC',
        hexChainId: '0xc9',
        networkId: 201,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Structx Mainnet',
        chainName: 'utx',
        hexChainId: '0xd0',
        networkId: 208,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Bitnet',
        chainName: 'BTN',
        hexChainId: '0xd2',
        networkId: 210,
        icon: 'bitnet',
    },
    {
        type: 'mainnet',
        name: 'Freight Trust Network',
        chainName: 'EDI',
        hexChainId: '0xd3',
        networkId: 0,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'MAP Makalu',
        chainName: 'MAP',
        hexChainId: '0xd4',
        networkId: 212,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'SiriusNet V2',
        chainName: 'SIN2',
        hexChainId: '0xd9',
        networkId: 217,
        icon: 'siriusnet',
    },
    {
        type: 'mainnet',
        name: 'SoterOne Mainnet old',
        chainName: 'SOTER',
        hexChainId: '0xda',
        networkId: 218,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Permission',
        chainName: 'ASK',
        hexChainId: '0xde',
        networkId: 2221,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'LACHAIN Mainnet',
        chainName: 'LA',
        hexChainId: '0xe1',
        networkId: 225,
        icon: 'lachain',
    },
    {
        type: 'testnet',
        name: 'LACHAIN Testnet',
        chainName: 'TLA',
        hexChainId: '0xe2',
        networkId: 226,
        icon: 'lachain',
    },
    {
        type: 'mainnet',
        name: 'SwapDEX',
        chainName: 'SDX',
        hexChainId: '0xe6',
        networkId: 230,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Deamchain Testnet',
        chainName: 'Deamchain',
        hexChainId: '0xec',
        networkId: 236,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Plinga Mainnet',
        chainName: 'Plinga',
        hexChainId: '0xf2',
        networkId: 242,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Energy Web Chain',
        chainName: 'Energy Web Chain',
        hexChainId: '0xf6',
        networkId: 246,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Oasys Mainnet',
        chainName: 'Oasys',
        hexChainId: '0xf8',
        networkId: 248,
        icon: 'oasys',
    },
    {
        type: 'mainnet',
        name: 'Fantom Opera',
        chainName: 'FTM',
        hexChainId: '0xfa',
        networkId: 250,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Huobi ECO Chain Testnet',
        chainName: 'Heco',
        hexChainId: '0x100',
        networkId: 256,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Setheum',
        chainName: 'Setheum',
        hexChainId: '0x102',
        networkId: 258,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Neonlink Mainnet',
        chainName: 'Neonlink',
        hexChainId: '0x103',
        networkId: 259,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'SUR Blockchain Network',
        chainName: 'SUR',
        hexChainId: '0x106',
        networkId: 1,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'High Performance Blockchain',
        chainName: 'HPB',
        hexChainId: '0x10d',
        networkId: 269,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'EgonCoin Mainnet',
        chainName: 'EGON',
        hexChainId: '0x10f',
        networkId: 271,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'LaChain',
        chainName: 'LaChain',
        hexChainId: '0x112',
        networkId: 274,
        icon: 'LaChain',
    },
    {
        type: 'testnet',
        name: 'zkSync Era Testnet',
        chainName: 'ETH',
        hexChainId: '0x118',
        networkId: 280,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Boba Network',
        chainName: 'ETH',
        hexChainId: '0x120',
        networkId: 288,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Hedera Mainnet',
        chainName: 'Hedera',
        hexChainId: '0x127',
        networkId: 295,
        icon: 'hedera',
    },
    {
        type: 'testnet',
        name: 'Hedera Testnet',
        chainName: 'Hedera',
        hexChainId: '0x128',
        networkId: 296,
        icon: 'hedera',
    },
    {
        type: 'mainnet',
        name: 'Hedera Previewnet',
        chainName: 'Hedera',
        hexChainId: '0x129',
        networkId: 297,
        icon: 'hedera',
    },
    {
        type: 'mainnet',
        name: 'Hedera Localnet',
        chainName: 'Hedera',
        hexChainId: '0x12a',
        networkId: 298,
        icon: 'hedera',
    },
    {
        type: 'mainnet',
        name: '(deprecated) Optimism on GC',
        chainName: 'OGC',
        hexChainId: '0x12c',
        networkId: 300,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Bobaopera',
        chainName: 'Bobaopera',
        hexChainId: '0x12d',
        networkId: 301,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Neurochain Testnet',
        chainName: 'NCN',
        hexChainId: '0x12f',
        networkId: 303,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Omax Mainnet',
        chainName: 'OMAX Chain',
        hexChainId: '0x137',
        networkId: 311,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Neurochain Mainnet',
        chainName: 'NCN',
        hexChainId: '0x139',
        networkId: 313,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Filecoin - Mainnet',
        chainName: 'FIL',
        hexChainId: '0x13a',
        networkId: 314,
        icon: 'filecoin',
    },
    {
        type: 'mainnet',
        name: 'KCC Mainnet',
        chainName: 'KCC',
        hexChainId: '0x141',
        networkId: 321,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'KCC Testnet',
        chainName: 'KCC',
        hexChainId: '0x142',
        networkId: 322,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'zkSync Era Mainnet',
        chainName: 'ETH',
        hexChainId: '0x144',
        networkId: 324,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Web3Q Mainnet',
        chainName: 'Web3Q',
        hexChainId: '0x14d',
        networkId: 333,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'DFK Chain Test',
        chainName: 'DFK',
        hexChainId: '0x14f',
        networkId: 335,
        icon: 'dfk',
    },
    {
        type: 'mainnet',
        name: 'Shiden',
        chainName: 'SDN',
        hexChainId: '0x150',
        networkId: 336,
        icon: 'shiden',
    },
    {
        type: 'testnet',
        name: 'Cronos Testnet',
        chainName: 'CRO',
        hexChainId: '0x152',
        networkId: 338,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Yooldo Verse Mainnet',
        chainName: 'Yooldo Verse',
        hexChainId: '0x159',
        networkId: 345,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Theta Mainnet',
        chainName: 'Theta',
        hexChainId: '0x169',
        networkId: 361,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Theta Sapphire Testnet',
        chainName: 'Theta',
        hexChainId: '0x16b',
        networkId: 363,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Theta Amber Testnet',
        chainName: 'Theta',
        hexChainId: '0x16c',
        networkId: 364,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Theta Testnet',
        chainName: 'Theta',
        hexChainId: '0x16d',
        networkId: 365,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'PulseChain',
        chainName: 'PLS',
        hexChainId: '0x171',
        networkId: 369,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Consta Testnet',
        chainName: 'tCNT',
        hexChainId: '0x173',
        networkId: 371,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Lisinski',
        chainName: 'CRO',
        hexChainId: '0x181',
        networkId: 385,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'HyperonChain TestNet',
        chainName: 'HPN',
        hexChainId: '0x190',
        networkId: 400,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Ozone Chain Testnet',
        chainName: 'OZONE',
        hexChainId: '0x191',
        networkId: 401,
        icon: 'ozonechain',
    },
    {
        type: 'mainnet',
        name: 'Pepe Chain Mainnet',
        chainName: 'PC',
        hexChainId: '0x19b',
        networkId: 411,
        icon: 'pepechain',
    },
    {
        type: 'mainnet',
        name: 'SX Network Mainnet',
        chainName: 'SX',
        hexChainId: '0x1a0',
        networkId: 416,
        icon: 'SX',
    },
    {
        type: 'testnet',
        name: 'LaTestnet',
        chainName: 'LaTestnet',
        hexChainId: '0x1a2',
        networkId: 418,
        icon: 'LaChain',
    },
    {
        type: 'testnet',
        name: 'Optimism Goerli Testnet',
        chainName: 'ETH',
        hexChainId: '0x1a4',
        networkId: 420,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'PGN (Public Goods Network)',
        chainName: 'ETH',
        hexChainId: '0x1a8',
        networkId: 424,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Zeeth Chain',
        chainName: 'ZeethChain',
        hexChainId: '0x1ab',
        networkId: 427,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Obscuro Testnet',
        chainName: 'ETH',
        hexChainId: '0x1bb',
        networkId: 443,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Frenchain Testnet',
        chainName: 'tfren',
        hexChainId: '0x1bc',
        networkId: 444,
        icon: 'fren',
    },
    {
        type: 'mainnet',
        name: 'ARZIO Chain',
        chainName: 'ARZIO',
        hexChainId: '0x1c8',
        networkId: 456,
        icon: 'arzio',
    },
    {
        type: 'mainnet',
        name: 'Rupaya',
        chainName: 'RUPX',
        hexChainId: '0x1f3',
        networkId: 499,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Camino C-Chain',
        chainName: 'CAM',
        hexChainId: '0x1f4',
        networkId: 1000,
        icon: 'camino',
    },
    {
        type: 'testnet',
        name: 'Columbus Test Network',
        chainName: 'CAM',
        hexChainId: '0x1f5',
        networkId: 1001,
        icon: 'camino',
    },
    {
        type: 'mainnet',
        name: 'Double-A Chain Mainnet',
        chainName: 'AAC',
        hexChainId: '0x200',
        networkId: 512,
        icon: 'aac',
    },
    {
        type: 'testnet',
        name: 'Double-A Chain Testnet',
        chainName: 'AAC',
        hexChainId: '0x201',
        networkId: 513,
        icon: 'aac',
    },
    {
        type: 'mainnet',
        name: 'Gear Zero Network Mainnet',
        chainName: 'GearZero',
        hexChainId: '0x204',
        networkId: 516,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'XT Smart Chain Mainnet',
        chainName: 'XSC',
        hexChainId: '0x208',
        networkId: 1024,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Firechain Mainnet',
        chainName: 'FIRE',
        hexChainId: '0x211',
        networkId: 529,
        icon: 'firechain',
    },
    {
        type: 'mainnet',
        name: 'F(x)Core Mainnet Network',
        chainName: 'Fxcore',
        hexChainId: '0x212',
        networkId: 530,
        icon: 'fxcore',
    },
    {
        type: 'mainnet',
        name: 'Candle',
        chainName: 'Candle',
        hexChainId: '0x216',
        networkId: 534,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'PAWCHAIN Testnet',
        chainName: 'PAW',
        hexChainId: '0x21e',
        networkId: 542,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Vela1 Chain Mainnet',
        chainName: 'VELA1',
        hexChainId: '0x22b',
        networkId: 555,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Tao Network',
        chainName: 'TAO',
        hexChainId: '0x22e',
        networkId: 558,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Dogechain Testnet',
        chainName: 'DC',
        hexChainId: '0x238',
        networkId: 568,
        icon: 'dogechain',
    },
    {
        type: 'mainnet',
        name: 'Rollux Mainnet',
        chainName: 'SYS',
        hexChainId: '0x23a',
        networkId: 570,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Metis Stardust Testnet',
        chainName: 'ETH',
        hexChainId: '0x24c',
        networkId: 588,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Astar',
        chainName: 'ASTR',
        hexChainId: '0x250',
        networkId: 592,
        icon: 'astar',
    },
    {
        type: 'testnet',
        name: 'Acala Mandala Testnet TC9',
        chainName: 'mACA',
        hexChainId: '0x253',
        networkId: 595,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Karura Network Testnet',
        chainName: 'KAR',
        hexChainId: '0x254',
        networkId: 596,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Acala Network Testnet',
        chainName: 'ACA',
        hexChainId: '0x255',
        networkId: 597,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Metis Goerli Testnet',
        chainName: 'ETH',
        hexChainId: '0x257',
        networkId: 599,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Meshnyan testnet',
        chainName: 'MeshTestChain',
        hexChainId: '0x258',
        networkId: 600,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'PEER Testnet',
        chainName: 'PEER',
        hexChainId: '0x259',
        networkId: 601,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Graphlinq Blockchain Mainnet',
        chainName: 'GLQ Blockchain',
        hexChainId: '0x266',
        networkId: 614,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Avocado',
        chainName: 'Avocado',
        hexChainId: '0x27a',
        networkId: 634,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'SX Network Testnet',
        chainName: 'SX',
        hexChainId: '0x287',
        networkId: 647,
        icon: 'SX',
    },
    {
        type: 'mainnet',
        name: 'Endurance Smart Chain Mainnet',
        chainName: 'ACE',
        hexChainId: '0x288',
        networkId: 648,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Pixie Chain Testnet',
        chainName: 'PixieChain',
        hexChainId: '0x29a',
        networkId: 666,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'LAOS Arrakis',
        chainName: 'LAOS',
        hexChainId: '0x29b',
        networkId: 667,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Karura Network',
        chainName: 'KAR',
        hexChainId: '0x2ae',
        networkId: 686,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Star Social Testnet',
        chainName: 'SNS',
        hexChainId: '0x2bc',
        networkId: 700,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'BlockChain Station Mainnet',
        chainName: 'BCS',
        hexChainId: '0x2c3',
        networkId: 707,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'BlockChain Station Testnet',
        chainName: 'BCS',
        hexChainId: '0x2c4',
        networkId: 708,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Shibarium Beta',
        chainName: 'Shibarium',
        hexChainId: '0x2cf',
        networkId: 719,
        icon: 'shibarium',
    },
    {
        type: 'mainnet',
        name: 'Lycan Chain',
        chainName: 'LYC',
        hexChainId: '0x2d1',
        networkId: 721,
        icon: 'lycanchain',
    },
    {
        type: 'testnet',
        name: 'Canto Testnet',
        chainName: 'Canto Tesnet',
        hexChainId: '0x2e4',
        networkId: 740,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Vention Smart Chain Testnet',
        chainName: 'VSCT',
        hexChainId: '0x2e5',
        networkId: 741,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Script Testnet',
        chainName: 'SPAY',
        hexChainId: '0x2e6',
        networkId: 742,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'QL1',
        chainName: 'QOM',
        hexChainId: '0x2fe',
        networkId: 766,
        icon: 'qom',
    },
    {
        type: 'testnet',
        name: 'OpenChain Testnet',
        chainName: 'OpenChain Testnet',
        hexChainId: '0x308',
        networkId: 776,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'cheapETH',
        chainName: 'cheapETH',
        hexChainId: '0x309',
        networkId: 777,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'MAAL Sharia Chain',
        chainName: 'MAAL',
        hexChainId: '0x312',
        networkId: 786,
        icon: 'maal',
    },
    {
        type: 'mainnet',
        name: 'Acala Network',
        chainName: 'ACA',
        hexChainId: '0x313',
        networkId: 787,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Aerochain Testnet',
        chainName: 'Aerochain',
        hexChainId: '0x314',
        networkId: 788,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Patex',
        chainName: 'ETH',
        hexChainId: '0x315',
        networkId: 789,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Lucid Blockchain',
        chainName: 'Lucid',
        hexChainId: '0x320',
        networkId: 800,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Haic',
        chainName: 'Haic',
        hexChainId: '0x323',
        networkId: 803,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Portal Fantasy Chain Test',
        chainName: 'PF',
        hexChainId: '0x328',
        networkId: 808,
        icon: 'pf',
    },
    {
        type: 'mainnet',
        name: 'Qitmeer',
        chainName: 'MEER',
        hexChainId: '0x32d',
        networkId: 813,
        icon: 'meer',
    },
    {
        type: 'mainnet',
        name: 'BeOne Chain Mainnet',
        chainName: 'BOC',
        hexChainId: '0x332',
        networkId: 818,
        icon: 'beonechain',
    },
    {
        type: 'mainnet',
        name: 'Callisto Mainnet',
        chainName: 'CLO',
        hexChainId: '0x334',
        networkId: 1,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Callisto Testnet Deprecated',
        chainName: 'CLO',
        hexChainId: '0x335',
        networkId: 2,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Taraxa Mainnet',
        chainName: 'Tara',
        hexChainId: '0x349',
        networkId: 841,
        icon: 'taraxa',
    },
    {
        type: 'testnet',
        name: 'Taraxa Testnet',
        chainName: 'Tara',
        hexChainId: '0x34a',
        networkId: 842,
        icon: 'taraxa',
    },
    {
        type: 'mainnet',
        name: 'Zeeth Chain Dev',
        chainName: 'ZeethChainDev',
        hexChainId: '0x35b',
        networkId: 859,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Fantasia Chain Mainnet',
        chainName: 'FSC',
        hexChainId: '0x364',
        networkId: 868,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Bandai Namco Research Verse Mainnet',
        chainName: 'Bandai Namco Research Verse',
        hexChainId: '0x36c',
        networkId: 876,
        icon: 'bnken',
    },
    {
        type: 'mainnet',
        name: 'Dexit Network',
        chainName: 'DXT',
        hexChainId: '0x36d',
        networkId: 877,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Ambros Chain Mainnet',
        chainName: 'ambroschain',
        hexChainId: '0x370',
        networkId: 880,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Wanchain',
        chainName: 'WAN',
        hexChainId: '0x378',
        networkId: 888,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Garizon Testnet Stage0',
        chainName: 'GAR',
        hexChainId: '0x384',
        networkId: 900,
        icon: 'garizon',
    },
    {
        type: 'testnet',
        name: 'Garizon Testnet Stage1',
        chainName: 'GAR',
        hexChainId: '0x385',
        networkId: 901,
        icon: 'garizon',
    },
    {
        type: 'testnet',
        name: 'Garizon Testnet Stage2',
        chainName: 'GAR',
        hexChainId: '0x386',
        networkId: 902,
        icon: 'garizon',
    },
    {
        type: 'testnet',
        name: 'Garizon Testnet Stage3',
        chainName: 'GAR',
        hexChainId: '0x387',
        networkId: 903,
        icon: 'garizon',
    },
    {
        type: 'mainnet',
        name: 'Portal Fantasy Chain',
        chainName: 'PF',
        hexChainId: '0x38d',
        networkId: 909,
        icon: 'pf',
    },
    {
        type: 'testnet',
        name: 'DecentraBone Layer1 Testnet',
        chainName: 'DBONE',
        hexChainId: '0x38e',
        networkId: 910,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Rinia Testnet',
        chainName: 'FIRE',
        hexChainId: '0x395',
        networkId: 917,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Mode Testnet',
        chainName: 'ETH',
        hexChainId: '0x397',
        networkId: 919,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'PulseChain Testnet',
        chainName: 'tPLS',
        hexChainId: '0x3ac',
        networkId: 940,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'PulseChain Testnet v2b',
        chainName: 't2bPLS',
        hexChainId: '0x3ad',
        networkId: 941,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'PulseChain Testnet v3',
        chainName: 't3PLS',
        hexChainId: '0x3ae',
        networkId: 942,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'PulseChain Testnet v4',
        chainName: 't4PLS',
        hexChainId: '0x3af',
        networkId: 943,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'muNode Testnet',
        chainName: 'munode',
        hexChainId: '0x3bc',
        networkId: 956,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Oort Mainnet',
        chainName: 'Oort Mainnet',
        hexChainId: '0x3ca',
        networkId: 970,
        icon: 'ccn',
    },
    {
        type: 'mainnet',
        name: 'Oort Huygens',
        chainName: 'Huygens',
        hexChainId: '0x3cb',
        networkId: 971,
        icon: 'ccn',
    },
    {
        type: 'mainnet',
        name: 'Oort Ascraeus',
        chainName: 'Ascraeus',
        hexChainId: '0x3cc',
        networkId: 972,
        icon: 'ccn',
    },
    {
        type: 'mainnet',
        name: 'Nepal Blockchain Network',
        chainName: 'YETI',
        hexChainId: '0x3d1',
        networkId: 977,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'TOP Mainnet EVM',
        chainName: 'TOP',
        hexChainId: '0x3d4',
        networkId: 0,
        icon: 'top',
    },
    {
        type: 'mainnet',
        name: 'Memo Smart Chain Mainnet',
        chainName: 'MEMO',
        hexChainId: '0x3d9',
        networkId: 985,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'TOP Mainnet',
        chainName: 'TOP',
        hexChainId: '0x3dd',
        networkId: 0,
        icon: 'top',
    },
    {
        type: 'mainnet',
        name: 'eLiberty Mainnet',
        chainName: '$EL',
        hexChainId: '0x3de',
        networkId: 990,
        icon: null,
    },
    {
        type: 'mainnet',
        name: '5ireChain Thunder',
        chainName: '5ireChain',
        hexChainId: '0x3e5',
        networkId: 997,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Lucky Network',
        chainName: 'LN',
        hexChainId: '0x3e6',
        networkId: 998,
        icon: 'lucky',
    },
    {
        type: 'testnet',
        name: 'Wanchain Testnet',
        chainName: 'WAN',
        hexChainId: '0x3e7',
        networkId: 999,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'GTON Mainnet',
        chainName: 'GTON',
        hexChainId: '0x3e8',
        networkId: 1000,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Klaytn Testnet Baobab',
        chainName: 'KLAY',
        hexChainId: '0x3e9',
        networkId: 1001,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'T-EKTA',
        chainName: 'T-EKTA',
        hexChainId: '0x3ec',
        networkId: 1004,
        icon: 'ekta',
    },
    {
        type: 'testnet',
        name: 'Newton Testnet',
        chainName: 'NEW',
        hexChainId: '0x3ef',
        networkId: 1007,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Eurus Mainnet',
        chainName: 'EUN',
        hexChainId: '0x3f0',
        networkId: 1008,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Evrice Network',
        chainName: 'EVC',
        hexChainId: '0x3f2',
        networkId: 1010,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Newton',
        chainName: 'NEW',
        hexChainId: '0x3f4',
        networkId: 1012,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Sakura',
        chainName: 'Sakura',
        hexChainId: '0x3fe',
        networkId: 1022,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Clover Testnet',
        chainName: 'Clover',
        hexChainId: '0x3ff',
        networkId: 1023,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'CLV Parachain',
        chainName: 'CLV',
        hexChainId: '0x400',
        networkId: 1024,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'BitTorrent Chain Testnet',
        chainName: 'BTTC',
        hexChainId: '0x404',
        networkId: 1028,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Conflux eSpace',
        chainName: 'Conflux',
        hexChainId: '0x406',
        networkId: 1030,
        icon: 'conflux',
    },
    {
        type: 'testnet',
        name: 'Proxy Network Testnet',
        chainName: 'Proxy Network',
        hexChainId: '0x407',
        networkId: 1031,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Bronos Testnet',
        chainName: 'Bronos',
        hexChainId: '0x40e',
        networkId: 1038,
        icon: 'bronos',
    },
    {
        type: 'mainnet',
        name: 'Bronos Mainnet',
        chainName: 'Bronos',
        hexChainId: '0x40f',
        networkId: 1039,
        icon: 'bronos',
    },
    {
        type: 'testnet',
        name: 'ShimmerEVM Testnet Deprecated',
        chainName: 'ShimmerEVM',
        hexChainId: '0x42f',
        networkId: 1071,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'ShimmerEVM Testnet',
        chainName: 'ShimmerEVM',
        hexChainId: '0x430',
        networkId: 1072,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Mintara Testnet',
        chainName: 'Mintara',
        hexChainId: '0x437',
        networkId: 1079,
        icon: 'mintara',
    },
    {
        type: 'mainnet',
        name: 'Mintara Mainnet',
        chainName: 'Mintara',
        hexChainId: '0x438',
        networkId: 1080,
        icon: 'mintara',
    },
    {
        type: 'mainnet',
        name: 'Metis Andromeda Mainnet',
        chainName: 'ETH',
        hexChainId: '0x440',
        networkId: 1088,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'MOAC mainnet',
        chainName: 'MOAC',
        hexChainId: '0x44b',
        networkId: 1099,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Polygon zkEVM',
        chainName: 'Polygon',
        hexChainId: '0x44d',
        networkId: 1101,
        icon: 'zkevm',
    },
    {
        type: 'mainnet',
        name: 'WEMIX3.0 Mainnet',
        chainName: 'WEMIX',
        hexChainId: '0x457',
        networkId: 1111,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'WEMIX3.0 Testnet',
        chainName: 'TWEMIX',
        hexChainId: '0x458',
        networkId: 1112,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Core Blockchain Testnet',
        chainName: 'Core',
        hexChainId: '0x45b',
        networkId: 1115,
        icon: 'core',
    },
    {
        type: 'mainnet',
        name: 'Core Blockchain Mainnet',
        chainName: 'Core',
        hexChainId: '0x45c',
        networkId: 1116,
        icon: 'core',
    },
    {
        type: 'mainnet',
        name: 'Dogcoin Mainnet',
        chainName: 'DOGS',
        hexChainId: '0x45d',
        networkId: 1117,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'DeFiChain EVM Network Mainnet',
        chainName: 'defichain-evm',
        hexChainId: '0x46a',
        networkId: 1130,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'DeFiChain EVM Network Testnet',
        chainName: 'defichain-evm-testnet',
        hexChainId: '0x46b',
        networkId: 1131,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'AmStar Testnet',
        chainName: 'AmStar',
        hexChainId: '0x472',
        networkId: 1138,
        icon: 'amstar',
    },
    {
        type: 'mainnet',
        name: 'MathChain',
        chainName: 'MATH',
        hexChainId: '0x473',
        networkId: 1139,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'MathChain Testnet',
        chainName: 'MATH',
        hexChainId: '0x474',
        networkId: 1140,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Symplexia Smart Chain',
        chainName: 'Plexchain',
        hexChainId: '0x47d',
        networkId: 1149,
        icon: 'plexchain',
    },
    {
        type: 'testnet',
        name: 'Origin Testnet',
        chainName: 'Origin',
        hexChainId: '0x492',
        networkId: 1170,
        icon: 'origin',
    },
    {
        type: 'testnet',
        name: 'Smart Host Teknoloji TESTNET',
        chainName: 'SHT',
        hexChainId: '0x499',
        networkId: 1177,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Iora Chain',
        chainName: 'IORA',
        hexChainId: '0x4ad',
        networkId: 1197,
        icon: 'iorachain',
    },
    {
        type: 'testnet',
        name: 'Evanesco Testnet',
        chainName: 'Evanesco Testnet',
        hexChainId: '0x4b1',
        networkId: 1201,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'World Trade Technical Chain Mainnet',
        chainName: 'WTT',
        hexChainId: '0x4b2',
        networkId: 2048,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Popcateum Mainnet',
        chainName: 'POPCATEUM',
        hexChainId: '0x4bd',
        networkId: 1213,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'EnterChain Mainnet',
        chainName: 'ENTER',
        hexChainId: '0x4be',
        networkId: 1214,
        icon: 'enter',
    },
    {
        type: 'mainnet',
        name: 'Exzo Network Mainnet',
        chainName: 'EXZO',
        hexChainId: '0x4cd',
        networkId: 1229,
        icon: 'exzo',
    },
    {
        type: 'testnet',
        name: 'Ultron Testnet',
        chainName: 'Ultron',
        hexChainId: '0x4ce',
        networkId: 1230,
        icon: 'ultron',
    },
    {
        type: 'mainnet',
        name: 'Ultron Mainnet',
        chainName: 'Ultron',
        hexChainId: '0x4cf',
        networkId: 1231,
        icon: 'ultron',
    },
    {
        type: 'mainnet',
        name: 'Step Network',
        chainName: 'STEP',
        hexChainId: '0x4d2',
        networkId: 1234,
        icon: 'step',
    },
    {
        type: 'mainnet',
        name: 'ARC Mainnet',
        chainName: 'ARC',
        hexChainId: '0x4db',
        networkId: 1243,
        icon: 'arc',
    },
    {
        type: 'testnet',
        name: 'ARC Testnet',
        chainName: 'ARC',
        hexChainId: '0x4dc',
        networkId: 1244,
        icon: 'arc',
    },
    {
        type: 'mainnet',
        name: 'OM Platform Mainnet',
        chainName: 'omplatform',
        hexChainId: '0x4de',
        networkId: 1246,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'CIC Chain Testnet',
        chainName: 'CICT',
        hexChainId: '0x4e4',
        networkId: 1252,
        icon: 'cicchain',
    },
    {
        type: 'mainnet',
        name: 'HALO Mainnet',
        chainName: 'HALO',
        hexChainId: '0x500',
        networkId: 1280,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Moonbeam',
        chainName: 'MOON',
        hexChainId: '0x504',
        networkId: 1284,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Moonriver',
        chainName: 'MOON',
        hexChainId: '0x505',
        networkId: 1285,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Moonrock old',
        chainName: 'MOON',
        hexChainId: '0x506',
        networkId: 1286,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Moonbase Alpha',
        chainName: 'MOON',
        hexChainId: '0x507',
        networkId: 1287,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Moonrock',
        chainName: 'MOON',
        hexChainId: '0x508',
        networkId: 1288,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Swisstronik Testnet',
        chainName: 'SWTR',
        hexChainId: '0x50b',
        networkId: 1291,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Bobabeam',
        chainName: 'Bobabeam',
        hexChainId: '0x50e',
        networkId: 1294,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Bobabase Testnet',
        chainName: 'Bobabase Testnet',
        hexChainId: '0x511',
        networkId: 1297,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Dos Fuji Subnet',
        chainName: 'DOS',
        hexChainId: '0x51f',
        networkId: 1311,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Alyx Mainnet',
        chainName: 'ALYX',
        hexChainId: '0x522',
        networkId: 1314,
        icon: 'alyx',
    },
    {
        type: 'mainnet',
        name: 'Aitd Mainnet',
        chainName: 'AITD',
        hexChainId: '0x527',
        networkId: 1319,
        icon: 'aitd',
    },
    {
        type: 'testnet',
        name: 'Aitd Testnet',
        chainName: 'AITD',
        hexChainId: '0x528',
        networkId: 1320,
        icon: 'aitd',
    },
    {
        type: 'testnet',
        name: 'Geth Testnet',
        chainName: 'ETH',
        hexChainId: '0x539',
        networkId: 1337,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Elysium Testnet',
        chainName: 'Elysium',
        hexChainId: '0x53a',
        networkId: 1338,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Elysium Mainnet',
        chainName: 'Elysium',
        hexChainId: '0x53b',
        networkId: 1339,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'CIC Chain Mainnet',
        chainName: 'CIC',
        hexChainId: '0x549',
        networkId: 1353,
        icon: 'cicchain',
    },
    {
        type: 'mainnet',
        name: 'Zafirium Mainnet',
        chainName: 'ZAFIC',
        hexChainId: '0x559',
        networkId: 1369,
        icon: 'zafirium',
    },
    {
        type: 'mainnet',
        name: 'Kalar Chain',
        chainName: 'KLC',
        hexChainId: '0x563',
        networkId: 1379,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'AmStar Mainnet',
        chainName: 'AmStar',
        hexChainId: '0x56c',
        networkId: 1388,
        icon: 'amstar',
    },
    {
        type: 'mainnet',
        name: 'Joseon Mainnet',
        chainName: 'Joseon',
        hexChainId: '0x570',
        networkId: 1392,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Polygon zkEVM Testnet old',
        chainName: 'Polygon',
        hexChainId: '0x57a',
        networkId: 1402,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Polygon zkEVM Testnet Pre Audit-Upgraded',
        chainName: 'Polygon',
        hexChainId: '0x58e',
        networkId: 1422,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Rikeza Network Mainnet',
        chainName: 'Rikeza',
        hexChainId: '0x599',
        networkId: 1433,
        icon: 'rikeza',
    },
    {
        type: 'mainnet',
        name: 'Living Assets Mainnet',
        chainName: 'LAS',
        hexChainId: '0x5a0',
        networkId: 1440,
        icon: 'livingassets',
    },
    {
        type: 'testnet',
        name: 'Polygon zkEVM Testnet',
        chainName: 'Polygon',
        hexChainId: '0x5a2',
        networkId: 1442,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'GIL Testnet',
        chainName: 'GIL',
        hexChainId: '0x5ac',
        networkId: 1452,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Ctex Scan Blockchain',
        chainName: 'Ctex Scan Blockchain',
        hexChainId: '0x5af',
        networkId: 1455,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'BEVM',
        chainName: 'ChainX',
        hexChainId: '0x5dd',
        networkId: 1501,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Sherpax Mainnet',
        chainName: 'Sherpax Mainnet',
        hexChainId: '0x5e2',
        networkId: 1506,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Sherpax Testnet',
        chainName: 'Sherpax Testnet',
        hexChainId: '0x5e3',
        networkId: 1507,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Beagle Messaging Chain',
        chainName: 'BMC',
        hexChainId: '0x5eb',
        networkId: 1515,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Tenet',
        chainName: 'TENET',
        hexChainId: '0x617',
        networkId: 1559,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Catecoin Chain Mainnet',
        chainName: 'Catechain',
        hexChainId: '0x652',
        networkId: 1618,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Atheios',
        chainName: 'ATH',
        hexChainId: '0x654',
        networkId: 11235813,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Btachain',
        chainName: 'btachain',
        hexChainId: '0x679',
        networkId: 1657,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Horizen Yuma Testnet Deprecated',
        chainName: 'Yuma',
        hexChainId: '0x67e',
        networkId: 1662,
        icon: 'eon',
    },
    {
        type: 'testnet',
        name: 'Horizen Gobi Testnet',
        chainName: 'Gobi',
        hexChainId: '0x67f',
        networkId: 1663,
        icon: 'eon',
    },
    {
        type: 'mainnet',
        name: 'LUDAN Mainnet',
        chainName: 'LUDAN',
        hexChainId: '0x698',
        networkId: 1688,
        icon: 'ludan',
    },
    {
        type: 'mainnet',
        name: 'Anytype EVM Chain',
        chainName: 'ETH',
        hexChainId: '0x6a5',
        networkId: 1701,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'TBSI Mainnet',
        chainName: 'TBSI',
        hexChainId: '0x6ab',
        networkId: 1707,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'TBSI Testnet',
        chainName: 'TBSI',
        hexChainId: '0x6ac',
        networkId: 1708,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Palette Chain Mainnet',
        chainName: 'PLT',
        hexChainId: '0x6b6',
        networkId: 1718,
        icon: 'PLT',
    },
    {
        type: 'mainnet',
        name: 'PartyChain',
        chainName: 'mainnet',
        hexChainId: '0x6ed',
        networkId: 1773,
        icon: 'grams',
    },
    {
        type: 'mainnet',
        name: 'Gauss Mainnet',
        chainName: 'Gauss',
        hexChainId: '0x6f1',
        networkId: 1777,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Kerleano',
        chainName: 'CRC',
        hexChainId: '0x70c',
        networkId: 1804,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Rabbit Analog Testnet Chain',
        chainName: 'rAna',
        hexChainId: '0x70f',
        networkId: 1807,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Cube Chain Mainnet',
        chainName: 'Cube',
        hexChainId: '0x71a',
        networkId: 1818,
        icon: 'cube',
    },
    {
        type: 'testnet',
        name: 'Cube Chain Testnet',
        chainName: 'Cube',
        hexChainId: '0x71b',
        networkId: 1819,
        icon: 'cube',
    },
    {
        type: 'mainnet',
        name: 'Teslafunds',
        chainName: 'TSF',
        hexChainId: '0x740',
        networkId: 1,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'WhiteBIT Network',
        chainName: 'WBT',
        hexChainId: '0x753',
        networkId: 1875,
        icon: 'whitebit',
    },
    {
        type: 'testnet',
        name: 'Gitshock Cartenz Testnet',
        chainName: 'Gitshock Cartenz',
        hexChainId: '0x759',
        networkId: 1881,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Lightlink Phoenix Mainnet',
        chainName: 'Lightlink Phoenix Mainnet',
        hexChainId: '0x762',
        networkId: 1890,
        icon: 'lightlink',
    },
    {
        type: 'testnet',
        name: 'Lightlink Pegasus Testnet',
        chainName: 'Lightlink Pegasus Testnet',
        hexChainId: '0x763',
        networkId: 1891,
        icon: 'lightlink',
    },
    {
        type: 'mainnet',
        name: 'BON Network',
        chainName: 'BON',
        hexChainId: '0x76a',
        networkId: 1,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Bitcichain Mainnet',
        chainName: 'BITCI',
        hexChainId: '0x773',
        networkId: 1907,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Bitcichain Testnet',
        chainName: 'TBITCI',
        hexChainId: '0x774',
        networkId: 1908,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'ONUS Chain Testnet',
        chainName: 'onus',
        hexChainId: '0x799',
        networkId: 1945,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'D-Chain Mainnet',
        chainName: 'D-Chain',
        hexChainId: '0x79f',
        networkId: 1951,
        icon: 'dchain',
    },
    {
        type: 'testnet',
        name: 'Dexilla Testnet',
        chainName: 'Dexilla',
        hexChainId: '0x7a2',
        networkId: 1954,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Eleanor',
        chainName: 'MTC',
        hexChainId: '0x7af',
        networkId: 1967,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Super Smart Chain Testnet',
        chainName: 'TSCS',
        hexChainId: '0x7b1',
        networkId: 1969,
        icon: 'super',
    },
    {
        type: 'mainnet',
        name: 'Super Smart Chain Mainnet',
        chainName: 'SCS',
        hexChainId: '0x7b2',
        networkId: 1970,
        icon: 'super',
    },
    {
        type: 'testnet',
        name: 'Atelier',
        chainName: 'ALTR',
        hexChainId: '0x7b3',
        networkId: 1971,
        icon: 'atlr',
    },
    {
        type: 'mainnet',
        name: 'ONUS Chain Mainnet',
        chainName: 'onus',
        hexChainId: '0x7b7',
        networkId: 1975,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Eurus Testnet',
        chainName: 'EUN',
        hexChainId: '0x7c0',
        networkId: 1984,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'EtherGem',
        chainName: 'EGEM',
        hexChainId: '0x7c3',
        networkId: 1987,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Ekta',
        chainName: 'EKTA',
        hexChainId: '0x7ca',
        networkId: 1994,
        icon: 'ekta',
    },
    {
        type: 'testnet',
        name: 'edeXa Testnet',
        chainName: 'edeXa TestNetwork',
        hexChainId: '0x7cb',
        networkId: 1995,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Dogechain Mainnet',
        chainName: 'DC',
        hexChainId: '0x7d0',
        networkId: 2000,
        icon: 'dogechain',
    },
    {
        type: 'mainnet',
        name: 'Milkomeda C1 Mainnet',
        chainName: 'milkAda',
        hexChainId: '0x7d1',
        networkId: 2001,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Milkomeda A1 Mainnet',
        chainName: 'milkALGO',
        hexChainId: '0x7d2',
        networkId: 2002,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'CloudWalk Testnet',
        chainName: 'CloudWalk Testnet',
        hexChainId: '0x7d8',
        networkId: 2008,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'CloudWalk Mainnet',
        chainName: 'CloudWalk Mainnet',
        hexChainId: '0x7d9',
        networkId: 2009,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'MainnetZ Mainnet',
        chainName: 'NetZ',
        hexChainId: '0x7e0',
        networkId: 2016,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'PublicMint Devnet',
        chainName: 'PublicMint',
        hexChainId: '0x7e2',
        networkId: 2018,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'PublicMint Testnet',
        chainName: 'PublicMint',
        hexChainId: '0x7e3',
        networkId: 2019,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'PublicMint Mainnet',
        chainName: 'PublicMint',
        hexChainId: '0x7e4',
        networkId: 2020,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Edgeware EdgeEVM Mainnet',
        chainName: 'EDG',
        hexChainId: '0x7e5',
        networkId: 2021,
        icon: 'edgeware',
    },
    {
        type: 'testnet',
        name: 'Beresheet BereEVM Testnet',
        chainName: 'EDG',
        hexChainId: '0x7e6',
        networkId: 2022,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Taycan Testnet',
        chainName: 'Taycan',
        hexChainId: '0x7e7',
        networkId: 2023,
        icon: 'shuffle',
    },
    {
        type: 'mainnet',
        name: 'Rangers Protocol Mainnet',
        chainName: 'Rangers',
        hexChainId: '0x7e9',
        networkId: 2025,
        icon: 'rangers',
    },
    {
        type: 'mainnet',
        name: 'Centrifuge',
        chainName: 'CFG',
        hexChainId: '0x7ef',
        networkId: 2031,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Catalyst',
        chainName: 'CFG',
        hexChainId: '0x7f0',
        networkId: 2032,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Kiwi Subnet',
        chainName: 'KIWI',
        hexChainId: '0x7f5',
        networkId: 2037,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'OriginTrail Parachain',
        chainName: 'OTP',
        hexChainId: '0x7fb',
        networkId: 2043,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Shrapnel Subnet',
        chainName: 'shrapnel',
        hexChainId: '0x7fc',
        networkId: 2044,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Stratos Testnet',
        chainName: 'STOS',
        hexChainId: '0x7ff',
        networkId: 2047,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Stratos Mainnet',
        chainName: 'STOS',
        hexChainId: '0x800',
        networkId: 2048,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Quokkacoin Mainnet',
        chainName: 'Qkacoin',
        hexChainId: '0x81d',
        networkId: 2077,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Altair',
        chainName: 'AIR',
        hexChainId: '0x828',
        networkId: 2088,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Algol',
        chainName: 'algol',
        hexChainId: '0x829',
        networkId: 2089,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Ecoball Mainnet',
        chainName: 'ECO',
        hexChainId: '0x834',
        networkId: 2100,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Ecoball Testnet Espuma',
        chainName: 'ECO',
        hexChainId: '0x835',
        networkId: 2101,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Exosama Network',
        chainName: 'EXN',
        hexChainId: '0x83d',
        networkId: 2109,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Metaplayerone Mainnet',
        chainName: 'METAD',
        hexChainId: '0x84a',
        networkId: 2122,
        icon: 'metad',
    },
    {
        type: 'testnet',
        name: 'Metaplayerone Dubai Testnet',
        chainName: 'MP1 Dubai-Testnet',
        hexChainId: '0x84c',
        networkId: 2124,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Defi Oracle Meta Testnet',
        chainName: 'dfiometatest',
        hexChainId: '0x85a',
        networkId: 21,
        icon: 'defioraclemeta',
    },
    {
        type: 'mainnet',
        name: 'BOSagora Mainnet',
        chainName: 'ETH',
        hexChainId: '0x867',
        networkId: 2151,
        icon: 'agora',
    },
    {
        type: 'mainnet',
        name: 'Findora Mainnet',
        chainName: 'Findora',
        hexChainId: '0x868',
        networkId: 2152,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Findora Testnet',
        chainName: 'Testnet-anvil',
        hexChainId: '0x869',
        networkId: 2153,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Findora Forge',
        chainName: 'Testnet-forge',
        hexChainId: '0x86a',
        networkId: 2154,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Moonsama Network',
        chainName: 'MSN',
        hexChainId: '0x897',
        networkId: 2199,
        icon: 'msn',
    },
    {
        type: 'mainnet',
        name: 'Antofy Mainnet',
        chainName: 'ABN',
        hexChainId: '0x89a',
        networkId: 2202,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Bitcoin EVM',
        chainName: 'Bitcoin EVM',
        hexChainId: '0x89b',
        networkId: 2203,
        icon: 'ebtc',
    },
    {
        type: 'mainnet',
        name: 'Evanesco Mainnet',
        chainName: 'EVA',
        hexChainId: '0x8a5',
        networkId: 2213,
        icon: 'evanesco',
    },
    {
        type: 'testnet',
        name: 'Kava Testnet',
        chainName: 'KAVA',
        hexChainId: '0x8ad',
        networkId: 2221,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Kava',
        chainName: 'KAVA',
        hexChainId: '0x8ae',
        networkId: 2222,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'VChain Mainnet',
        chainName: 'VChain',
        hexChainId: '0x8af',
        networkId: 2223,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'BOMB Chain',
        chainName: 'BOMB',
        hexChainId: '0x8fc',
        networkId: 2300,
        icon: 'bomb',
    },
    {
        type: 'mainnet',
        name: 'Arevia',
        chainName: 'Arevia',
        hexChainId: '0x905',
        networkId: 2309,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'SOMA Network Testnet',
        chainName: 'SOMA',
        hexChainId: '0x913',
        networkId: 2323,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Altcoinchain',
        chainName: 'mainnet',
        hexChainId: '0x91a',
        networkId: 2330,
        icon: 'altcoinchain',
    },
    {
        type: 'mainnet',
        name: 'SOMA Network Mainnet',
        chainName: 'SOMA',
        hexChainId: '0x91c',
        networkId: 2332,
        icon: null,
    },
    {
        type: 'testnet',
        name: '(deprecated) Kroma Sepolia',
        chainName: 'ETH',
        hexChainId: '0x935',
        networkId: 2357,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Kroma Sepolia',
        chainName: 'ETH',
        hexChainId: '0x936',
        networkId: 2358,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'BOMB Chain Testnet',
        chainName: 'BOMB',
        hexChainId: '0x95f',
        networkId: 2399,
        icon: 'bomb',
    },
    {
        type: 'mainnet',
        name: 'TCG Verse Mainnet',
        chainName: 'TCG Verse',
        hexChainId: '0x960',
        networkId: 2400,
        icon: 'tcg_verse',
    },
    {
        type: 'mainnet',
        name: 'XODEX',
        chainName: 'XODEX',
        hexChainId: '0x96f',
        networkId: 10,
        icon: 'xodex',
    },
    {
        type: 'testnet',
        name: 'Unicorn Ultra Nebulas Testnet',
        chainName: 'u2u',
        hexChainId: '0x9b4',
        networkId: 2484,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Kortho Mainnet',
        chainName: 'Kortho Chain',
        hexChainId: '0x9ff',
        networkId: 2559,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'TechPay Mainnet',
        chainName: 'TPC',
        hexChainId: '0xa09',
        networkId: 2569,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'PoCRNet',
        chainName: 'CRC',
        hexChainId: '0xa2e',
        networkId: 2606,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Redlight Chain Mainnet',
        chainName: 'REDLC',
        hexChainId: '0xa33',
        networkId: 2611,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'EZChain C-Chain Mainnet',
        chainName: 'EZC',
        hexChainId: '0xa34',
        networkId: 2612,
        icon: 'ezchain',
    },
    {
        type: 'testnet',
        name: 'EZChain C-Chain Testnet',
        chainName: 'EZC',
        hexChainId: '0xa35',
        networkId: 2613,
        icon: 'ezchain',
    },
    {
        type: 'testnet',
        name: 'WhiteBIT Network Testnet',
        chainName: 'WBT',
        hexChainId: '0xa41',
        networkId: 2625,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Boba Network Goerli Testnet',
        chainName: 'ETH',
        hexChainId: '0xb48',
        networkId: 2888,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'BitYuan Mainnet',
        chainName: 'BTY',
        hexChainId: '0xbb7',
        networkId: 2999,
        icon: 'bty',
    },
    {
        type: 'mainnet',
        name: 'CENNZnet Rata',
        chainName: 'CENNZnet',
        hexChainId: '0xbb8',
        networkId: 3000,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'CENNZnet Nikau',
        chainName: 'CENNZnet',
        hexChainId: '0xbb9',
        networkId: 3001,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Canxium Mainnet',
        chainName: 'CAU',
        hexChainId: '0xbbb',
        networkId: 3003,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'PLAYA3ULL GAMES',
        chainName: '3ULL',
        hexChainId: '0xbc3',
        networkId: 3011,
        icon: 'playa3ull',
    },
    {
        type: 'mainnet',
        name: 'Orlando Chain',
        chainName: 'ORL',
        hexChainId: '0xbd7',
        networkId: 3031,
        icon: 'orl',
    },
    {
        type: 'mainnet',
        name: 'Bifrost Mainnet',
        chainName: 'BFC',
        hexChainId: '0xbfc',
        networkId: 3068,
        icon: 'bifrost',
    },
    {
        type: 'testnet',
        name: 'Filecoin - Hyperspace testnet',
        chainName: 'FIL',
        hexChainId: '0xc45',
        networkId: 3141,
        icon: 'filecoin',
    },
    {
        type: 'testnet',
        name: 'Debounce Subnet Testnet',
        chainName: 'Debounce Network',
        hexChainId: '0xcea',
        networkId: 3306,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'ZCore Testnet',
        chainName: 'Beach',
        hexChainId: '0xd03',
        networkId: 3331,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Web3Q Testnet',
        chainName: 'Web3Q',
        hexChainId: '0xd05',
        networkId: 3333,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Web3Q Galileo',
        chainName: 'Web3Q',
        hexChainId: '0xd06',
        networkId: 3334,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Paribu Net Mainnet',
        chainName: 'PRB',
        hexChainId: '0xd48',
        networkId: 3400,
        icon: 'prb',
    },
    {
        type: 'testnet',
        name: 'SecureChain Testnet',
        chainName: 'SCAI',
        hexChainId: '0xd6a',
        networkId: 3434,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Paribu Net Testnet',
        chainName: 'PRB',
        hexChainId: '0xdac',
        networkId: 3500,
        icon: 'prb',
    },
    {
        type: 'mainnet',
        name: 'JFIN Chain',
        chainName: 'JFIN',
        hexChainId: '0xdad',
        networkId: 3501,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'PandoProject Mainnet',
        chainName: 'PandoProject',
        hexChainId: '0xe11',
        networkId: 3601,
        icon: 'pando',
    },
    {
        type: 'testnet',
        name: 'PandoProject Testnet',
        chainName: 'PandoProject',
        hexChainId: '0xe12',
        networkId: 3602,
        icon: 'pando',
    },
    {
        type: 'testnet',
        name: 'Botanix Testnet',
        chainName: 'BTC',
        hexChainId: '0xe34',
        networkId: 3636,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Botanix Mainnet',
        chainName: 'BTC',
        hexChainId: '0xe35',
        networkId: 3637,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Jouleverse Mainnet',
        chainName: 'Jouleverse',
        hexChainId: '0xe52',
        networkId: 3666,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Bittex Mainnet',
        chainName: 'BTX',
        hexChainId: '0xe6a',
        networkId: 3690,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Empire Network',
        chainName: 'EMPIRE',
        hexChainId: '0xe6d',
        networkId: 3693,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'SenjePowers Testnet',
        chainName: 'SPC',
        hexChainId: '0xe72',
        networkId: 3698,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'SenjePowers Mainnet',
        chainName: 'SPC',
        hexChainId: '0xe73',
        networkId: 3699,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Crossbell',
        chainName: 'Crossbell',
        hexChainId: '0xe99',
        networkId: 3737,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'AlveyChain Mainnet',
        chainName: 'ALV',
        hexChainId: '0xed5',
        networkId: 3797,
        icon: 'alveychain',
    },
    {
        type: 'mainnet',
        name: 'DRAC Network',
        chainName: 'DRAC',
        hexChainId: '0xf48',
        networkId: 3912,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'DOS Tesnet',
        chainName: 'DOS',
        hexChainId: '0xf63',
        networkId: 3939,
        icon: 'doschain',
    },
    {
        type: 'mainnet',
        name: 'DYNO Mainnet',
        chainName: 'DYNO',
        hexChainId: '0xf7e',
        networkId: 3966,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'DYNO Testnet',
        chainName: 'DYNO',
        hexChainId: '0xf7f',
        networkId: 3967,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'YuanChain Mainnet',
        chainName: 'YCC',
        hexChainId: '0xf9f',
        networkId: 3999,
        icon: 'ycc',
    },
    {
        type: 'mainnet',
        name: 'Ozone Chain Mainnet',
        chainName: 'OZONE',
        hexChainId: '0xfa0',
        networkId: 4000,
        icon: 'ozonechain',
    },
    {
        type: 'testnet',
        name: 'Peperium Chain Testnet',
        chainName: 'PERIUM',
        hexChainId: '0xfa1',
        networkId: 4001,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Fantom Testnet',
        chainName: 'FTM',
        hexChainId: '0xfa2',
        networkId: 4002,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Bobaopera Testnet',
        chainName: 'Bobaopera Testnet',
        hexChainId: '0xfd3',
        networkId: 4051,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Nahmii 3 Mainnet',
        chainName: 'Nahmii',
        hexChainId: '0xfdd',
        networkId: 4061,
        icon: 'nahmii',
    },
    {
        type: 'testnet',
        name: 'Nahmii 3 Testnet',
        chainName: 'Nahmii',
        hexChainId: '0xfde',
        networkId: 4062,
        icon: 'nahmii',
    },
    {
        type: 'testnet',
        name: 'Fastex Chain (Bahamut) Oasis Testnet',
        chainName: 'Fastex Chain (Bahamut)',
        hexChainId: '0xffa',
        networkId: 4090,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Bitindi Testnet',
        chainName: 'BNI',
        hexChainId: '0x1000',
        networkId: 4096,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Bitindi Mainnet',
        chainName: 'BNI',
        hexChainId: '0x1003',
        networkId: 4099,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'AIOZ Network Testnet',
        chainName: 'AIOZ',
        hexChainId: '0x1006',
        networkId: 4102,
        icon: 'aioz',
    },
    {
        type: 'testnet',
        name: 'Tipboxcoin Testnet',
        chainName: 'TPBX',
        hexChainId: '0x102d',
        networkId: 4141,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'PHI Network V1',
        chainName: 'PHI V1',
        hexChainId: '0x1055',
        networkId: 4181,
        icon: 'phi',
    },
    {
        type: 'testnet',
        name: 'LUKSO Testnet',
        chainName: 'LUKSO Testnet',
        hexChainId: '0x1069',
        networkId: 4201,
        icon: 'lukso',
    },
    {
        type: 'mainnet',
        name: 'Nexi Mainnet',
        chainName: 'Nexi',
        hexChainId: '0x1092',
        networkId: 4242,
        icon: 'nexi',
    },
    {
        type: 'testnet',
        name: 'Bobafuji Testnet',
        chainName: 'Bobafuji Testnet',
        hexChainId: '0x10e8',
        networkId: 4328,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Htmlcoin Mainnet',
        chainName: 'mainnet',
        hexChainId: '0x115c',
        networkId: 4444,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Orderly Sepolia Testnet',
        chainName: 'ETH',
        hexChainId: '0x116c',
        networkId: 4460,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'IoTeX Network Mainnet',
        chainName: 'iotex.io',
        hexChainId: '0x1251',
        networkId: 4689,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'IoTeX Network Testnet',
        chainName: 'iotex.io',
        hexChainId: '0x1252',
        networkId: 4690,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'MEVerse Chain Testnet',
        chainName: 'MEVerse',
        hexChainId: '0x1297',
        networkId: 4759,
        icon: 'meverse',
    },
    {
        type: 'testnet',
        name: 'BlackFort Exchange Network Testnet',
        chainName: 'TBXN',
        hexChainId: '0x12a9',
        networkId: 4777,
        icon: 'bxn',
    },
    {
        type: 'testnet',
        name: 'Venidium Testnet',
        chainName: 'XVM',
        hexChainId: '0x1336',
        networkId: 4918,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Venidium Mainnet',
        chainName: 'XVM',
        hexChainId: '0x1337',
        networkId: 4919,
        icon: 'venidium',
    },
    {
        type: 'mainnet',
        name: 'BlackFort Exchange Network',
        chainName: 'BXN',
        hexChainId: '0x1387',
        networkId: 4999,
        icon: 'bxn',
    },
    {
        type: 'mainnet',
        name: 'Mantle',
        chainName: 'ETH',
        hexChainId: '0x1388',
        networkId: 5000,
        icon: 'mantle',
    },
    {
        type: 'testnet',
        name: 'Mantle Testnet',
        chainName: 'ETH',
        hexChainId: '0x1389',
        networkId: 5001,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Treasurenet Mainnet Alpha',
        chainName: 'Treasurenet Mainnet Alpha',
        hexChainId: '0x138a',
        networkId: 5002,
        icon: 'treasurenet',
    },
    {
        type: 'testnet',
        name: 'Treasurenet Testnet',
        chainName: 'Treasurenet Testnet',
        hexChainId: '0x138d',
        networkId: 5005,
        icon: 'treasurenet',
    },
    {
        type: 'mainnet',
        name: 'Fastex Chain (Bahamut)',
        chainName: 'Fastex Chain (Bahamut)',
        hexChainId: '0x142d',
        networkId: 5165,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'TLChain Network Mainnet',
        chainName: 'TLC',
        hexChainId: '0x1439',
        networkId: 5177,
        icon: 'tlc',
    },
    {
        type: 'mainnet',
        name: 'EraSwap Mainnet',
        chainName: 'ESN',
        hexChainId: '0x144d',
        networkId: 5197,
        icon: 'eraswap',
    },
    {
        type: 'mainnet',
        name: 'Humanode Mainnet',
        chainName: 'HMND',
        hexChainId: '0x1472',
        networkId: 5234,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Firechain Mainnet Old',
        chainName: 'FIRE',
        hexChainId: '0x14aa',
        networkId: 5290,
        icon: 'firechain',
    },
    {
        type: 'mainnet',
        name: 'Uzmi Network Mainnet',
        chainName: 'UZMI',
        hexChainId: '0x14c3',
        networkId: 5315,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Nahmii Mainnet',
        chainName: 'Nahmii',
        hexChainId: '0x15af',
        networkId: 5551,
        icon: 'nahmii',
    },
    {
        type: 'testnet',
        name: 'Nahmii Testnet',
        chainName: 'Nahmii',
        hexChainId: '0x15b1',
        networkId: 5553,
        icon: 'nahmii',
    },
    {
        type: 'mainnet',
        name: 'Chain Verse Mainnet',
        chainName: 'CVERSE',
        hexChainId: '0x15b3',
        networkId: 5555,
        icon: 'chain_verse',
    },
    {
        type: 'testnet',
        name: 'Arcturus Chain Testnet',
        chainName: 'ARCTURUS',
        hexChainId: '0x15f0',
        networkId: 5616,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Syscoin Tanenbaum Testnet',
        chainName: 'SYS',
        hexChainId: '0x1644',
        networkId: 5700,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Hika Network Testnet',
        chainName: 'HIK',
        hexChainId: '0x1661',
        networkId: 5729,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'SatoshiChain Testnet',
        chainName: 'SATS',
        hexChainId: '0x167e',
        networkId: 5758,
        icon: 'satoshichain',
    },
    {
        type: 'testnet',
        name: 'Ganache',
        chainName: 'ETH',
        hexChainId: '0x1691',
        networkId: 5777,
        icon: 'ganache',
    },
    {
        type: 'testnet',
        name: 'Ontology Testnet',
        chainName: 'Ontology',
        hexChainId: '0x16db',
        networkId: 5851,
        icon: 'ontology',
    },
    {
        type: 'mainnet',
        name: 'Wegochain Rubidium Mainnet',
        chainName: 'RBD',
        hexChainId: '0x16ed',
        networkId: 5869,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Tres Testnet',
        chainName: 'TresLeches',
        hexChainId: '0x17b1',
        networkId: 6065,
        icon: 'tresleches',
    },
    {
        type: 'mainnet',
        name: 'Tres Mainnet',
        chainName: 'TresLeches',
        hexChainId: '0x17b2',
        networkId: 6066,
        icon: 'tresleches',
    },
    {
        type: 'testnet',
        name: 'Cascadia Testnet',
        chainName: 'Cascadia',
        hexChainId: '0x17d6',
        networkId: 6102,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'UPTN Testnet',
        chainName: 'UPTN',
        hexChainId: '0x17e6',
        networkId: 6118,
        icon: 'uptn',
    },
    {
        type: 'mainnet',
        name: 'UPTN',
        chainName: 'UPTN',
        hexChainId: '0x17e7',
        networkId: 6119,
        icon: 'uptn',
    },
    {
        type: 'mainnet',
        name: 'Peerpay',
        chainName: 'P2P',
        hexChainId: '0x1966',
        networkId: 6502,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Scolcoin WeiChain Testnet',
        chainName: 'SCOLWEI-testnet',
        hexChainId: '0x1998',
        networkId: 6552,
        icon: 'scolcoin',
    },
    {
        type: 'testnet',
        name: 'Fox Testnet Network',
        chainName: 'FOX',
        hexChainId: '0x19a5',
        networkId: 6565,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Pixie Chain Mainnet',
        chainName: 'PixieChain',
        hexChainId: '0x19e2',
        networkId: 6626,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'IRIShub',
        chainName: 'IRIShub',
        hexChainId: '0x1a20',
        networkId: 6688,
        icon: 'irishub',
    },
    {
        type: 'mainnet',
        name: 'Gold Smart Chain Mainnet',
        chainName: 'STAND',
        hexChainId: '0x1a85',
        networkId: 6789,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Tomb Chain Mainnet',
        chainName: 'Tomb Chain',
        hexChainId: '0x1b39',
        networkId: 6969,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'PolySmartChain',
        chainName: 'PSC',
        hexChainId: '0x1b57',
        networkId: 6999,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'ZetaChain Mainnet',
        chainName: 'ZetaChain',
        hexChainId: '0x1b58',
        networkId: 7000,
        icon: 'zetachain',
    },
    {
        type: 'testnet',
        name: 'ZetaChain Athens 3 Testnet',
        chainName: 'ZetaChain',
        hexChainId: '0x1b59',
        networkId: 7001,
        icon: 'zetachain',
    },
    {
        type: 'mainnet',
        name: 'Ella the heart',
        chainName: 'ella',
        hexChainId: '0x1b73',
        networkId: 7027,
        icon: 'ella',
    },
    {
        type: 'mainnet',
        name: 'Planq Mainnet',
        chainName: 'Planq',
        hexChainId: '0x1b9e',
        networkId: 7070,
        icon: 'planq',
    },
    {
        type: 'mainnet',
        name: 'Bitrock Mainnet',
        chainName: 'Bitrock',
        hexChainId: '0x1c03',
        networkId: 7171,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'KLYNTAR',
        chainName: 'KLY',
        hexChainId: '0x1ca3',
        networkId: 7331,
        icon: 'klyntar',
    },
    {
        type: 'mainnet',
        name: 'Horizen EON',
        chainName: 'EON',
        hexChainId: '0x1ca4',
        networkId: 7332,
        icon: 'eon',
    },
    {
        type: 'mainnet',
        name: 'Shyft Mainnet',
        chainName: 'SHYFT',
        hexChainId: '0x1cad',
        networkId: 7341,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Raba Network Mainnet',
        chainName: 'Raba',
        hexChainId: '0x1d3c',
        networkId: 7484,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'MEVerse Chain Mainnet',
        chainName: 'MEVerse',
        hexChainId: '0x1d5e',
        networkId: 7518,
        icon: 'meverse',
    },
    {
        type: 'testnet',
        name: 'ADIL Testnet',
        chainName: 'ADIL',
        hexChainId: '0x1d97',
        networkId: 7575,
        icon: 'adil',
    },
    {
        type: 'mainnet',
        name: 'Adil Chain V2 Mainnet',
        chainName: 'ADIL',
        hexChainId: '0x1d98',
        networkId: 7576,
        icon: 'adil',
    },
    {
        type: 'mainnet',
        name: 'The Root Network - Mainnet',
        chainName: 'TRN',
        hexChainId: '0x1df4',
        networkId: 7668,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'The Root Network - Porcini Testnet',
        chainName: 'TRN',
        hexChainId: '0x1df8',
        networkId: 7672,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Canto',
        chainName: 'Canto',
        hexChainId: '0x1e14',
        networkId: 7700,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Canto Tesnet',
        chainName: 'Canto',
        hexChainId: '0x1e15',
        networkId: 7701,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Bitrock Testnet',
        chainName: 'Bitrock',
        hexChainId: '0x1e5b',
        networkId: 7771,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Rise of the Warbots Testnet',
        chainName: 'nmactest',
        hexChainId: '0x1e61',
        networkId: 7777,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Hazlor Testnet',
        chainName: 'SCAS',
        hexChainId: '0x1ec6',
        networkId: 7878,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'ARDENIUM Athena',
        chainName: 'ATHENA',
        hexChainId: '0x1ed7',
        networkId: 7895,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'DOS Chain',
        chainName: 'DOS',
        hexChainId: '0x1f2b',
        networkId: 7979,
        icon: 'doschain',
    },
    {
        type: 'mainnet',
        name: 'Teleport',
        chainName: 'Teleport',
        hexChainId: '0x1f40',
        networkId: 8000,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Teleport Testnet',
        chainName: 'Teleport',
        hexChainId: '0x1f41',
        networkId: 8001,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'MDGL Testnet',
        chainName: 'MDGL',
        hexChainId: '0x1f5d',
        networkId: 8029,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Shardeum Liberty 1.X',
        chainName: 'Shardeum',
        hexChainId: '0x1f90',
        networkId: 8080,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Shardeum Liberty 2.X',
        chainName: 'Shardeum',
        hexChainId: '0x1f91',
        networkId: 8081,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Shardeum Sphinx 1.X',
        chainName: 'Shardeum',
        hexChainId: '0x1f92',
        networkId: 8082,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'BitEth',
        chainName: 'BTE',
        hexChainId: '0x1f96',
        networkId: 8086,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'StreamuX Blockchain',
        chainName: 'StreamuX',
        hexChainId: '0x1fa2',
        networkId: 8098,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Qitmeer Network Testnet',
        chainName: 'MEER',
        hexChainId: '0x1fc3',
        networkId: 8131,
        icon: 'meer',
    },
    {
        type: 'mainnet',
        name: 'Qitmeer Network Mixnet',
        chainName: 'MEER',
        hexChainId: '0x1fc4',
        networkId: 8132,
        icon: 'meer',
    },
    {
        type: 'mainnet',
        name: 'Qitmeer Network Privnet',
        chainName: 'MEER',
        hexChainId: '0x1fc5',
        networkId: 8133,
        icon: 'meer',
    },
    {
        type: 'mainnet',
        name: 'Amana',
        chainName: 'MEER',
        hexChainId: '0x1fc6',
        networkId: 8134,
        icon: 'meer',
    },
    {
        type: 'mainnet',
        name: 'Flana',
        chainName: 'MEER',
        hexChainId: '0x1fc7',
        networkId: 8135,
        icon: 'meer',
    },
    {
        type: 'mainnet',
        name: 'Mizana',
        chainName: 'MEER',
        hexChainId: '0x1fc8',
        networkId: 8136,
        icon: 'meer',
    },
    {
        type: 'testnet',
        name: 'BeOne Chain Testnet',
        chainName: 'BOC',
        hexChainId: '0x1ff5',
        networkId: 8181,
        icon: 'beonechain',
    },
    {
        type: 'mainnet',
        name: 'Klaytn Mainnet Cypress',
        chainName: 'KLAY',
        hexChainId: '0x2019',
        networkId: 8217,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Blockton Blockchain',
        chainName: 'Blockton Blockchain',
        hexChainId: '0x2050',
        networkId: 8272,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'KorthoTest',
        chainName: 'Kortho',
        hexChainId: '0x205d',
        networkId: 8285,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Dracones Financial Services',
        chainName: 'FUCK',
        hexChainId: '0x20c3',
        networkId: 8387,
        icon: 'dracones',
    },
    {
        type: 'mainnet',
        name: 'Base',
        chainName: 'ETH',
        hexChainId: '0x2105',
        networkId: 8453,
        icon: 'base',
    },
    {
        type: 'mainnet',
        name: 'Toki Network',
        chainName: 'TOKI',
        hexChainId: '0x21ce',
        networkId: 8654,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Toki Testnet',
        chainName: 'TOKI',
        hexChainId: '0x21cf',
        networkId: 8655,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'TOOL Global Mainnet',
        chainName: 'OLO',
        hexChainId: '0x2213',
        networkId: 8723,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'TOOL Global Testnet',
        chainName: 'OLO',
        hexChainId: '0x2214',
        networkId: 8724,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Alph Network',
        chainName: 'ALPH',
        hexChainId: '0x2222',
        networkId: 8738,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'TMY Chain',
        chainName: 'TMY',
        hexChainId: '0x2240',
        networkId: 8768,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'MARO Blockchain Mainnet',
        chainName: 'MARO Blockchain',
        hexChainId: '0x2290',
        networkId: 8848,
        icon: 'MARO',
    },
    {
        type: 'mainnet',
        name: 'Unique',
        chainName: 'UNQ',
        hexChainId: '0x22b0',
        networkId: 8880,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Quartz by Unique',
        chainName: 'UNQ',
        hexChainId: '0x22b1',
        networkId: 8881,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Opal testnet by Unique',
        chainName: 'UNQ',
        hexChainId: '0x22b2',
        networkId: 8882,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Sapphire by Unique',
        chainName: 'UNQ',
        hexChainId: '0x22b3',
        networkId: 8883,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'XANAChain',
        chainName: 'XANAChain',
        hexChainId: '0x22b8',
        networkId: 8888,
        icon: 'xeta',
    },
    {
        type: 'mainnet',
        name: 'Vyvo Smart Chain',
        chainName: 'VSC',
        hexChainId: '0x22b9',
        networkId: 8889,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Mammoth Mainnet',
        chainName: 'MMT',
        hexChainId: '0x22c2',
        networkId: 8898,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'JIBCHAIN L1',
        chainName: 'JBC',
        hexChainId: '0x22c3',
        networkId: 8899,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Giant Mammoth Mainnet',
        chainName: 'GMMT',
        hexChainId: '0x231d',
        networkId: 8989,
        icon: 'gmmt',
    },
    {
        type: 'mainnet',
        name: 'bloxberg',
        chainName: 'bloxberg',
        hexChainId: '0x2323',
        networkId: 8995,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Evmos Testnet',
        chainName: 'Evmos',
        hexChainId: '0x2328',
        networkId: 9000,
        icon: 'evmos',
    },
    {
        type: 'mainnet',
        name: 'Evmos',
        chainName: 'Evmos',
        hexChainId: '0x2329',
        networkId: 9001,
        icon: 'evmos',
    },
    {
        type: 'mainnet',
        name: 'BerylBit Mainnet',
        chainName: 'BRB',
        hexChainId: '0x2334',
        networkId: 9012,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Genesis Coin',
        chainName: 'Genesis',
        hexChainId: '0x238c',
        networkId: 9100,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Rinia Testnet Old',
        chainName: 'FIRE',
        hexChainId: '0x23d2',
        networkId: 9170,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Codefin Mainnet',
        chainName: 'COF',
        hexChainId: '0x2407',
        networkId: 9223,
        icon: 'codefin',
    },
    {
        type: 'testnet',
        name: 'Dogcoin Testnet',
        chainName: 'DOGS',
        hexChainId: '0x247b',
        networkId: 9339,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Rangers Protocol Testnet Robin',
        chainName: 'Rangers',
        hexChainId: '0x2537',
        networkId: 9527,
        icon: 'rangers',
    },
    {
        type: 'testnet',
        name: 'QEasyWeb3 Testnet',
        chainName: 'QET',
        hexChainId: '0x2538',
        networkId: 9528,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Neonlink Testnet',
        chainName: 'Neonlink',
        hexChainId: '0x2557',
        networkId: 9559,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Oort MainnetDev',
        chainName: 'MainnetDev',
        hexChainId: '0x25e4',
        networkId: 9700,
        icon: 'ccn',
    },
    {
        type: 'testnet',
        name: 'Boba BNB Testnet',
        chainName: 'Boba BNB Testnet',
        hexChainId: '0x2600',
        networkId: 9728,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'MainnetZ Testnet',
        chainName: 'NetZ',
        hexChainId: '0x2628',
        networkId: 9768,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'PepeNetwork Mainnet',
        chainName: 'PepeNetwork',
        hexChainId: '0x2633',
        networkId: 9779,
        icon: 'pepenetwork',
    },
    {
        type: 'mainnet',
        name: 'Carbon EVM',
        chainName: 'Carbon',
        hexChainId: '0x263e',
        networkId: 9790,
        icon: 'carbon',
    },
    {
        type: 'testnet',
        name: 'Carbon EVM Testnet',
        chainName: 'Carbon',
        hexChainId: '0x2640',
        networkId: 9792,
        icon: 'carbon',
    },
    {
        type: 'testnet',
        name: 'IMPERIUM TESTNET',
        chainName: 'tIMP',
        hexChainId: '0x265a',
        networkId: 9818,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'IMPERIUM MAINNET',
        chainName: 'IMP',
        hexChainId: '0x265b',
        networkId: 9819,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Mind Smart Chain Testnet',
        chainName: 'tMIND',
        hexChainId: '0x26f9',
        networkId: 9977,
        icon: 'mindchain',
    },
    {
        type: 'mainnet',
        name: 'Mind Smart Chain Mainnet',
        chainName: 'MIND',
        hexChainId: '0x270c',
        networkId: 9996,
        icon: 'mindchain',
    },
    {
        type: 'testnet',
        name: 'AltLayer Testnet',
        chainName: 'ETH',
        hexChainId: '0x270d',
        networkId: 9997,
        icon: 'altlayer',
    },
    {
        type: 'testnet',
        name: 'myOwn Testnet',
        chainName: 'myOwn',
        hexChainId: '0x270f',
        networkId: 9999,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Smart Bitcoin Cash',
        chainName: 'smartBCH',
        hexChainId: '0x2710',
        networkId: 10000,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Smart Bitcoin Cash Testnet',
        chainName: 'smartBCHTest',
        hexChainId: '0x2711',
        networkId: 10001,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Gon Chain',
        chainName: 'GonChain',
        hexChainId: '0x2728',
        networkId: 10024,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Japan Open Chain Testnet',
        chainName: 'JOCT',
        hexChainId: '0x2761',
        networkId: 10081,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'SJATSH',
        chainName: 'ETH',
        hexChainId: '0x2766',
        networkId: 10086,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Blockchain Genesis Mainnet',
        chainName: 'GEN',
        hexChainId: '0x2775',
        networkId: 10101,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Gnosis Chiado Testnet',
        chainName: 'GNO',
        hexChainId: '0x27d8',
        networkId: 10200,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'MaxxChain Mainnet',
        chainName: 'MaxxChain',
        hexChainId: '0x27d9',
        networkId: 10201,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Arthera Testnet',
        chainName: 'AA',
        hexChainId: '0x2803',
        networkId: 10243,
        icon: null,
    },
    {
        type: 'mainnet',
        name: '0XTade',
        chainName: '0XTade Chain',
        hexChainId: '0x2808',
        networkId: 10248,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Numbers Mainnet',
        chainName: 'NUM',
        hexChainId: '0x290b',
        networkId: 10507,
        icon: 'num',
    },
    {
        type: 'testnet',
        name: 'Numbers Testnet',
        chainName: 'NUM',
        hexChainId: '0x290c',
        networkId: 10508,
        icon: 'num',
    },
    {
        type: 'mainnet',
        name: 'CryptoCoinPay',
        chainName: 'CCP',
        hexChainId: '0x2a47',
        networkId: 10823,
        icon: 'ccp',
    },
    {
        type: 'mainnet',
        name: 'Quadrans Blockchain',
        chainName: 'QDC',
        hexChainId: '0x2ac2',
        networkId: 10946,
        icon: 'quadrans',
    },
    {
        type: 'testnet',
        name: 'Quadrans Blockchain Testnet',
        chainName: 'tQDC',
        hexChainId: '0x2ac3',
        networkId: 10947,
        icon: 'quadrans',
    },
    {
        type: 'mainnet',
        name: 'Astra',
        chainName: 'Astra',
        hexChainId: '0x2b66',
        networkId: 11110,
        icon: 'astra',
    },
    {
        type: 'mainnet',
        name: 'WAGMI',
        chainName: 'WAGMI',
        hexChainId: '0x2b67',
        networkId: 11111,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Astra Testnet',
        chainName: 'Astra',
        hexChainId: '0x2b6b',
        networkId: 11115,
        icon: 'astra',
    },
    {
        type: 'mainnet',
        name: 'HashBit Mainnet',
        chainName: 'HBIT',
        hexChainId: '0x2b6f',
        networkId: 11119,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Haqq Network',
        chainName: 'Haqq',
        hexChainId: '0x2be3',
        networkId: 11235,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Shyft Testnet',
        chainName: 'SHYFTT',
        hexChainId: '0x2cad',
        networkId: 11437,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Sardis Testnet',
        chainName: 'SRDX',
        hexChainId: '0x2d5c',
        networkId: 11612,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'SanR Chain',
        chainName: 'SanRChain',
        hexChainId: '0x2e70',
        networkId: 11888,
        icon: 'sanrchain',
    },
    {
        type: 'mainnet',
        name: 'SatoshiChain Mainnet',
        chainName: 'SATS',
        hexChainId: '0x2ee9',
        networkId: 12009,
        icon: 'satoshichain',
    },
    {
        type: 'testnet',
        name: 'Singularity ZERO Testnet',
        chainName: 'ZERO',
        hexChainId: '0x2f13',
        networkId: 12051,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Singularity ZERO Mainnet',
        chainName: 'ZERO',
        hexChainId: '0x2f14',
        networkId: 12052,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'BRC Chain Mainnet',
        chainName: 'BRC',
        hexChainId: '0x2f5b',
        networkId: 12123,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Fibonacci Mainnet',
        chainName: 'FIBO',
        hexChainId: '0x3012',
        networkId: 1230,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'BLG Testnet',
        chainName: 'BLG',
        hexChainId: '0x3021',
        networkId: 12321,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Step Testnet',
        chainName: 'STEP',
        hexChainId: '0x3039',
        networkId: 12345,
        icon: 'step',
    },
    {
        type: 'testnet',
        name: 'Rikeza Network Testnet',
        chainName: 'Rikeza',
        hexChainId: '0x31ab',
        networkId: 12715,
        icon: 'rikeza',
    },
    {
        type: 'mainnet',
        name: 'SPS',
        chainName: 'SPS',
        hexChainId: '0x32c8',
        networkId: 13000,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Credit Smart Chain',
        chainName: 'CREDIT',
        hexChainId: '0x33fc',
        networkId: 13308,
        icon: 'credit',
    },
    {
        type: 'testnet',
        name: 'Beam Testnet',
        chainName: 'BEAM',
        hexChainId: '0x3419',
        networkId: 13337,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Phoenix Mainnet',
        chainName: 'Phoenix',
        hexChainId: '0x3445',
        networkId: 13381,
        icon: 'phoenix',
    },
    {
        type: 'mainnet',
        name: 'Susono',
        chainName: 'SUS',
        hexChainId: '0x35f4',
        networkId: 13812,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'SPS Testnet',
        chainName: 'SPS-Testnet',
        hexChainId: '0x36b0',
        networkId: 14000,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Humanode Testnet 5 Israfel',
        chainName: 'Humanode Testnet 5',
        hexChainId: '0x3a05',
        networkId: 14853,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'LoopNetwork Mainnet',
        chainName: 'LoopNetwork',
        hexChainId: '0x3cbf',
        networkId: 15551,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Trust EVM Testnet',
        chainName: 'Trust EVM Testnet',
        hexChainId: '0x3cc3',
        networkId: 15555,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'EOS EVM Network Testnet',
        chainName: 'EOS',
        hexChainId: '0x3cc5',
        networkId: 15557,
        icon: 'eos',
    },
    {
        type: 'mainnet',
        name: 'MetaDot Mainnet',
        chainName: 'MTT',
        hexChainId: '0x3e80',
        networkId: 16000,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'MetaDot Testnet',
        chainName: 'MTTTest',
        hexChainId: '0x3e81',
        networkId: 16001,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Genesys Mainnet',
        chainName: 'Genesys',
        hexChainId: '0x407b',
        networkId: 16507,
        icon: 'genesys',
    },
    {
        type: 'testnet',
        name: 'IRIShub Testnet',
        chainName: 'IRIShub',
        hexChainId: '0x4130',
        networkId: 16688,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'AirDAO Mainnet',
        chainName: 'ambnet',
        hexChainId: '0x414e',
        networkId: 16718,
        icon: 'airdao',
    },
    {
        type: 'testnet',
        name: 'IVAR Chain Testnet',
        chainName: 'IVAR',
        hexChainId: '0x41f8',
        networkId: 16888,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Holesky',
        chainName: 'ETH',
        hexChainId: '0x4268',
        networkId: 17000,
        icon: 'ethereum',
    },
    {
        type: 'testnet',
        name: 'Palette Chain Testnet',
        chainName: 'PLT',
        hexChainId: '0x431c',
        networkId: 17180,
        icon: 'PLT',
    },
    {
        type: 'mainnet',
        name: 'EOS EVM Network',
        chainName: 'EOS',
        hexChainId: '0x4571',
        networkId: 17777,
        icon: 'eos',
    },
    {
        type: 'testnet',
        name: 'Frontier of Dreams Testnet',
        chainName: 'Game Network',
        hexChainId: '0x4650',
        networkId: 18000,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Smart Trade Networks',
        chainName: 'Smart Trade Networks',
        hexChainId: '0x46ca',
        networkId: 18122,
        icon: 'stn',
    },
    {
        type: 'mainnet',
        name: 'Proof Of Memes',
        chainName: 'POM',
        hexChainId: '0x46ef',
        networkId: 18159,
        icon: 'pom',
    },
    {
        type: 'mainnet',
        name: 'MXC zkEVM Mainnet',
        chainName: 'MXC zkEVM',
        hexChainId: '0x48fe',
        networkId: 18686,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'HOME Verse Mainnet',
        chainName: 'HOME Verse',
        hexChainId: '0x4a43',
        networkId: 19011,
        icon: 'home_verse',
    },
    {
        type: 'mainnet',
        name: 'BTCIX Network',
        chainName: 'BTCIX',
        hexChainId: '0x4d85',
        networkId: 19845,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Camelark Mainnet',
        chainName: 'ETHW',
        hexChainId: '0x4e21',
        networkId: 20001,
        icon: 'camelark',
    },
    {
        type: 'testnet',
        name: 'Callisto Testnet',
        chainName: 'CLO',
        hexChainId: '0x50f9',
        networkId: 79,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'P12 Chain',
        chainName: 'P12',
        hexChainId: '0x5100',
        networkId: 20736,
        icon: 'p12',
    },
    {
        type: 'mainnet',
        name: 'CENNZnet Azalea',
        chainName: 'CENNZnet',
        hexChainId: '0x5359',
        networkId: 21337,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'omChain Mainnet',
        chainName: 'OML',
        hexChainId: '0x5538',
        networkId: 21816,
        icon: 'omlira',
    },
    {
        type: 'mainnet',
        name: 'Taycan',
        chainName: 'Taycan',
        hexChainId: '0x5607',
        networkId: 22023,
        icon: 'shuffle',
    },
    {
        type: 'testnet',
        name: 'AirDAO Testnet',
        chainName: 'ambnet-test',
        hexChainId: '0x5618',
        networkId: 22040,
        icon: 'airdao',
    },
    {
        type: 'mainnet',
        name: 'MAP Mainnet',
        chainName: 'MAP',
        hexChainId: '0x58f8',
        networkId: 22776,
        icon: 'map',
    },
    {
        type: 'testnet',
        name: 'Antofy Testnet',
        chainName: 'ABN',
        hexChainId: '0x59de',
        networkId: 23006,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Opside Testnet',
        chainName: 'Opside',
        hexChainId: '0x5a4e',
        networkId: 23118,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Oasis Sapphire',
        chainName: 'Sapphire',
        hexChainId: '0x5afe',
        networkId: 23294,
        icon: 'oasis',
    },
    {
        type: 'testnet',
        name: 'Oasis Sapphire Testnet',
        chainName: 'Sapphire',
        hexChainId: '0x5aff',
        networkId: 23295,
        icon: 'oasis',
    },
    {
        type: 'mainnet',
        name: 'Webchain',
        chainName: 'WEB',
        hexChainId: '0x5fa4',
        networkId: 37129,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'MintMe.com Coin',
        chainName: 'MINTME',
        hexChainId: '0x609e',
        networkId: 37480,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Hammer Chain Mainnet',
        chainName: 'HammerChain',
        hexChainId: '0x6520',
        networkId: 25888,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Bitkub Chain Testnet',
        chainName: 'BKC',
        hexChainId: '0x6545',
        networkId: 25925,
        icon: 'bkc',
    },
    {
        type: 'testnet',
        name: 'Ferrum Testnet',
        chainName: 'tFRM',
        hexChainId: '0x65aa',
        networkId: 26026,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Hertz Network Mainnet',
        chainName: 'HTZ',
        hexChainId: '0x67e8',
        networkId: 26600,
        icon: 'hertz-network',
    },
    {
        type: 'mainnet',
        name: 'OasisChain Mainnet',
        chainName: 'OasisChain',
        hexChainId: '0x68ef',
        networkId: 26863,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Optimism Bedrock (Goerli Alpha Testnet)',
        chainName: 'ETH',
        hexChainId: '0x6f70',
        networkId: 28528,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Piece testnet',
        chainName: 'PieceNetwork',
        hexChainId: '0x7573',
        networkId: 30067,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Ethersocial Network',
        chainName: 'ESN',
        hexChainId: '0x797e',
        networkId: 1,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'CloudTx Mainnet',
        chainName: 'CLD',
        hexChainId: '0x79f7',
        networkId: 31223,
        icon: 'cloudtx',
    },
    {
        type: 'testnet',
        name: 'CloudTx Testnet',
        chainName: 'CloudTx',
        hexChainId: '0x79f8',
        networkId: 31224,
        icon: 'cloudtx',
    },
    {
        type: 'testnet',
        name: 'GoChain Testnet',
        chainName: 'GO',
        hexChainId: '0x7a69',
        networkId: 31337,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Filecoin - Wallaby testnet',
        chainName: 'FIL',
        hexChainId: '0x7ab7',
        networkId: 31415,
        icon: 'filecoin',
    },
    {
        type: 'mainnet',
        name: 'Bitgert Mainnet',
        chainName: 'Brise',
        hexChainId: '0x7f08',
        networkId: 32520,
        icon: 'brise',
    },
    {
        type: 'mainnet',
        name: 'Fusion Mainnet',
        chainName: 'FSN',
        hexChainId: '0x7f93',
        networkId: 32659,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Zilliqa EVM',
        chainName: 'ZIL',
        hexChainId: '0x8001',
        networkId: 32769,
        icon: 'zilliqa',
    },
    {
        type: 'testnet',
        name: 'Zilliqa EVM Testnet',
        chainName: 'ZIL',
        hexChainId: '0x814d',
        networkId: 33101,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Aves Mainnet',
        chainName: 'AVS',
        hexChainId: '0x8235',
        networkId: 33333,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'J2O Taro',
        chainName: 'TARO',
        hexChainId: '0x88c3',
        networkId: 35011,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Q Mainnet',
        chainName: 'Q',
        hexChainId: '0x8a71',
        networkId: 35441,
        icon: 'q',
    },
    {
        type: 'testnet',
        name: 'Q Testnet',
        chainName: 'Q',
        hexChainId: '0x8a73',
        networkId: 35443,
        icon: 'q',
    },
    {
        type: 'mainnet',
        name: 'Energi Mainnet',
        chainName: 'NRG',
        hexChainId: '0x9b75',
        networkId: 39797,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'OHO Mainnet',
        chainName: 'OHO',
        hexChainId: '0x9b87',
        networkId: 39815,
        icon: 'oho',
    },
    {
        type: 'mainnet',
        name: 'Opulent-X BETA',
        chainName: 'Opulent-X',
        hexChainId: '0xa21c',
        networkId: 41500,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'pegglecoin',
        chainName: '42069',
        hexChainId: '0xa455',
        networkId: 42069,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Arbitrum One',
        chainName: 'ETH',
        hexChainId: '0xa4b1',
        networkId: 42161,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Arbitrum Nova',
        chainName: 'ETH',
        hexChainId: '0xa4ba',
        networkId: 42170,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Celo Mainnet',
        chainName: 'CELO',
        hexChainId: '0xa4ec',
        networkId: 42220,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Oasis Emerald Testnet',
        chainName: 'Emerald',
        hexChainId: '0xa515',
        networkId: 42261,
        icon: 'oasis',
    },
    {
        type: 'mainnet',
        name: 'Oasis Emerald',
        chainName: 'Emerald',
        hexChainId: '0xa516',
        networkId: 42262,
        icon: 'oasis',
    },
    {
        type: 'testnet',
        name: 'Kinto Testnet',
        chainName: 'ETH',
        hexChainId: '0xa788',
        networkId: 42888,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Athereum',
        chainName: 'ATH',
        hexChainId: '0xa866',
        networkId: 43110,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Avalanche Fuji Testnet',
        chainName: 'AVAX',
        hexChainId: '0xa869',
        networkId: 1,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Avalanche C-Chain',
        chainName: 'AVAX',
        hexChainId: '0xa86a',
        networkId: 43114,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Boba Avax',
        chainName: 'Boba Avax',
        hexChainId: '0xa918',
        networkId: 43288,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Frenchain',
        chainName: 'fren',
        hexChainId: '0xad9c',
        networkId: 44444,
        icon: 'fren',
    },
    {
        type: 'testnet',
        name: 'Celo Alfajores Testnet',
        chainName: 'CELO',
        hexChainId: '0xaef3',
        networkId: 44787,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Autobahn Network',
        chainName: 'TXL',
        hexChainId: '0xafc8',
        networkId: 45000,
        icon: 'autobahn',
    },
    {
        type: 'testnet',
        name: 'Fusion Testnet',
        chainName: 'FSN',
        hexChainId: '0xb660',
        networkId: 46688,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'REI Network',
        chainName: 'REI',
        hexChainId: '0xbabd',
        networkId: 47805,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Wireshape Floripa Testnet',
        chainName: 'Wireshape',
        hexChainId: '0xbf99',
        networkId: 49049,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Bifrost Testnet',
        chainName: 'BFC',
        hexChainId: '0xbfc0',
        networkId: 49088,
        icon: 'bifrost',
    },
    {
        type: 'testnet',
        name: 'Energi Testnet',
        chainName: 'NRG',
        hexChainId: '0xc285',
        networkId: 49797,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Liveplex OracleEVM',
        chainName: 'Liveplex OracleEVM Network',
        hexChainId: '0xc351',
        networkId: 50001,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'GTON Testnet',
        chainName: 'GTON Testnet',
        hexChainId: '0xc365',
        networkId: 50021,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Opside Testnet Pre-Alpha',
        chainName: 'ETH',
        hexChainId: '0xc7ea',
        networkId: 51178,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Sardis Mainnet',
        chainName: 'SRDX',
        hexChainId: '0xca00',
        networkId: 51712,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'DFK Chain',
        chainName: 'DFK',
        hexChainId: '0xd2af',
        networkId: 53935,
        icon: 'dfk',
    },
    {
        type: 'testnet',
        name: 'Haqq Chain Testnet',
        chainName: 'TestEdge2',
        hexChainId: '0xd3c3',
        networkId: 54211,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Toronet Testnet',
        chainName: 'Toronet',
        hexChainId: '0xd431',
        networkId: 54321,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Titan',
        chainName: 'ETH',
        hexChainId: '0xd6dc',
        networkId: 55004,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'REI Chain Mainnet',
        chainName: 'REI',
        hexChainId: '0xd903',
        networkId: 55555,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'REI Chain Testnet',
        chainName: 'REI',
        hexChainId: '0xd904',
        networkId: 55556,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Boba BNB Mainnet',
        chainName: 'Boba BNB Mainnet',
        hexChainId: '0xdbe0',
        networkId: 56288,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Rollux Testnet',
        chainName: 'SYS',
        hexChainId: '0xdea8',
        networkId: 57000,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Sepolia PGN (Public Goods Network)',
        chainName: 'ETH',
        hexChainId: '0xe298',
        networkId: 58008,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Linea Testnet',
        chainName: 'ETH',
        hexChainId: '0xe704',
        networkId: 59140,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Linea',
        chainName: 'ETH',
        hexChainId: '0xe708',
        networkId: 59144,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Thinkium Testnet Chain 0',
        chainName: 'Thinkium',
        hexChainId: '0xea60',
        networkId: 60000,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Thinkium Testnet Chain 1',
        chainName: 'Thinkium',
        hexChainId: '0xea61',
        networkId: 60001,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Thinkium Testnet Chain 2',
        chainName: 'Thinkium',
        hexChainId: '0xea62',
        networkId: 60002,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Thinkium Testnet Chain 103',
        chainName: 'Thinkium',
        hexChainId: '0xeac7',
        networkId: 60103,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'AxelChain Dev-Net',
        chainName: 'AXEL',
        hexChainId: '0xf168',
        networkId: 61800,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Etica Mainnet',
        chainName: 'Etica Protocol (ETI/EGAZ)',
        hexChainId: '0xf16b',
        networkId: 61803,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'DoKEN Super Chain Mainnet',
        chainName: 'DoKEN Super Chain',
        hexChainId: '0xf1dc',
        networkId: 61916,
        icon: 'doken',
    },
    {
        type: 'testnet',
        name: 'Celo Baklava Testnet',
        chainName: 'CELO',
        hexChainId: '0xf370',
        networkId: 62320,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'MultiVAC Mainnet',
        chainName: 'MultiVAC',
        hexChainId: '0xf49d',
        networkId: 62621,
        icon: 'multivac',
    },
    {
        type: 'mainnet',
        name: 'eCredits Mainnet',
        chainName: 'ECS',
        hexChainId: '0xf618',
        networkId: 63000,
        icon: 'ecredits',
    },
    {
        type: 'testnet',
        name: 'eCredits Testnet',
        chainName: 'ECS',
        hexChainId: '0xf619',
        networkId: 63001,
        icon: 'ecredits',
    },
    {
        type: 'mainnet',
        name: 'Scolcoin Mainnet',
        chainName: 'SCOLWEI',
        hexChainId: '0xffaa',
        networkId: 65450,
        icon: 'scolcoin',
    },
    {
        type: 'mainnet',
        name: 'SiriusNet',
        chainName: 'SIN',
        hexChainId: '0x1073e',
        networkId: 67390,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Cosmic Chain',
        chainName: 'COSMIC',
        hexChainId: '0x10804',
        networkId: 3344,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Condrieu',
        chainName: 'ETH',
        hexChainId: '0x10f2c',
        networkId: 69420,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Thinkium Mainnet Chain 0',
        chainName: 'Thinkium',
        hexChainId: '0x11170',
        networkId: 70000,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Thinkium Mainnet Chain 1',
        chainName: 'Thinkium',
        hexChainId: '0x11171',
        networkId: 70001,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Thinkium Mainnet Chain 2',
        chainName: 'Thinkium',
        hexChainId: '0x11172',
        networkId: 70002,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Thinkium Mainnet Chain 103',
        chainName: 'Thinkium',
        hexChainId: '0x111d7',
        networkId: 70103,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'GuapcoinX',
        chainName: 'GuapcoinX',
        hexChainId: '0x115c7',
        networkId: 71111,
        icon: 'guapcoinx',
    },
    {
        type: 'testnet',
        name: 'Polyjuice Testnet',
        chainName: 'CKB',
        hexChainId: '0x116e1',
        networkId: 1,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Godwoken Testnet v1',
        chainName: 'GWT',
        hexChainId: '0x116e9',
        networkId: 71401,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Godwoken Mainnet',
        chainName: 'GWT',
        hexChainId: '0x116ea',
        networkId: 71402,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Energy Web Volta Testnet',
        chainName: 'Volta',
        hexChainId: '0x12047',
        networkId: 73799,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Mixin Virtual Machine',
        chainName: 'MVM',
        hexChainId: '0x120c7',
        networkId: 73927,
        icon: 'mvm',
    },
    {
        type: 'mainnet',
        name: 'ResinCoin Mainnet',
        chainName: 'RESIN',
        hexChainId: '0x124f8',
        networkId: 75000,
        icon: 'resincoin',
    },
    {
        type: 'mainnet',
        name: 'Vention Smart Chain Mainnet',
        chainName: 'VSC',
        hexChainId: '0x12f2c',
        networkId: 77612,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Toronet Mainnet',
        chainName: 'Toronet',
        hexChainId: '0x12fd1',
        networkId: 77777,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Firenze test network',
        chainName: 'ETH',
        hexChainId: '0x1311e',
        networkId: 78110,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Dragonfly Mainnet (Hexapod)',
        chainName: 'Dragonfly',
        hexChainId: '0x131c9',
        networkId: 78281,
        icon: 'dragonfly',
    },
    {
        type: 'testnet',
        name: 'Gold Smart Chain Testnet',
        chainName: 'STAND',
        hexChainId: '0x13807',
        networkId: 79879,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Mumbai',
        chainName: 'Polygon',
        hexChainId: '0x13881',
        networkId: 80001,
        icon: 'polygon',
    },
    {
        type: 'testnet',
        name: 'Amana Testnet',
        chainName: 'MEER',
        hexChainId: '0x13dbd',
        networkId: 81341,
        icon: 'meer',
    },
    {
        type: 'mainnet',
        name: 'Amana Mixnet',
        chainName: 'MEER',
        hexChainId: '0x13dbe',
        networkId: 81342,
        icon: 'meer',
    },
    {
        type: 'mainnet',
        name: 'Amana Privnet',
        chainName: 'MEER',
        hexChainId: '0x13dbf',
        networkId: 81343,
        icon: 'meer',
    },
    {
        type: 'testnet',
        name: 'Flana Testnet',
        chainName: 'MEER',
        hexChainId: '0x13dc7',
        networkId: 81351,
        icon: 'meer',
    },
    {
        type: 'mainnet',
        name: 'Flana Mixnet',
        chainName: 'MEER',
        hexChainId: '0x13dc8',
        networkId: 81352,
        icon: 'meer',
    },
    {
        type: 'mainnet',
        name: 'Flana Privnet',
        chainName: 'MEER',
        hexChainId: '0x13dc9',
        networkId: 81353,
        icon: 'meer',
    },
    {
        type: 'testnet',
        name: 'Mizana Testnet',
        chainName: 'MEER',
        hexChainId: '0x13dd1',
        networkId: 81361,
        icon: 'meer',
    },
    {
        type: 'mainnet',
        name: 'Mizana Mixnet',
        chainName: 'MEER',
        hexChainId: '0x13dd2',
        networkId: 81362,
        icon: 'meer',
    },
    {
        type: 'mainnet',
        name: 'Mizana Privnet',
        chainName: 'MEER',
        hexChainId: '0x13dd3',
        networkId: 81363,
        icon: 'meer',
    },
    {
        type: 'testnet',
        name: 'Base Goerli Testnet',
        chainName: 'ETH',
        hexChainId: '0x14a33',
        networkId: 84531,
        icon: 'baseTestnet',
    },
    {
        type: 'mainnet',
        name: 'CYBERTRUST',
        chainName: 'CYBER',
        hexChainId: '0x14dc9',
        networkId: 48501,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Chiliz Scoville Testnet',
        chainName: 'CHZ',
        hexChainId: '0x15b30',
        networkId: 88880,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'IVAR Chain Mainnet',
        chainName: 'IVAR',
        hexChainId: '0x15b38',
        networkId: 88888,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Beverly Hills',
        chainName: 'ETH',
        hexChainId: '0x16062',
        networkId: 90210,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Nautilus Chain',
        chainName: 'ETH',
        hexChainId: '0x1637a',
        networkId: 91002,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Lambda Testnet',
        chainName: 'Lambda',
        hexChainId: '0x16761',
        networkId: 92001,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Mantis Testnet (Hexapod)',
        chainName: 'Mantis',
        hexChainId: '0x17aca',
        networkId: 96970,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Boba BNB Mainnet Old',
        chainName: 'Boba BNB Mainnet',
        hexChainId: '0x17c08',
        networkId: 97288,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'eLiberty Testnet',
        chainName: '$EL',
        hexChainId: '0x1831b',
        networkId: 99099,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'UB Smart Chain(testnet)',
        chainName: 'USC',
        hexChainId: '0x1869e',
        networkId: 99998,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'UB Smart Chain',
        chainName: 'USC',
        hexChainId: '0x1869f',
        networkId: 99999,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'QuarkChain Mainnet Root',
        chainName: 'QuarkChain',
        hexChainId: '0x186a0',
        networkId: 100000,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'QuarkChain Mainnet Shard 0',
        chainName: 'QuarkChain',
        hexChainId: '0x186a1',
        networkId: 100001,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'QuarkChain Mainnet Shard 1',
        chainName: 'QuarkChain',
        hexChainId: '0x186a2',
        networkId: 100002,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'QuarkChain Mainnet Shard 2',
        chainName: 'QuarkChain',
        hexChainId: '0x186a3',
        networkId: 100003,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'QuarkChain Mainnet Shard 3',
        chainName: 'QuarkChain',
        hexChainId: '0x186a4',
        networkId: 100004,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'QuarkChain Mainnet Shard 4',
        chainName: 'QuarkChain',
        hexChainId: '0x186a5',
        networkId: 100005,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'QuarkChain Mainnet Shard 5',
        chainName: 'QuarkChain',
        hexChainId: '0x186a6',
        networkId: 100006,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'QuarkChain Mainnet Shard 6',
        chainName: 'QuarkChain',
        hexChainId: '0x186a7',
        networkId: 100007,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'QuarkChain Mainnet Shard 7',
        chainName: 'QuarkChain',
        hexChainId: '0x186a8',
        networkId: 100008,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'VeChain',
        chainName: 'VeChain',
        hexChainId: '0x186a9',
        networkId: 100009,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'VeChain Testnet',
        chainName: 'VeChain',
        hexChainId: '0x186aa',
        networkId: 100010,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Deprecated CHI',
        chainName: 'CHI1',
        hexChainId: '0x18704',
        networkId: 100100,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Soverun Testnet',
        chainName: 'SVRN',
        hexChainId: '0x18a92',
        networkId: 101010,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Crystaleum',
        chainName: 'crystal',
        hexChainId: '0x192b2',
        networkId: 1,
        icon: 'crystal',
    },
    {
        type: 'mainnet',
        name: 'BROChain Mainnet',
        chainName: 'BRO',
        hexChainId: '0x1a901',
        networkId: 108801,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'QuarkChain Devnet Root',
        chainName: 'QuarkChain',
        hexChainId: '0x1adb0',
        networkId: 110000,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'QuarkChain Devnet Shard 0',
        chainName: 'QuarkChain',
        hexChainId: '0x1adb1',
        networkId: 110001,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'QuarkChain Devnet Shard 1',
        chainName: 'QuarkChain',
        hexChainId: '0x1adb2',
        networkId: 110002,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'QuarkChain Devnet Shard 2',
        chainName: 'QuarkChain',
        hexChainId: '0x1adb3',
        networkId: 110003,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'QuarkChain Devnet Shard 3',
        chainName: 'QuarkChain',
        hexChainId: '0x1adb4',
        networkId: 110004,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'QuarkChain Devnet Shard 4',
        chainName: 'QuarkChain',
        hexChainId: '0x1adb5',
        networkId: 110005,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'QuarkChain Devnet Shard 5',
        chainName: 'QuarkChain',
        hexChainId: '0x1adb6',
        networkId: 110006,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'QuarkChain Devnet Shard 6',
        chainName: 'QuarkChain',
        hexChainId: '0x1adb7',
        networkId: 110007,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'QuarkChain Devnet Shard 7',
        chainName: 'QuarkChain',
        hexChainId: '0x1adb8',
        networkId: 110008,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Siberium Test Network',
        chainName: 'SBR',
        hexChainId: '0x1b198',
        networkId: 111000,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Siberium Network',
        chainName: 'SBR',
        hexChainId: '0x1b207',
        networkId: 111111,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Metachain One Mainnet',
        chainName: 'METAO',
        hexChainId: '0x1b6e6',
        networkId: 112358,
        icon: 'metao',
    },
    {
        type: 'testnet',
        name: 'ADIL Devnet',
        chainName: 'ADIL',
        hexChainId: '0x1e240',
        networkId: 123456,
        icon: 'adil',
    },
    {
        type: 'mainnet',
        name: 'ETND Chain Mainnets',
        chainName: 'ETND',
        hexChainId: '0x2015b',
        networkId: 131419,
        icon: 'ETND',
    },
    {
        type: 'mainnet',
        name: 'ICPlaza Mainnet',
        chainName: 'ICPlaza',
        hexChainId: '0x22e09',
        networkId: 142857,
        icon: 'icplaza',
    },
    {
        type: 'testnet',
        name: 'Taiko (Alpha-2 Testnet)',
        chainName: 'ETH',
        hexChainId: '0x28c5c',
        networkId: 167004,
        icon: 'taiko',
    },
    {
        type: 'mainnet',
        name: 'Taiko Grimsvotn L2',
        chainName: 'ETH',
        hexChainId: '0x28c5d',
        networkId: 167005,
        icon: 'taiko',
    },
    {
        type: 'mainnet',
        name: 'Taiko Eldfell L3',
        chainName: 'ETH',
        hexChainId: '0x28c5e',
        networkId: 167006,
        icon: 'taiko',
    },
    {
        type: 'testnet',
        name: 'Condor Test Network',
        chainName: 'CONDOR',
        hexChainId: '0x2e1d1',
        networkId: 188881,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Milkomeda C1 Testnet',
        chainName: 'milkTAda',
        hexChainId: '0x30da5',
        networkId: 200101,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Milkomeda A1 Testnet',
        chainName: 'milkTAlgo',
        hexChainId: '0x30e0a',
        networkId: 200202,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Akroma',
        chainName: 'AKA',
        hexChainId: '0x30fb1',
        networkId: 200625,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Alaya Mainnet',
        chainName: 'Alaya',
        hexChainId: '0x3113a',
        networkId: 1,
        icon: 'alaya',
    },
    {
        type: 'testnet',
        name: 'Alaya Dev Testnet',
        chainName: 'Alaya',
        hexChainId: '0x31146',
        networkId: 1,
        icon: 'alaya',
    },
    {
        type: 'mainnet',
        name: 'Mythical Chain',
        chainName: 'MYTH',
        hexChainId: '0x3144c',
        networkId: 201804,
        icon: 'mythical',
    },
    {
        type: 'testnet',
        name: 'Decimal Smart Chain Testnet',
        chainName: 'tDSC',
        hexChainId: '0x31524',
        networkId: 202020,
        icon: 'dsc',
    },
    {
        type: 'testnet',
        name: 'Jellie',
        chainName: 'ETH',
        hexChainId: '0x31780',
        networkId: 202624,
        icon: 'twala',
    },
    {
        type: 'mainnet',
        name: 'PlatON Mainnet',
        chainName: 'PlatON',
        hexChainId: '0x335f9',
        networkId: 1,
        icon: 'platon',
    },
    {
        type: 'mainnet',
        name: 'Mas Mainnet',
        chainName: 'MAS',
        hexChainId: '0x35c9b',
        networkId: 220315,
        icon: 'mas',
    },
    {
        type: 'mainnet',
        name: 'Reapchain Mainnet',
        chainName: 'REAP',
        hexChainId: '0x3602e',
        networkId: 221230,
        icon: 'reapchain',
    },
    {
        type: 'testnet',
        name: 'Reapchain Testnet',
        chainName: 'REAP',
        hexChainId: '0x3602f',
        networkId: 221231,
        icon: 'reapchain',
    },
    {
        type: 'mainnet',
        name: 'Taf ECO Chain Mainnet',
        chainName: 'Taf ECO Chain',
        hexChainId: '0x36ba8',
        networkId: 224168,
        icon: 'taf',
    },
    {
        type: 'testnet',
        name: 'HashKey Chain Testnet',
        chainName: 'HashKey',
        hexChainId: '0x383ab',
        networkId: 230315,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Haymo Testnet',
        chainName: 'tHYM',
        hexChainId: '0x394aa',
        networkId: 234666,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'ARTIS sigma1',
        chainName: 'ARTIS',
        hexChainId: '0x3c301',
        networkId: 246529,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'ARTIS Testnet tau1',
        chainName: 'ARTIS',
        hexChainId: '0x3c401',
        networkId: 246785,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Saakuru Testnet',
        chainName: 'Saakuru',
        hexChainId: '0x3c5d5',
        networkId: 247253,
        icon: 'saakuru',
    },
    {
        type: 'mainnet',
        name: 'CMP-Mainnet',
        chainName: 'CMP',
        hexChainId: '0x3e900',
        networkId: 256256,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Gear Zero Network Testnet',
        chainName: 'GearZero',
        hexChainId: '0x41010',
        networkId: 266256,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'EgonCoin Testnet',
        chainName: 'EGON',
        hexChainId: '0x423a7',
        networkId: 271271,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Social Smart Chain Mainnet',
        chainName: 'SoChain',
        hexChainId: '0x44a21',
        networkId: 281121,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Filecoin - Calibration testnet',
        chainName: 'FIL',
        hexChainId: '0x4cb2f',
        networkId: 314159,
        icon: 'filecoin',
    },
    {
        type: 'mainnet',
        name: 'TTcoin Smart Chain Mainnet',
        chainName: 'TSC',
        hexChainId: '0x50c5c',
        networkId: 330844,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Aves Testnet',
        chainName: 'AVST',
        hexChainId: '0x51613',
        networkId: 333331,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Oone Chain Testnet',
        chainName: 'OONE',
        hexChainId: '0x517d1',
        networkId: 333777,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Polis Testnet',
        chainName: 'Sparta',
        hexChainId: '0x51840',
        networkId: 333888,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Polis Mainnet',
        chainName: 'Olympus',
        hexChainId: '0x518af',
        networkId: 333999,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Bitfinity Network Testnet',
        chainName: 'BFT',
        hexChainId: '0x56b29',
        networkId: 355113,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'HAPchain Testnet',
        chainName: 'HAPchain',
        hexChainId: '0x5b3e9',
        networkId: 373737,
        icon: 'hap',
    },
    {
        type: 'mainnet',
        name: 'Metal C-Chain',
        chainName: 'Metal',
        hexChainId: '0x5d3eb',
        networkId: 381931,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Metal Tahoe C-Chain',
        chainName: 'Metal',
        hexChainId: '0x5d3ec',
        networkId: 381932,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Tipboxcoin Mainnet',
        chainName: 'TPBX',
        hexChainId: '0x62a48',
        networkId: 404040,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Kekchain',
        chainName: 'kek',
        hexChainId: '0x66a44',
        networkId: 103090,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Kekchain (kektest)',
        chainName: 'kek',
        hexChainId: '0x66b3a',
        networkId: 1,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Alterium L2 Testnet',
        chainName: 'ALT',
        hexChainId: '0x66b54',
        networkId: 420692,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Arbitrum Rinkeby',
        chainName: 'ETH',
        hexChainId: '0x66eeb',
        networkId: 421611,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Arbitrum Goerli',
        chainName: 'ETH',
        hexChainId: '0x66eed',
        networkId: 421613,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Fastex Chain testnet',
        chainName: 'FTN',
        hexChainId: '0x67932',
        networkId: 424242,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Markr Go',
        chainName: 'Unified',
        hexChainId: '0x69424',
        networkId: 431140,
        icon: 'markrgo',
    },
    {
        type: 'testnet',
        name: 'Dexalot Subnet Testnet',
        chainName: 'DEXALOT',
        hexChainId: '0x69849',
        networkId: 432201,
        icon: 'dexalot',
    },
    {
        type: 'mainnet',
        name: 'Dexalot Subnet',
        chainName: 'DEXALOT',
        hexChainId: '0x6984c',
        networkId: 432204,
        icon: 'dexalot',
    },
    {
        type: 'testnet',
        name: 'Weelink Testnet',
        chainName: 'WLK',
        hexChainId: '0x6c9e4',
        networkId: 444900,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Patex Sepolia Testnet',
        chainName: 'ETH',
        hexChainId: '0x7303c',
        networkId: 471100,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'OpenChain Mainnet',
        chainName: 'OpenChain',
        hexChainId: '0x73c1e',
        networkId: 474142,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'CMP-Testnet',
        chainName: 'CMP',
        hexChainId: '0x7d200',
        networkId: 512512,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'ethereum Fair',
        chainName: 'ETHF',
        hexChainId: '0x7d44c',
        networkId: 513100,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Scroll Sepolia Testnet',
        chainName: 'ETH',
        hexChainId: '0x8274f',
        networkId: 534351,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Scroll',
        chainName: 'ETH',
        hexChainId: '0x82750',
        networkId: 534352,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Scroll Alpha Testnet',
        chainName: 'ETH',
        hexChainId: '0x82751',
        networkId: 534353,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Scroll Pre-Alpha Testnet',
        chainName: 'ETH',
        hexChainId: '0x82752',
        networkId: 534354,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Shinarium Beta',
        chainName: 'Shinarium',
        hexChainId: '0x82941',
        networkId: 534849,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'BeanEco SmartChain',
        chainName: 'BESC',
        hexChainId: '0x829fd',
        networkId: 535037,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Bear Network Chain Mainnet',
        chainName: 'BRNKC',
        hexChainId: '0x9c8ce',
        networkId: 641230,
        icon: 'brnkc',
    },
    {
        type: 'mainnet',
        name: 'ALL Mainnet',
        chainName: 'ALL',
        hexChainId: '0x9f2a4',
        networkId: 651940,
        icon: 'alltra',
    },
    {
        type: 'testnet',
        name: 'Vision - Vpioneer Test Chain',
        chainName: 'Vision-Vpioneer',
        hexChainId: '0xa2c2a',
        networkId: 666666,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Bear Network Chain Testnet',
        chainName: 'BRNKCTEST',
        hexChainId: '0xb767e',
        networkId: 751230,
        icon: 'brnkc',
    },
    {
        type: 'mainnet',
        name: 'Miexs Smartchain',
        chainName: 'MiexsSmartchain',
        hexChainId: '0xb9e44',
        networkId: 761412,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'OctaSpace',
        chainName: 'OCTA',
        hexChainId: '0xc3501',
        networkId: 800001,
        icon: 'octaspace',
    },
    {
        type: 'mainnet',
        name: 'CURVE Mainnet',
        chainName: 'CURVE',
        hexChainId: '0xca027',
        networkId: 827431,
        icon: 'curveIcon',
    },
    {
        type: 'mainnet',
        name: '4GoodNetwork',
        chainName: '4GN',
        hexChainId: '0xce8b0',
        networkId: 846000,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Vision - Mainnet',
        chainName: 'Vision',
        hexChainId: '0xd9038',
        networkId: 888888,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Posichain Mainnet Shard 0',
        chainName: 'PSC',
        hexChainId: '0xdbba0',
        networkId: 900000,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Posichain Testnet Shard 0',
        chainName: 'PSC',
        hexChainId: '0xde2b0',
        networkId: 910000,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Posichain Devnet Shard 0',
        chainName: 'PSC',
        hexChainId: '0xe09c0',
        networkId: 920000,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Posichain Devnet Shard 1',
        chainName: 'PSC',
        hexChainId: '0xe09c1',
        networkId: 920001,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'FNCY Testnet',
        chainName: 'FNCY',
        hexChainId: '0xe158a',
        networkId: 923018,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Eluvio Content Fabric',
        chainName: 'Eluvio',
        hexChainId: '0xe93a9',
        networkId: 955305,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Etho Protocol',
        chainName: 'ETHO',
        hexChainId: '0x14095a',
        networkId: 1313114,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Xerom',
        chainName: 'XERO',
        hexChainId: '0x140adc',
        networkId: 1313500,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Kintsugi',
        chainName: 'ETH',
        hexChainId: '0x146966',
        networkId: 1337702,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Kiln',
        chainName: 'ETH',
        hexChainId: '0x1469ca',
        networkId: 1337802,
        icon: 'ethereum',
    },
    {
        type: 'mainnet',
        name: 'Zhejiang',
        chainName: 'ETH',
        hexChainId: '0x1469cb',
        networkId: 1337803,
        icon: 'ethereum',
    },
    {
        type: 'mainnet',
        name: 'Plian Mainnet Main',
        chainName: 'Plian',
        hexChainId: '0x2007d4',
        networkId: 2099156,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'PlatON Dev Testnet Deprecated',
        chainName: 'PlatON',
        hexChainId: '0x219e2d',
        networkId: 1,
        icon: 'platon',
    },
    {
        type: 'testnet',
        name: 'PlatON Dev Testnet2',
        chainName: 'PlatON',
        hexChainId: '0x21a9b4',
        networkId: 1,
        icon: 'platon',
    },
    {
        type: 'testnet',
        name: 'Filecoin - Butterfly testnet',
        chainName: 'FIL',
        hexChainId: '0x2fefd8',
        networkId: 3141592,
        icon: 'filecoin',
    },
    {
        type: 'testnet',
        name: 'Manta Pacific Testnet',
        chainName: 'Manta Pacific',
        hexChainId: '0x34816d',
        networkId: 3441005,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'AltLayer Zero Gas Network',
        chainName: 'ETH',
        hexChainId: '0x3d0903',
        networkId: 4000003,
        icon: 'altlayer',
    },
    {
        type: 'mainnet',
        name: 'Worlds Caldera',
        chainName: 'WCal',
        hexChainId: '0x4152c9',
        networkId: 4281033,
        icon: 'ethereum',
    },
    {
        type: 'testnet',
        name: 'MXC Wannsee zkEVM Testnet',
        chainName: 'MXC zkEVM',
        hexChainId: '0x4ed79b',
        networkId: 5167003,
        icon: 'mxc',
    },
    {
        type: 'mainnet',
        name: 'Imversed Mainnet',
        chainName: 'Imversed',
        hexChainId: '0x54c563',
        networkId: 5555555,
        icon: 'imversed',
    },
    {
        type: 'testnet',
        name: 'Imversed Testnet',
        chainName: 'Imversed',
        hexChainId: '0x54c566',
        networkId: 5555558,
        icon: 'imversed',
    },
    {
        type: 'mainnet',
        name: 'Saakuru Mainnet',
        chainName: 'Saakuru',
        hexChainId: '0x6e4216',
        networkId: 7225878,
        icon: 'saakuru',
    },
    {
        type: 'mainnet',
        name: 'OpenVessel',
        chainName: 'VSL',
        hexChainId: '0x703bae',
        networkId: 7355310,
        icon: 'vsl',
    },
    {
        type: 'testnet',
        name: 'QL1 Testnet',
        chainName: 'QOM',
        hexChainId: '0x75029a',
        networkId: 7668378,
        icon: 'qom',
    },
    {
        type: 'mainnet',
        name: 'Musicoin',
        chainName: 'MUSIC',
        hexChainId: '0x76740f',
        networkId: 7762959,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Zora',
        chainName: 'ETH',
        hexChainId: '0x76adf1',
        networkId: 7777777,
        icon: 'zora',
    },
    {
        type: 'mainnet',
        name: 'Plian Mainnet Subchain 1',
        chainName: 'Plian',
        hexChainId: '0x7a3038',
        networkId: 8007736,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'HAPchain',
        chainName: 'HAPchain',
        hexChainId: '0x8631e6',
        networkId: 8794598,
        icon: 'hap',
    },
    {
        type: 'testnet',
        name: 'Quarix Testnet',
        chainName: 'Quarix',
        hexChainId: '0x87a231',
        networkId: 8888881,
        icon: 'quarix',
    },
    {
        type: 'mainnet',
        name: 'Quarix',
        chainName: 'Quarix',
        hexChainId: '0x87a238',
        networkId: 8888888,
        icon: 'quarix',
    },
    {
        type: 'testnet',
        name: 'Plian Testnet Subchain 1',
        chainName: 'Plian',
        hexChainId: '0x999d4b',
        networkId: 10067275,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Soverun Mainnet',
        chainName: 'SVRN',
        hexChainId: '0x9a2112',
        networkId: 10101010,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Sepolia',
        chainName: 'ETH',
        hexChainId: '0xaa36a7',
        networkId: 11155111,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'PepChain Churchill',
        chainName: 'PEP',
        hexChainId: '0xcc07c9',
        networkId: 13371337,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Anduschain Mainnet',
        chainName: 'anduschain',
        hexChainId: '0xda0700',
        networkId: 14288640,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Plian Testnet Main',
        chainName: 'Plian',
        hexChainId: '0xfe3005',
        networkId: 16658437,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'IOLite',
        chainName: 'ILT',
        hexChainId: '0x1171337',
        networkId: 18289463,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'SmartMesh Mainnet',
        chainName: 'Spectrum',
        hexChainId: '0x133edce',
        networkId: 1,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'quarkblockchain',
        chainName: 'QKI',
        hexChainId: '0x133f0d5',
        networkId: 20181205,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Pego Network',
        chainName: 'PEGO',
        hexChainId: '0x1343e3e',
        networkId: 20201022,
        icon: 'pego',
    },
    {
        type: 'mainnet',
        name: 'Excelon Mainnet',
        chainName: 'XLON',
        hexChainId: '0x1507ca2',
        networkId: 22052002,
        icon: 'xlon',
    },
    {
        type: 'testnet',
        name: 'Excoincial Chain Volta-Testnet',
        chainName: 'TEXL',
        hexChainId: '0x19d3d21',
        networkId: 27082017,
        icon: 'exl',
    },
    {
        type: 'mainnet',
        name: 'Excoincial Chain Mainnet',
        chainName: 'EXL',
        hexChainId: '0x19d3d26',
        networkId: 27082022,
        icon: 'exl',
    },
    {
        type: 'mainnet',
        name: 'Auxilium Network Mainnet',
        chainName: 'AUX',
        hexChainId: '0x1b9ac4e',
        networkId: 28945486,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Flachain Mainnet',
        chainName: 'FLX',
        hexChainId: '0x1bafe56',
        networkId: 29032022,
        icon: 'flacoin',
    },
    {
        type: 'testnet',
        name: 'Filecoin - Local testnet',
        chainName: 'FIL',
        hexChainId: '0x1df5e76',
        networkId: 31415926,
        icon: 'filecoin',
    },
    {
        type: 'mainnet',
        name: 'Joys Digital Mainnet',
        chainName: 'JOYS',
        hexChainId: '0x2231c60',
        networkId: 35855456,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'maistestsubnet',
        chainName: 'MAI',
        hexChainId: '0x2936841',
        networkId: 43214913,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Aquachain',
        chainName: 'AQUA',
        hexChainId: '0x3adbc39',
        networkId: 61717561,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Autonity Bakerloo (Thames) Testnet',
        chainName: 'AUT',
        hexChainId: '0x3dff950',
        networkId: 65010000,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Autonity Piccadilly (Thames) Testnet',
        chainName: 'AUT',
        hexChainId: '0x3e158e0',
        networkId: 65100000,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'T.E.A.M Blockchain',
        chainName: 'TEAM',
        hexChainId: '0x54c5638',
        networkId: 88888888,
        icon: 'team',
    },
    {
        type: 'testnet',
        name: 'Joys Digital TestNet',
        chainName: 'TOYS',
        hexChainId: '0x5ecf69a',
        networkId: 99415706,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Gather Mainnet Network',
        chainName: 'GTH',
        hexChainId: '0xb7e7759',
        networkId: 192837465,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Kanazawa',
        chainName: 'Kanazawa',
        hexChainId: '0xd3b745e',
        networkId: 222000222,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Neon EVM DevNet',
        chainName: 'Solana',
        hexChainId: '0xe9ac0ce',
        networkId: 245022926,
        icon: 'neon',
    },
    {
        type: 'mainnet',
        name: 'Neon EVM MainNet',
        chainName: 'Solana',
        hexChainId: '0xe9ac0d6',
        networkId: 245022934,
        icon: 'neon',
    },
    {
        type: 'testnet',
        name: 'Neon EVM TestNet',
        chainName: 'Solana',
        hexChainId: '0xe9ac0dc',
        networkId: 245022940,
        icon: 'neon',
    },
    {
        type: 'mainnet',
        name: 'Razor Skale Chain',
        chainName: 'Razor Schain',
        hexChainId: '0x109b4597',
        networkId: 278611351,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'OneLedger Mainnet',
        chainName: 'OLT',
        hexChainId: '0x1294f7c2',
        networkId: 311752642,
        icon: 'oneledger',
    },
    {
        type: 'mainnet',
        name: 'Meld',
        chainName: 'MELD',
        hexChainId: '0x13d92e8d',
        networkId: 333000333,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Calypso NFT Hub (SKALE Testnet)',
        chainName: 'staging-utter-unripe-menkar',
        hexChainId: '0x1482a7b2',
        networkId: 344106930,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Gather Testnet Network',
        chainName: 'GTH',
        hexChainId: '0x153c099c',
        networkId: 356256156,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Gather Devnet Network',
        chainName: 'GTH',
        hexChainId: '0x1cfb18cf',
        networkId: 486217935,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Nebula Staging',
        chainName: 'staging-faint-slimy-achird',
        hexChainId: '0x1dfd2731',
        networkId: 503129905,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'IPOS Network',
        chainName: 'IPOS',
        hexChainId: '0x42e576f7',
        networkId: 1122334455,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'CyberdeckNet',
        chainName: 'cyberdeck',
        hexChainId: '0x44594e46',
        networkId: 1146703430,
        icon: 'cyberdeck',
    },
    {
        type: 'mainnet',
        name: 'HUMAN Protocol',
        chainName: 'wan-red-ain',
        hexChainId: '0x4be3e8bd',
        networkId: 1273227453,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Aurora Mainnet',
        chainName: 'NEAR',
        hexChainId: '0x4e454152',
        networkId: 1313161554,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Aurora Testnet',
        chainName: 'NEAR',
        hexChainId: '0x4e454153',
        networkId: 1313161555,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Aurora Betanet',
        chainName: 'NEAR',
        hexChainId: '0x4e454154',
        networkId: 1313161556,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Chaos (SKALE Testnet)',
        chainName: 'staging-fast-active-bellatrix',
        hexChainId: '0x50877ed6',
        networkId: 1351057110,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'RaptorChain',
        chainName: 'RPTR',
        hexChainId: '0x52505452',
        networkId: 1380996178,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Nebula Mainnet',
        chainName: 'green-giddy-denebola',
        hexChainId: '0x585eb4b1',
        networkId: 1482601649,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Calypso NFT Hub (SKALE)',
        chainName: 'honorable-steel-rasalhague',
        hexChainId: '0x5d456c62',
        networkId: 1564830818,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Harmony Mainnet Shard 0',
        chainName: 'Harmony',
        hexChainId: '0x63564c40',
        networkId: 1666600000,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Harmony Mainnet Shard 1',
        chainName: 'Harmony',
        hexChainId: '0x63564c41',
        networkId: 1666600001,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Harmony Mainnet Shard 2',
        chainName: 'Harmony',
        hexChainId: '0x63564c42',
        networkId: 1666600002,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Harmony Mainnet Shard 3',
        chainName: 'Harmony',
        hexChainId: '0x63564c43',
        networkId: 1666600003,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Harmony Testnet Shard 0',
        chainName: 'Harmony',
        hexChainId: '0x6357d2e0',
        networkId: 1666700000,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Harmony Testnet Shard 1',
        chainName: 'Harmony',
        hexChainId: '0x6357d2e1',
        networkId: 1666700001,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Harmony Devnet Shard 0',
        chainName: 'Harmony',
        hexChainId: '0x635ae020',
        networkId: 1666900000,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Harmony Devnet Shard 1',
        chainName: 'Harmony',
        hexChainId: '0x635ae021',
        networkId: 1666900001,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'DataHopper',
        chainName: 'HOP',
        hexChainId: '0x7877dc5d',
        networkId: 2021121117,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Europa SKALE Chain',
        chainName: 'europa',
        hexChainId: '0x79f99296',
        networkId: 2046399126,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Ancient8 Testnet',
        chainName: 'Ancient8',
        hexChainId: '0xaaaaaaab',
        networkId: 2863311531,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Pirl',
        chainName: 'PIRL',
        hexChainId: '0xba4dc610',
        networkId: 3125659152,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'OneLedger Testnet Frankenstein',
        chainName: 'OLT',
        hexChainId: '0xfb4d255f',
        networkId: 4216137055,
        icon: 'oneledger',
    },
    {
        type: 'testnet',
        name: 'Palm Testnet',
        chainName: 'Palm',
        hexChainId: '0x2a15c3083',
        networkId: 11297108099,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Palm',
        chainName: 'Palm',
        hexChainId: '0x2a15c308d',
        networkId: 11297108109,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Alphabet Mainnet',
        chainName: 'Alphabet Network',
        hexChainId: '0x19e55e2004',
        networkId: 111222333444,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Ntity Mainnet',
        chainName: 'Ntity',
        hexChainId: '0x2e08726bbe',
        networkId: 197710212030,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Haradev Testnet',
        chainName: 'Ntity',
        hexChainId: '0x2e08726bbf',
        networkId: 197710212031,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Zeniq',
        chainName: 'ZENIQ',
        hexChainId: '0x59454e4951',
        networkId: 383414847825,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'PDC Mainnet',
        chainName: 'IPDC',
        hexChainId: '0x9b22a5091f',
        networkId: 666301171999,
        icon: null,
    },
    {
        type: 'mainnet',
        name: 'Molereum Network',
        chainName: 'ETH',
        hexChainId: '0x57a238f93bf',
        networkId: 6022140761023,
        icon: null,
    },
    {
        type: 'testnet',
        name: 'Godwoken Testnet (V1)',
        chainName: 'GWT',
        hexChainId: '0x315db00000006',
        networkId: 868455272153094,
        icon: null,
    },
]
