import { CryptoCurrency } from '@zeal/domains/Currency'
import { Token } from '@zeal/domains/Token'

import { Portfolio } from '../index'

type Params = {
    currency: CryptoCurrency
    portfolio: Portfolio
}

export const getTokenByCryptoCurrency = ({
    currency,
    portfolio,
}: Params): Token => {
    const portfolioToken =
        portfolio.tokens.find(
            (token) => token.balance.currencyId === currency.id
        ) || null

    return (
        portfolioToken || {
            address: currency.address,
            balance: {
                amount: 0n,
                currencyId: currency.id,
            },
            networkHexId: currency.networkHexChainId,
            priceInDefaultCurrency: null,
            rate: null,
            marketData: null,
            scam: false,
        }
    )
}
