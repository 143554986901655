import React from 'react'
import { Path } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

import { Color, colors } from '../colors'

type Props = {
    color?: Color
    size: number
}
export const BoldWallet = ({ size, color }: Props) => (
    <SvgIcon
        viewBox="0 0 24 24"
        width={size}
        color={color && colors[color]}
        height={size}
    >
        <Path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M18.7303 6.77877H23.6666C23.6666 2.81536 21.2918 0.5 17.2681 0.5H6.73177C2.70807 0.5 0.333252 2.81536 0.333252 6.72821V15.2718C0.333252 19.1846 2.70807 21.5 6.73177 21.5H17.2681C21.2918 21.5 23.6666 19.1846 23.6666 15.2718V14.9078H18.7303C16.4393 14.9078 14.5821 13.0971 14.5821 10.8635C14.5821 8.6299 16.4393 6.81921 18.7303 6.81921V6.77877ZM18.7306 8.51628H22.7957C23.2768 8.51628 23.6669 8.89652 23.6669 9.36558V12.3179C23.6613 12.7847 23.2745 13.1618 22.7957 13.1672H18.8239C17.6641 13.1824 16.6499 12.4082 16.3869 11.3068C16.2551 10.6231 16.4401 9.91762 16.8921 9.37938C17.3442 8.84114 18.0171 8.52522 18.7306 8.51628ZM18.9074 11.618H19.2911C19.7837 11.618 20.183 11.2287 20.183 10.7485C20.183 10.2683 19.7837 9.87897 19.2911 9.87897H18.9074C18.6718 9.87626 18.4449 9.96561 18.2774 10.1271C18.1098 10.2885 18.0155 10.5087 18.0155 10.7384C18.0155 11.2203 18.4132 11.6125 18.9074 11.618ZM5.85987 6.77811H12.4451C12.9376 6.77811 13.3369 6.38881 13.3369 5.90859C13.3369 5.42836 12.9376 5.03906 12.4451 5.03906H5.85987C5.37134 5.03903 4.9737 5.4222 4.96802 5.89848C4.96799 6.38036 5.36564 6.77257 5.85987 6.77811Z"
            fill="currentColor"
        />
    </SvgIcon>
)
