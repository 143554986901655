import React from 'react'
import Svg, { Circle, Path } from 'react-native-svg'

type Props = {
    size: number
}

export const Arbitrum = ({ size }: Props) => {
    return (
        <Svg
            style={{ flexShrink: 0 }}
            viewBox="0 0 120 120"
            width={size}
            height={size}
            fill="none"
        >
            <Circle cx="60" cy="60" r="60" fill="#2D374B" />
            <Path
                d="M70.1196 56.5947L76.7155 45.409L94.4946 73.0839L94.5031 78.3952L94.4452 41.8478C94.403 40.9544 93.9283 40.1372 93.1711 39.6552L61.162 21.2541C60.4137 20.8862 59.4637 20.8905 58.7163 21.2658C58.6153 21.3161 58.5202 21.3712 58.43 21.4313L58.3182 21.5013L27.2486 39.4951L27.1281 39.5498C26.9731 39.621 26.8162 39.7116 26.6693 39.8168C26.0801 40.2389 25.689 40.8633 25.5625 41.5636C25.5434 41.6698 25.5294 41.7777 25.5229 41.887L25.5717 71.6695L42.132 46.0171C44.2171 42.6156 48.7596 41.52 52.9767 41.5795L57.926 41.7101L28.7635 88.4504L32.2012 90.4281L61.7128 41.7576L74.7571 41.7102L45.3216 91.609L57.5882 98.6603L59.0538 99.5027C59.6737 99.7545 60.4047 99.7672 61.0298 99.5415L93.4886 80.7423L87.2829 84.3362L70.1196 56.5947ZM72.6359 92.8193L60.2467 73.386L67.8097 60.56L84.0808 86.1908L72.6359 92.8193Z"
                fill="#2D374B"
            />
            <Path
                d="M60.2468 73.3897L72.6359 92.8231L84.0809 86.1945L67.8096 60.5637L60.2468 73.3897Z"
                fill="#28A0F0"
            />
            <Path
                d="M94.5032 78.3966L94.4947 73.0853L76.7156 45.4104L70.1196 56.5962L87.2828 84.3375L93.4885 80.7436C94.0971 80.2494 94.4654 79.5244 94.5041 78.7422L94.5032 78.3966Z"
                fill="#28A0F0"
            />
            <Path
                d="M20.0002 83.4056L28.7635 88.4523L57.9258 41.7122L52.9765 41.5816C48.7594 41.5221 44.2167 42.6177 42.1318 46.0191L25.5715 71.6716L20.0002 80.2266V83.4056Z"
                fill="white"
            />
            <Path
                d="M74.7572 41.7114L61.7128 41.7588L32.2013 90.4293L42.5165 96.3648L45.3217 91.6102L74.7572 41.7114Z"
                fill="white"
            />
            <Path
                d="M100 41.6449C99.8908 38.9185 98.4138 36.4227 96.1002 34.9697L63.6715 16.3319C61.3829 15.1803 58.5239 15.1788 56.2312 16.3312C55.9602 16.4678 24.6952 34.5893 24.6952 34.5893C24.2625 34.7968 23.8457 35.0438 23.4536 35.3243C21.3883 36.8036 20.1294 39.1025 20.0001 41.6281V80.2264L25.5713 71.6714L25.5227 41.889C25.5292 41.7801 25.5428 41.6728 25.5623 41.5671C25.6883 40.8663 26.0797 40.2412 26.6691 39.8187C26.8162 39.7135 58.6153 21.318 58.7163 21.2675C59.4637 20.8924 60.4137 20.888 61.162 21.2559L93.1711 39.6566C93.9283 40.1386 94.403 40.9558 94.4452 41.8492V78.7423C94.4065 79.5246 94.097 80.2495 93.4884 80.7437L87.2827 84.3376L84.0807 86.1922L72.6359 92.8209L61.0295 99.5429C60.4044 99.7686 59.6735 99.7559 59.0536 99.5041L45.3214 91.6106L42.5162 96.3654L54.8568 103.466C55.2649 103.698 55.6285 103.904 55.9269 104.071C56.3888 104.33 56.7037 104.503 56.8149 104.557C57.692 104.983 58.954 105.231 60.0912 105.231C61.1339 105.231 62.1507 105.039 63.1127 104.663L96.8243 85.1513C98.7592 83.653 99.8975 81.3934 100 78.9457V41.6449Z"
                fill="#96BEDC"
            />
        </Svg>
    )
}
