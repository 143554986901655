import React from 'react'
import Svg, { Circle, Path } from 'react-native-svg'

type Props = {
    size: number
}

export const AllNetwork = ({ size }: Props) => {
    return (
        <Svg
            style={{ flexShrink: 0 }}
            viewBox="0 0 120 120"
            width={size}
            height={size}
        >
            <Circle cx="60" cy="60" r="60" fill="white" />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M67.5012 45.3735C70.431 43.2195 72.3324 39.7485 72.3324 35.8333C72.3324 29.2979 67.0345 24 60.4991 24C53.9638 24 48.6659 29.2979 48.6659 35.8333C48.6659 39.7493 50.5681 43.2211 53.499 45.3749L40.2623 71.8484C39.1769 71.5203 38.0257 71.344 36.8333 71.344C30.2979 71.344 25 76.6419 25 83.1772C25 89.7126 30.2979 95.0105 36.8333 95.0105C42.6869 95.0105 47.5479 90.7601 48.4982 85.1772H72.5021C73.4524 90.7601 78.3134 95.0105 84.167 95.0105C90.7024 95.0105 96.0003 89.7126 96.0003 83.1772C96.0003 76.6419 90.7024 71.344 84.167 71.344C82.9748 71.344 81.8238 71.5203 80.7386 71.8482L67.5012 45.3735ZM57.0766 47.1641L43.8388 73.6396C46.2567 75.4185 47.9735 78.0948 48.4982 81.1772H72.5021C73.0268 78.0946 74.7439 75.4182 77.162 73.6392L63.924 47.1633C62.8399 47.4906 61.69 47.6665 60.4991 47.6665C59.3091 47.6665 58.1601 47.4909 57.0766 47.1641Z"
                fill="#CBD2D9"
            />
        </Svg>
    )
}
