import { FormattedMessage } from 'react-intl'

import { Column } from '@zeal/uikit/Column'
import { ListItem } from '@zeal/uikit/ListItem'
import { Text } from '@zeal/uikit/Text'

import { AvatarWithoutBadge } from '@zeal/domains/Account/components/Avatar'
import { format } from '@zeal/domains/Address/helpers/format'
import { Avatar } from '@zeal/domains/Currency/components/Avatar'
import { FormattedTokenBalances2 } from '@zeal/domains/Money/components/FormattedTokenBalances'
import { FormattedTokenInDefaultCurrency2 } from '@zeal/domains/Money/components/FormattedTokenInDefaultCurrency'

import { TopUpRequest } from '../../TopUpRequest'

type Props = {
    topUpRequest: TopUpRequest
}

export const TransactionContent = ({ topUpRequest }: Props) => {
    const currency = topUpRequest.amount.currency
    return (
        <Column spacing={20}>
            <ListItem
                avatar={({ size }) => (
                    <Avatar currency={currency} size={size} />
                )}
                aria-current={false}
                size="large"
                primaryText={currency.code}
                side={{
                    title: (
                        <>
                            -
                            <FormattedTokenBalances2
                                money={topUpRequest.amount}
                            />
                        </>
                    ),
                    subtitle: topUpRequest.amountInDefaultCurrency ? (
                        <Text
                            variant="paragraph"
                            weight="regular"
                            color="textSecondary"
                        >
                            -
                            <FormattedTokenInDefaultCurrency2
                                money={topUpRequest.amountInDefaultCurrency}
                            />
                        </Text>
                    ) : null,
                }}
            />
            <Column spacing={4}>
                <Text
                    variant="paragraph"
                    color="textSecondary"
                    weight="regular"
                >
                    <FormattedMessage id="topup.to" defaultMessage="To" />
                </Text>

                <ListItem
                    aria-current={false}
                    size="large"
                    primaryText={topUpRequest.account.label}
                    shortText={format(topUpRequest.account.address)}
                    avatar={({ size }) => (
                        <AvatarWithoutBadge
                            account={topUpRequest.account}
                            size={size}
                        />
                    )}
                />
            </Column>
        </Column>
    )
}
