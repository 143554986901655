import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'

import { Button } from '@zeal/uikit/Button'
import { Column } from '@zeal/uikit/Column'
import { Header } from '@zeal/uikit/Header'
import { Popup } from '@zeal/uikit/Popup'

import { Account } from '@zeal/domains/Account'

import { ExternalWalletAvatar } from './ExternalWalletAvatar'

type Props = {
    fromAccount: Account
    title: ReactNode
    onMsg: (msg: Msg) => void
}

type Msg = { type: 'close' }

export const TransactionSentToWalletPopup = ({
    onMsg,
    fromAccount,
    title,
}: Props) => (
    <Popup.Layout onMsg={onMsg} background="surfaceDefault">
        <Column spacing={24}>
            <Header
                icon={({ size }) => (
                    <ExternalWalletAvatar
                        size={size}
                        fromAccount={fromAccount}
                    />
                )}
                title={title}
                subtitle={
                    <FormattedMessage
                        id="tx-sent-to-wallet.subtitle"
                        defaultMessage="Continue in {wallet}"
                        values={{ wallet: fromAccount.label }}
                    />
                }
            />
            <Popup.Actions>
                <Button
                    variant="secondary"
                    onClick={() => onMsg({ type: 'close' })}
                    size="regular"
                >
                    <FormattedMessage
                        id="tx-sent-to-wallet.button"
                        defaultMessage="Close"
                    />
                </Button>
            </Popup.Actions>
        </Column>
    </Popup.Layout>
)
