import React from 'react'
import { Path } from 'react-native-svg'

import { Color, colors } from '@zeal/uikit/colors'
import { SvgIcon } from '@zeal/uikit/SvgIcon'

type Props = {
    size: number
    color?: Color
}

export const LightWallet = ({ size, color }: Props) => (
    <SvgIcon
        color={color && colors[color]}
        viewBox="0 0 24 24"
        width={size}
        height={size}
    >
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.7689 8.3818H22C22 4.98459 19.9644 3 16.5156 3H7.48444C4.03556 3 2 4.98459 2 8.33847V15.6615C2 19.0154 4.03556 21 7.48444 21H16.5156C19.9644 21 22 19.0154 22 15.6615V15.3495H17.7689C15.8052 15.3495 14.2133 13.7975 14.2133 11.883C14.2133 9.96849 15.8052 8.41647 17.7689 8.41647V8.3818ZM17.7691 9.87109H21.2536C21.6659 9.87109 22.0002 10.197 22.0002 10.5991V13.1296C21.9954 13.5297 21.6639 13.8529 21.2536 13.8576H17.8491C16.855 13.8707 15.9857 13.2071 15.7602 12.263C15.6473 11.677 15.8058 11.0722 16.1933 10.6109C16.5808 10.1495 17.1576 9.87876 17.7691 9.87109ZM17.9207 12.5297H18.2496C18.6718 12.5297 19.014 12.196 19.014 11.7844C19.014 11.3728 18.6718 11.0391 18.2496 11.0391H17.9207C17.7188 11.0368 17.5243 11.1134 17.3807 11.2518C17.2371 11.3902 17.1562 11.5789 17.1562 11.7758C17.1562 12.1888 17.4971 12.525 17.9207 12.5297ZM6.7371 8.38124H12.3815C12.8037 8.38124 13.146 8.04755 13.146 7.63593C13.146 7.22431 12.8037 6.89062 12.3815 6.89062H6.7371C6.31836 6.8906 5.97753 7.21903 5.97266 7.62726C5.97263 8.04031 6.31348 8.37649 6.7371 8.38124Z"
            fill="currentColor"
        />
    </SvgIcon>
)
