import * as SentryWeb from '@sentry/react' // eslint-disable-line
import * as SentryRN from '@sentry/react-native' // eslint-disable-line

import { getEnvironment, isProduction } from '../Environment'
import { notReachable } from '../notReachable'
import { ZealPlatform } from '../OS/ZealPlatform'

type Params = {
    dsn: string
    release?: string // TODO @resetko-zeal this got automagically populated in RN, do we want to pass it explicitly?
}

export const initReporting = (
    params: Params
): { wrapperFunction: typeof SentryRN.wrap } => {
    switch (ZealPlatform.OS) {
        case 'ios':
        case 'android':
            SentryRN.init({
                dsn: params.dsn,
                debug: !isProduction(),
                environment: getEnvironment(),
            })
            return { wrapperFunction: SentryRN.wrap }

        case 'web':
            SentryWeb.addTracingExtensions()
            SentryWeb.init({
                dsn: params.dsn,
                debug: !isProduction(),
                environment: getEnvironment(),
                release: params.release,
                integrations: [],
                tracesSampleRate: 0.05,
            })
            return { wrapperFunction: (comp) => comp }

        default:
            return notReachable(ZealPlatform.OS)
    }
}

export const setUserId = (id: string) => {
    switch (ZealPlatform.OS) {
        case 'ios':
        case 'android':
            SentryRN.setUser({ id })
            break

        case 'web':
            SentryWeb.setUser({ id })
            break

        default:
            notReachable(ZealPlatform.OS)
    }
}

export const captureException = (
    error: unknown,
    {
        tags,
        extra,
    }: {
        tags: Record<string, string | number | null>
        extra?: Record<string, unknown>
    }
) => {
    switch (ZealPlatform.OS) {
        case 'web':
            SentryWeb.captureException(error, { tags, extra })
            break
        case 'ios':
        case 'android':
            SentryRN.captureException(error, { tags, extra })
            break
        default:
            notReachable(ZealPlatform.OS)
    }
}
