import { failure, oneOf, Result, success } from '@zeal/toolkit/Result'

import {
    AddEthereumChainNotSupported,
    SwitchChainChainIdNotSupported,
    WagmiError,
} from '..'

const _dontForgetToAddParser: Record<WagmiError['type'], true> = {
    wagmi_add_ethereum_chain_not_supported: true,
    wagmi_switch_chain_chain_id_not_supported: true,
}

const parseError = (input: unknown): Result<unknown, Error> =>
    input instanceof Error
        ? success(input)
        : failure({ type: 'not_matching_instance' })

const parseAddEthereumChainNotSupported = (
    input: unknown
): Result<unknown, AddEthereumChainNotSupported> =>
    parseError(input).andThen((error) =>
        error.message.match('does not support wallet_addEthereumChain')
            ? success({ type: 'wagmi_add_ethereum_chain_not_supported' })
            : failure({ type: 'not_matching_instance_or_message' })
    )

const parseSwitchChainChainIdNotSupported = (
    input: unknown
): Result<unknown, SwitchChainChainIdNotSupported> =>
    parseError(input).andThen((error) =>
        error.message.match(
            'An error occurred when attempting to switch chain'
        ) && error.message.match('Chain Id not supported')
            ? success({ type: 'wagmi_switch_chain_chain_id_not_supported' })
            : failure({ type: 'not_matching_instance_or_message' })
    )

export const parseWagmiError = (error: unknown): Result<unknown, WagmiError> =>
    oneOf(error, [
        parseAddEthereumChainNotSupported(error),
        parseSwitchChainChainIdNotSupported(error),
    ])
