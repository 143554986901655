import { FormattedMessage } from 'react-intl'

import { Clickable } from '@zeal/uikit/Clickable'
import { BackIcon } from '@zeal/uikit/Icon/BackIcon'
import { Row } from '@zeal/uikit/Row'
import { Text } from '@zeal/uikit/Text'

import { notReachable } from '@zeal/toolkit'
import { ZealPlatform } from '@zeal/toolkit/OS/ZealPlatform'

type Props = {
    onMsg: (msg: Msg) => void
}

type Msg = { type: 'close' }

export const Header = ({ onMsg }: Props) => {
    switch (ZealPlatform.OS) {
        case 'ios':
        case 'android':
            return (
                <Clickable onClick={() => onMsg({ type: 'close' })}>
                    <Row spacing={4}>
                        <BackIcon size={24} color="iconDefault" />
                        <Text
                            variant="title3"
                            weight="semi_bold"
                            color="textPrimary"
                        >
                            <FormattedMessage
                                id="account.topup.title"
                                defaultMessage="Add funds to Zeal"
                            />
                        </Text>
                    </Row>
                </Clickable>
            )
        case 'web':
            return (
                <Text variant="title3" weight="semi_bold" color="textPrimary">
                    <FormattedMessage
                        id="account.topup.title"
                        defaultMessage="Add funds to Zeal"
                    />
                </Text>
            )
        /* istanbul ignore next */
        default:
            return notReachable(ZealPlatform.OS)
    }
}
