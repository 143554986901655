import {
    DecryptIncorrectPassword,
    EncryptedObjectInvalidFormat,
    InvalidEncryptedFileFormat,
} from '@zeal/toolkit/Crypto'
import { ImperativeError } from '@zeal/toolkit/Error'
import { UnexpectedResultFailureError } from '@zeal/toolkit/Result'

import { BundlerResponseError } from '@zeal/domains/Error/domains/BundlerError'
import { LedgerError } from '@zeal/domains/Error/domains/Ledger'
import { PasskeyError } from '@zeal/domains/Error/domains/Passkey'
import { RPCResponseError } from '@zeal/domains/Error/domains/RPCError'
import { TrezorError } from '@zeal/domains/Error/domains/Trezor'
import { UnblockError } from '@zeal/domains/Error/domains/Unblock'
import { WagmiError } from '@zeal/domains/Error/domains/Wagmi'
import { WalletConnectError } from '@zeal/domains/Error/domains/WalletConnect'
import { FailedToFetchGoogleAuthToken } from '@zeal/domains/GoogleDriveFile'

import { RPCRequestParseError } from './RPCRequestParseError'

export class HttpError extends Error {
    isHttpError = true
    type: 'http_error' = 'http_error' as const
    name = 'HttpError' as const

    url: string
    queryParams: unknown
    method: string
    data: unknown
    status: number | null
    trace: string | null

    constructor(
        url: string,
        method: string,
        status: number | null,
        trace: string | null,
        data: unknown,
        queryParams: unknown
    ) {
        super('HttpError')
        this.trace = trace
        this.url = url
        this.method = method
        this.status = status
        this.data = data
        this.queryParams = queryParams
    }
}

export class ConnectivityError extends Error {
    isConnectivityError = true
    type: 'connectivity_error' = 'connectivity_error' as const
    name = 'ConnectivityError' as const

    url: string
    queryParams: unknown
    method: string

    constructor(url: string, method: string, queryParams: unknown) {
        super('ConnectivityError')
        this.url = url
        this.method = method
        this.queryParams = queryParams
    }
}

export type UnknownError = {
    type: 'unknown_error'
    error: unknown
}

export type UnexpectedFailureError = {
    type: 'unexpected_failure'
    error: UnexpectedResultFailureError<unknown>
}

export type GoogleApiError = {
    type: 'google_api_error'
    code: number // TODO :: add more union codes here
    message: string
    error: unknown
}

export type GnosisPayIsNotAvailableInThisCountry = {
    type: 'gnosis_pay_is_not_available_in_this_country'
}
export type GnosisPayNoActiveCardsFound = {
    type: 'gnosis_pay_no_active_cards_found'
}

export type BiometricPromptCancelled = { type: 'biometric_prompt_cancelled' }
export type BiometricPromptAuthFailed = { type: 'biometric_prompt_auth_failed' }
export type SecureStoreKeychainDecryptionError = {
    type: 'secure_store_keychain_decryption_error'
}

export type IosCouldNotCommunicateWithHelperApplication = {
    type: 'ios_could_not_communicate_with_helper_application'
}

export class UnknownMerchantCode extends Error {
    type: 'unknown_merchant_code' = 'unknown_merchant_code' as const
    name: string = 'UnknownMerchantCode' as const

    code: number

    constructor(code: number) {
        super()
        this.code = code
        this.message = `Unknown merchant code: ${code}`
    }
}

export type AppError =
    | BiometricPromptAuthFailed
    | BiometricPromptCancelled
    | BundlerResponseError
    | ConnectivityError
    | DecryptIncorrectPassword
    | EncryptedObjectInvalidFormat
    | FailedToFetchGoogleAuthToken
    | GnosisPayIsNotAvailableInThisCountry
    | GnosisPayNoActiveCardsFound
    | GoogleApiError
    | HttpError
    | ImperativeError
    | InvalidEncryptedFileFormat
    | IosCouldNotCommunicateWithHelperApplication
    | LedgerError
    | PasskeyError
    | RPCRequestParseError
    | RPCResponseError
    | SecureStoreKeychainDecryptionError
    | TrezorError
    | UnblockError
    | UnexpectedFailureError
    | UnknownError
    | UnknownMerchantCode
    | WalletConnectError
    | WagmiError
