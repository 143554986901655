import React from 'react'
import Svg, { Circle, Defs, LinearGradient, Path, Stop } from 'react-native-svg'

type Props = {
    size: number
}

export const Mantle = ({ size }: Props) => {
    return (
        <Svg
            style={{ flexShrink: 0 }}
            viewBox="0 0 120 120"
            width={size}
            height={size}
            fill="none"
        >
            <Circle
                cx="60"
                cy="60"
                r="60"
                fill="url(#paint0_linear_1501_607)"
            />
            <Path
                d="M37.642 48.6495L26.1179 42.782C25.2165 44.553 24.4488 46.4099 23.8379 48.3012L36.1431 52.2802C36.5453 51.0363 37.0484 49.8146 37.642 48.6495Z"
                fill="white"
            />
            <Path
                d="M47.4308 38.3084L53.8138 49.3066C54.746 48.766 55.7437 48.3502 56.7816 48.0712L53.4782 35.7907C54.3188 35.5646 55.1732 35.378 56.0323 35.2423L54.0023 22.4692C52.0389 22.7809 50.0879 23.2514 48.2023 23.8661L52.1496 35.9639C50.894 36.3737 49.6714 36.8826 48.4938 37.4849L42.6919 26.1597C40.9275 27.064 39.2209 28.1145 37.6172 29.2854L45.2411 39.7314C45.9434 39.2195 46.6783 38.7461 47.4308 38.3084Z"
                fill="white"
            />
            <Path
                d="M81.6835 47.4207L70.6895 53.8097C71.2311 54.7409 71.6466 55.7395 71.9267 56.7757L84.2059 53.4661C84.432 54.307 84.6187 55.1604 84.7552 56.0185L97.5261 53.9847C97.2135 52.0222 96.742 50.0702 96.1255 48.1837L84.0298 52.1386C83.6191 50.8832 83.1101 49.6613 82.5069 48.4838L93.8281 42.674C92.9238 40.9116 91.8713 39.2049 90.7006 37.6021L80.2578 45.2312C80.7705 45.9335 81.2459 46.6674 81.6835 47.4207Z"
                fill="white"
            />
            <Path
                d="M77.1998 26.108C75.4268 25.2075 73.5701 24.4407 71.6807 23.8308L67.7085 36.1383C68.9514 36.5395 70.1732 37.0436 71.3401 37.6362L77.1998 26.108Z"
                fill="white"
            />
            <Path
                d="M72.6079 38.0831L66.1519 49.2881C67.087 49.8268 67.9488 50.4868 68.7156 51.2505L86.8229 33.0814C85.4183 31.6816 83.8925 30.3819 82.2878 29.2178L74.8196 39.5175C74.1078 39.0018 73.3697 38.5227 72.6079 38.0831Z"
                fill="white"
            />
            <Path
                d="M38.0902 47.3801L49.292 53.8432C49.8307 52.9091 50.4926 52.0462 51.2575 51.2784L33.0964 33.1641C31.6957 34.5687 30.395 36.0935 29.231 37.6972L39.5266 45.1705C39.0109 45.8813 38.5299 46.6183 38.0902 47.3801Z"
                fill="white"
            />
            <Path
                d="M63.9207 35.0252L65.8745 22.4522C63.9438 22.152 61.9678 22 60.0005 22H59.9707V47.6521H60.0005C61.0818 47.6521 62.1516 47.7906 63.181 48.0639L66.4979 35.5639C65.6494 35.3388 64.7894 35.1608 63.9207 35.0252Z"
                fill="white"
            />
            <Path
                d="M48.0642 56.8126L35.5665 53.4868C35.3404 54.3354 35.1615 55.1954 35.0258 56.0641L22.455 54.1044C22.153 56.0421 22 58.0258 22 59.999H47.6514C47.6514 58.9157 47.7902 57.8431 48.0642 56.8126Z"
                fill="white"
            />
            <Path
                d="M82.3585 71.3523L93.8825 77.2199C94.7839 75.4486 95.5515 73.5919 96.1624 71.7006L83.8573 67.7214C83.455 68.9654 82.9521 70.1872 82.3585 71.3523Z"
                fill="white"
            />
            <Path
                d="M72.5686 81.6905L66.1856 70.6924C65.2534 71.233 64.2559 71.6485 63.2178 71.9276L66.5214 84.2081C65.6796 84.4343 64.8262 84.6209 63.9673 84.7566L65.996 97.5286C67.9597 97.2169 69.9106 96.7464 71.7963 96.1317L67.849 84.0339C69.1054 83.6242 70.3272 83.1152 71.5047 82.513L77.3066 93.8382C79.0709 92.9339 80.7776 91.8833 82.3812 90.7126L74.7572 80.2665C74.055 80.7784 73.3211 81.2527 72.5686 81.6905Z"
                fill="white"
            />
            <Path
                d="M38.3172 72.5814L49.3123 66.1926C48.7706 65.2612 48.3549 64.2626 48.075 63.2266L35.7958 66.5359C35.5697 65.6951 35.3831 64.8417 35.2465 63.9836L22.4746 66.0174C22.7873 67.98 23.2587 69.9321 23.8753 71.8186L35.9709 67.8635C36.3816 69.1191 36.8906 70.341 37.4938 71.5185L26.1726 77.3282C27.0769 79.0907 28.1294 80.7974 29.3002 82.4001L39.743 74.7711C39.2302 74.0688 38.7549 73.3347 38.3172 72.5814Z"
                fill="white"
            />
            <Path
                d="M42.7998 93.893C44.5727 94.7936 46.4294 95.5602 48.319 96.1703L52.2912 83.8627C51.0482 83.4616 49.8265 82.9574 48.6594 82.3647L42.7998 93.893Z"
                fill="white"
            />
            <Path
                d="M47.3921 81.9191L53.8483 70.7142C52.9132 70.1754 52.0512 69.5154 51.2844 68.7517L33.1772 86.9208C34.5818 88.3206 36.1076 89.6203 37.7122 90.7845L45.1804 80.4847C45.8924 81.0004 46.6301 81.4794 47.3921 81.9191Z"
                fill="white"
            />
            <Path
                d="M81.909 72.6218L70.707 66.1589C70.1683 67.093 69.5064 67.956 68.7417 68.7236L86.9018 86.839C88.3026 85.4344 89.6031 83.9096 90.7673 82.3059L80.4715 74.8327C80.9874 74.1217 81.4693 73.3838 81.909 72.6218Z"
                fill="white"
            />
            <Path
                d="M56.8194 71.936L53.5024 84.4361C54.351 84.661 55.211 84.8391 56.0797 84.9748L54.1269 97.5477C56.0577 97.8479 58.0336 97.9999 60.001 97.9999H60.0307V72.3478H60.001C58.9196 72.3478 57.8488 72.2092 56.8194 71.936Z"
                fill="white"
            />
            <Path
                d="M72.3487 60.0017C72.3487 61.085 72.2093 62.1566 71.9351 63.1879L84.4327 66.5137C84.6589 65.6651 84.8378 64.8051 84.9733 63.9364L97.5452 65.8972C97.8472 63.9595 98.0001 61.9758 98.0001 60.0025L72.3487 60.0017Z"
                fill="white"
            />
            <Defs>
                <LinearGradient
                    id="paint0_linear_1501_607"
                    x1="0"
                    y1="0"
                    x2="120"
                    y2="120"
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#008467" />
                    <Stop offset="1" />
                </LinearGradient>
            </Defs>
        </Svg>
    )
}
